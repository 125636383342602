import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Drawer, InputAdornment, Typography } from "@material-ui/core";
import { drawerStyles } from '../cart/CartDrawer'
import DrawerHeader from '../cart/DrawerHeader'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Link, TextField } from "@material-ui/core";
import { MailOutline as EmailIcon, LockOpen as PasswordIcon } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { changeRegisterDrawerVisibilityAction } from "../../../redux/actions/auth/changeRegisterDrawerVisibilityAction";
import { recoverPasswordAction, resetRecoverPasswordStatus } from "../../../redux/actions/auth/recoverPasswordAction";
import { loginUserAction } from "../../../redux/actions/auth/loginUserAction";
import { Formik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { COMPLETE, ERROR } from '../../../redux/types/asyncStatusTypes';

const useStyles = makeStyles(() => ({

    mainInputs: {
        padding: "1rem"
    },

    emailTextField: {
        paddingBottom: "1rem"
    },
    recoverPasswordLink: {
        textDecorationLine: 'underline',
        textAlign: "center",
    },
    recoverPasswordLabel: {
        textAlign: "center",
    },
    footer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        padding: '1rem',
        boxShadow: "0 -1px 8px 0 rgba(0,0,0,.1)"
    },
    errorMessage: {
        marginTop: "30px",
        top: "20px",
        borderColor: "red",
        borderStyle: "dashed",
        borderWidth: "thin",
        padding: "10px",
        borderRadius: "8px",
        color: "red",
    }, swalIcon: {
        width: "60px",
        height: "60px"
    }
}))
const loginSchema = Yup.object({
    email: Yup.string().email("Debes de introducir un email váilido").trim()
        .required('Debes introducir un email válido'),
    password: Yup.string().trim()
        .min(5, 'Mínimo 5 carácteres')
        .required('Debes de introducir la contraseña'),
})

const LoginDrawer = (props) => {
    const dispatch = useDispatch();
    const classes = drawerStyles();

    const myClasses = useStyles();
    const { onHandleClose, open } = props;

    const ReactSwal = withReactContent(Swal)


    const isLogged = useSelector(state => state["authReducer"].isLogged);
    const isLogging = useSelector(state => state["authReducer"].isLogging);
    const errorMessage = useSelector(state => state["authReducer"].errorMessage);
    const recoverPasswordStatus = useSelector(state => state["authReducer"].recoverPasswordStatus)

    useEffect(() => {
        if (!isLogging && isLogged) {
            onHandleClose()
        }
        // eslint-disable-next-line
    }, [isLogging]);

    const [loginForm, setLoginForm] = React.useState({
        showPassword: false
    });
    useEffect(() => {
        if (recoverPasswordStatus === ERROR) {
            ReactSwal.fire({
                customClass: {
                    container: "swalert"
                },
                confirmButtonColor: "#90594c",
                text: errorMessage ? errorString() : "Inténtalo de nuevo más tarde",
                title: "ERROR",
                icon: 'error'
            });
            dispatch(resetRecoverPasswordStatus())
        } else if (recoverPasswordStatus === COMPLETE) {
            ReactSwal.fire({
                customClass: {
                    container: "swalert"
                },
                confirmButtonColor: "#90594c",
                text: "Se ha enviado un correo de recuperación a tu correo",
                title: "ENVIADO",
                icon: 'success'
            });
            dispatch(resetRecoverPasswordStatus())

        }
        return () => {

        }
    }, [recoverPasswordStatus])



    const handleClickShowPassword = () => {
        setLoginForm({ ...loginForm, showPassword: !loginForm.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();

    };

    const handleRecoverPassword = async event => {
        event.preventDefault();

        ReactSwal.fire({
            customClass: {
                container: "swalert"
            },
            input: "email",
            inputPlaceholder: "ejemplo@correo.es",
            confirmButtonColor: "#90594c",
            text: "Introduce tu correo y te enviaremos instrucciones para restablecer tu contraseña",
            validationMessage: "Introduce un correo válido",
            showCancelButton: true,
            iconHtml: <EmailIcon className={myClasses.swalIcon} />,
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(recoverPasswordAction(result.value))
            }
        })


    }

    const handleRegisterClicked = (event) => {
        dispatch(changeRegisterDrawerVisibilityAction(true))
        onHandleClose()
    }

    const ErrorMessage = () => {


        if (errorMessage === null) return null;
        return (
            <Typography variant="body2" className={myClasses.errorMessage}>{errorString()}</Typography>
        );
    }
    const errorString = () => {
        if (!errorMessage) return "";

        switch (errorMessage) {
            case 'auth/user-not-found':
                return "No se ha encontrado ninguna cuenta con ese correo";
            case 'auth/invalid-email':
                return "Email con formato incorrecto";
            case 'auth/wrong-password':
                return 'Usuario o contraseña incorrecto';
            case 'auth/too-many-requests':
                return 'Demasiados intentos. Vuelve a intentarlo en un tiempo';
            case 'no_user':
                return 'No se ha encontrado el usuario. Contacte con soporte técnico';
            default:
                return "Se ha producido un error";
        }

    }
    /*
        if (isLogged) {
            onHandleClose();
        }*/



    return (
        <Drawer ModalProps={{ disableEnforceFocus: true }} anchor={"right"} open={open} onClose={() => onHandleClose(false)} classes={{ paper: classes.paper }} styles={{ backgroundColor: "white" }}>
            <DrawerHeader title="Identifícate" onHandleClose={() => onHandleClose(false)} />

            <Formik

                initialValues={{
                    email: "",
                    password: ""
                }
                }
                validationSchema={
                    loginSchema
                }
                onSubmit={(values) => {
                    // setLoginForm({ ...loginForm, isLogging:true});
                    const form = loginSchema.cast(values)//esto es necesario para que las transformaciones (por ejemplo, el trim) se apliquen
                    dispatch(loginUserAction(form));
                }}
            >
                {props => (
                    <form
                        className={myClasses.mainInputs}
                        onSubmit={props.handleSubmit}
                    >
                        <TextField
                            key="email"
                            id="email"
                            name="email"
                            fullWidth
                            className={myClasses.emailTextField}
                            value={loginForm.email}
                            color="secondary"
                            label="Email"
                            variant="outlined"
                            placeholder="usuario@correo.com"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                            {...props.getFieldProps('email')}
                            helperText={props.touched.email && props.errors.email}
                            error={props.touched.email && props.errors.email}
                        />

                        <TextField
                            key="password"
                            id="password"
                            name="password"
                            className={myClasses.emailTextField}
                            fullWidth
                            color="secondary"
                            label="Contraseña"
                            variant="outlined"
                            type={loginForm.showPassword ? 'text' : 'password'}
                            placeholder="Introduce tu password"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PasswordIcon />
                                    </InputAdornment>
                                ), endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}>

                                            {loginForm.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            {...props.getFieldProps('password')}
                            helperText={props.touched.password && props.errors.password}
                            error={props.touched.password && props.errors.password}
                        />


                        <Typography fullWidth className={myClasses.recoverPasswordLabel}>¿Tienes problemas para acceder a tu cuenta?</Typography>
                        <Typography fullWidth className={myClasses.recoverPasswordLink}>
                            <Link href="#" color="secondary" onClick={handleRecoverPassword}>
                                Recuperar contraseña
                    </Link>
                        </Typography>
                        {
                            errorMessage ? <ErrorMessage /> : undefined
                        }
                        <Box className={classes.footer}>
                            <Button
                                className={classes.acceptButton}
                                variant={"contained"}
                                color="primary"
                                fullWidth
                                type="submit"
                                disabled={isLogging}
                            >
                                {isLogging ? "Iniciando" : "Iniciar Sesión"}
                            </Button>
                            {process.env.REACT_APP_CREATE_ACCOUNT_DISABLED ? "" :
                                <Button
                                    className={myClasses.acceptButton}
                                    variant={"contained"}
                                    color="primary"
                                    fullWidth
                                    onClick={handleRegisterClicked}>
                                    Crear una cuenta nueva
                </Button>}

                        </Box>

                    </form>
                )}
            </Formik>
        </Drawer>
    );
}

export default LoginDrawer;

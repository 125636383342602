import { IconButton, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useDispatch} from "react-redux";
import { changeAddressSelectorVisibilityAction } from "../../../redux/actions/address/changeAddressSelectorVisibilityAction";
import { changeAddressEditorVisibilityAction } from "../../../redux/actions/address/changeAddressEditorVisibilityAction";

import { Add as AddIcon, EditOutlined as EditIcon, LocationOn as LocationIcon, Check as SelectedIcon} from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
    container: {
        margin: 0,
        width: 400
    },
    addressItem: {
        paddingLeft: 15,
        paddingRight: 0,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        borderBottom: "1px solid #f5f5f5"

    },
}));



const SelectShippingAddressCell = ({ address, addressSelected,onAddressSelected,forEditing }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    

    if (!address) return null;

    const onClickAddress = () => {
       onAddressSelected(address)
    }

    return (
        <ListItem alignItems="flex-start" className={classes.addressItem} key={address.id} onClick={onClickAddress}>
            <ListItemIcon>
                <LocationIcon />
            </ListItemIcon>
            <ListItemText
                primary={address.name}
                secondary={
                    address.street + " " + address.city + " " + address.postalCode + " " + address.province.name
                }
            />

            {
            forEditing ?
            <ListItemIcon>
                    <IconButton edge="end" aria-label="comments">
                        <EditIcon />
                    </IconButton>
                </ListItemIcon>
            :
            ((addressSelected && addressSelected.id === address.id) ?
                <ListItemIcon>
                    <IconButton edge="end" aria-label="comments">
                        <SelectedIcon />
                    </IconButton>
                </ListItemIcon> : "")
            }
        </ListItem>
    )
}

const SelectShippingAddressList = ({ addresses, addressSelected, onAddressSelected,forEditing }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    if (!addresses) addresses = [];

    const onClickCreateNewAddress = () => {
        if(!forEditing)
        {//SI NO ESTÁ EDITANDO, CUANDO VUELVA DE CREAR UNA DIRECCIÖN DEBERÏA DE HABER DESAPARECIDO EL SELECTOR
            dispatch(changeAddressSelectorVisibilityAction(false));
        }
        dispatch(changeAddressEditorVisibilityAction(true));
    }
    

    return (
        <div className={classes.container}>
            <List >
                {
                    addresses.map(address => <SelectShippingAddressCell address={address} addressSelected={addressSelected} onAddressSelected={onAddressSelected} forEditing={forEditing}/>)
                }
                <ListItem key={"listItem"} onClick={onClickCreateNewAddress}>
                    <ListItemIcon>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={`AÑADIR UNA NUEVA DIRECCIÓN`}
                    />
                </ListItem>
            </List>

        </div>

    )

}

export default SelectShippingAddressList;

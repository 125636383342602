import {SET_SETUP_CLIENT_SECRET} from "../../types/creditCardTypes";
import {getClientStripeKeyAsync} from "../../../services/clientServices";

export function getSetupCreditCardClientSecretAction() {
    console.log("llamada")
    return async (dispatch) => {
        const clientSecret = await getClientStripeKeyAsync();
       
        if (clientSecret instanceof Error) {
            console.log('Algo va mal', clientSecret);
        } else {
            console.log(clientSecret)
            dispatch(setSetupClientSecret(clientSecret));
        }
    }
}

const setSetupClientSecret = clientSecret => ({
    type: SET_SETUP_CLIENT_SECRET,
    payload: clientSecret
})
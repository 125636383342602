import {
    CHANGE_DELIVERY_DATE_SELECTED,
    CHANGE_DELIVERY_DATE_SELECTOR_VISIBILITY
} from "../types/deliveryDateTypes";

const initialState = {
    deliveryDateSelectorOpened: false,
    loading: false,
    deliveryDateSelected: null
}

const state = (state = initialState, action) => {
    switch (action.type) {


        case CHANGE_DELIVERY_DATE_SELECTOR_VISIBILITY:
            console.log("CHANGING VISIBILITY: "+action.payload)
            return {
                ...state,
                deliveryDateSelectorOpened: action.payload
            }



        case CHANGE_DELIVERY_DATE_SELECTED:
            console.log("CHANGING DELIVERYDATE SELECTED: "+action.payload)
            return {
                ...state,
                deliveryDateSelected:action.payload,
             //   deliveryDateSelectorOpened: false
            }

        default:
            return state;
    }
}

export default state;
import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Drawer, InputAdornment, Typography } from "@material-ui/core";
import { drawerStyles } from '../cart/CartDrawer'
import DrawerHeader from '../cart/DrawerHeader'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Link, TextField } from "@material-ui/core";
import { MailOutline as EmailIcon, LocationOnOutlined as LocationIcon, LockOpen as PasswordIcon, PhoneOutlined as PhoneIcon, PermIdentity as UserIcon } from '@material-ui/icons';
import {changeAuthFormVisibilityAction} from "../../../redux/actions/auth/changeAuthFormVisibilityAction";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { registerClientAction } from "../../../redux/actions/client/registerClientAction";
import { Formik } from 'formik';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
    mainInputs: {
        padding: "1rem"
    },

    emailTextField: {
        paddingBottom: "1rem"
    },
    recoverPasswordLink: {
        textDecorationLine: 'underline',
        textAlign: "center",
    },
    recoverPasswordLabel: {
        textAlign: "center",
    },
    footer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        padding: '1rem',
        boxShadow: "0 -1px 8px 0 rgba(0,0,0,.1)"
    },
    conditions: {
        textAlign: "left",
        lineHeight: "1"

    },
    conditionsGroup: {
        paddingBottom: "0.8rem"

    },
    errorMessage: {
        marginTop:"5px",
        borderColor: "red",
        color: "red",
        fontSize:"15px"
    }
}));

const registerSchema = Yup.object({
    name: Yup.string().trim()
        .required('Debes introducir un nombre'),
    email: Yup.string().email("Debes de introducir un email váilido").trim()
        .required('Debes introducir un email válido'),
    phone: Yup.string().trim().matches(/^[0-9\-\+]{9,15}$/, "Formato de teléfono incorrecto").
        min(9, "Número de teléfono incorrecto").
        required("Debes proporcionar un código postal"),
    postalCode: Yup.string().trim().matches(/^[0-9]+$/, "Código postal incorrecto").
        length(5, "Código postal incorrecto").
        required("Debes proporcionar un código postal"),
    password: Yup.string().trim()
        .min(5, 'Mínimo 5 carácteres')
        .required('Debes de introducir la contraseña'),
        repeatedPassword: Yup.string()
        .matches(Yup.ref('password'), 'Las contraseñas no coinciden'),
    conditionsAccepted:Yup.bool().oneOf([true]),
    marketingAccepted:Yup.bool()
})


const RegisterDrawer = (props) => {
    const dispatch = useDispatch();
    const classes = drawerStyles();

    const myClasses = useStyles();
    const { onHandleClose, open } = props;

    const isLogged = useSelector(state => state["authReducer"].isLogged);
    const isRegistering = useSelector(state => state["clientReducer"].isRegistering);
    const errorMessage = useSelector(state => state["clientReducer"].errorMessage);

    useEffect(() => {
        if (!isRegistering && isLogged){
            onHandleClose()
        }
        // eslint-disable-next-line
    }, [isRegistering]);

    const [registerForm, setRegisterForm] = React.useState({

        type: 'retail',
        showPassword: false,
        showRepeatPassword: false,
        conditionsAccepted: false,
        marketingAccepted: false
    });

    const handleInputChange = (prop) => (event) => {
        event.preventDefault();

        setRegisterForm({
            ...registerForm,
            [prop]: event.target.value
        });
    };

    const handleCheckChange = (event, value) => {
        setRegisterForm({
            ...registerForm,
            [event.target.id]: value
        });
    }

    const handleClickShowPassword = () => {
        setRegisterForm({ ...registerForm, showPassword: !registerForm.showPassword });
    };
    const handleClickShowRepeatPassword = () => {
        setRegisterForm({ ...registerForm, showRepeatPassword: !registerForm.showRepeatPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onClickLogin = (event) => {
        dispatch(changeAuthFormVisibilityAction(true))
        onHandleClose()
    }
    /*
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(registerClientAction(registerForm));
    }*/

    const handleRecoverPassword = (event) => {
        event.preventDefault();
    }
/*
    if (isLogged) {
        onHandleClose();
    }*/


    return (
        <Drawer anchor={"right"} open={open} onClose={onHandleClose} classes={{ paper: classes.paper }} styles={{ backgroundColor: "white" }}>
            <DrawerHeader title="Registro" onHandleClose={onHandleClose} />
            <Formik
            initialValues={{
                type:"retail",
                name:"",
                email:"",
                phone:"",
                postalCode:"",
                password:"",
                repeatedPassword:"",
                conditionsAccepted:false,
                marketingAccepted:false
            }
            }

            validationSchema={
                registerSchema
            }
            onSubmit={(values) => {
                const registerForm = registerSchema.cast(values)//esto es necesario para que las transformaciones (por ejemplo, el trim) se apliquen
                if (!registerForm.conditionsAccepted){
                    alert("Tienes que aceptar los términos legales y condiciones de privacidad")
                }else{
                    //alert(JSON.stringify(registerForm))
                }
                dispatch(registerClientAction(registerForm));
            }}
            >
                 {props => (
            <form
                className={myClasses.mainInputs}
                onSubmit={props.handleSubmit}
            >


                <TextField
                    key="name"
                    fullWidth
                    className={myClasses.emailTextField}
                    value={registerForm.name}
                    color="secondary"
                    label="Nombre y apellidos"
                    variant="outlined"
                    //InputLabelProps={{shrink: true}}
                    onChange={handleInputChange('name')}
                    placeholder="Andrea Fernández González"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <UserIcon />
                            </InputAdornment>
                        ),
                    }}
                    {...props.getFieldProps('name')}
                    helperText={props.touched.name && props.errors.name}
                    error={props.touched.name && props.errors.name}
                />
                <TextField
                    key="email"
                    fullWidth
                    className={myClasses.emailTextField}
                    value={registerForm.email}
                    color="secondary"
                    label="Email"
                    variant="outlined"
                    //InputLabelProps={{shrink: true}}
                    onChange={handleInputChange('email')}
                    placeholder="usuario@correo.com"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    {...props.getFieldProps('email')}
                    helperText={props.touched.email && props.errors.email}
                    error={props.touched.email && props.errors.email}
                />
                <TextField
                    key="phone"
                    fullWidth
                    className={myClasses.emailTextField}
                    value={registerForm.phone}
                    color="secondary"
                    label="Teléfono"
                    variant="outlined"
                    //InputLabelProps={{shrink: true}}
                    onChange={handleInputChange('phone')}
                    placeholder="666555444"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PhoneIcon />
                            </InputAdornment>
                        ),
                    }}
                    {...props.getFieldProps('phone')}
                    helperText={props.touched.phone && props.errors.phone}
                    error={props.touched.phone && props.errors.phone}
                />
                <TextField
                    key="postalCode"
                    fullWidth
                    className={myClasses.emailTextField}
                    value={registerForm.postalCode}
                    color="secondary"
                    label="Código Postal"
                    variant="outlined"
                    //InputLabelProps={{shrink: true}}
                    onChange={handleInputChange('postalCode')}
                    placeholder="33000"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LocationIcon />
                            </InputAdornment>
                        ),
                    }}
                    {...props.getFieldProps('postalCode')}
                    helperText={props.touched.postalCode && props.errors.postalCode}
                    error={props.touched.postalCode && props.errors.postalCode}
                />


                <TextField
                    key="password"
                    className={myClasses.emailTextField}
                    fullWidth
                    value={registerForm.password}
                    color="secondary"
                    label="Contraseña"
                    variant="outlined"
                    type={registerForm.showPassword ? 'text' : 'password'}
                    //InputLabelProps={{shrink: true}}
                    onChange={handleInputChange('password')}
                    placeholder="Introduce tu password"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PasswordIcon />
                            </InputAdornment>
                        ), endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}>

                                    {registerForm.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    {...props.getFieldProps('password')}
                    helperText={props.touched.password && props.errors.password}
                    error={props.touched.password && props.errors.password}
                    />
                <TextField
                    key="repeatedPassword"
                    name="repeatedPassword"
                    id="repeatedPassword"
                    className={myClasses.emailTextField}
                    fullWidth
                    value={registerForm.repeatedPassword}
                    color="secondary"
                    label="Repite la contraseña"
                    variant="outlined"
                    type={registerForm.showRepeatPassword ? 'text' : 'password'}
                    //InputLabelProps={{shrink: true}}
                    onChange={handleInputChange('repeatedPassword')}
                    placeholder="Repite tu password"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PasswordIcon />
                            </InputAdornment>
                        ), endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    onClick={handleClickShowRepeatPassword}
                                    onMouseDown={handleMouseDownPassword}>

                                    {registerForm.showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    {...props.getFieldProps('repeatPassword')}
                    helperText={props.touched.repeatPassword && props.errors.repeatPassword}
                    error={props.touched.repeatPassword && props.errors.repeatPassword}
                    />


                <FormGroup className={myClasses.conditionsGroup}>
                    <FormControlLabel
                        control={<Checkbox id="conditionsAccepted" checked={props.values.conditionsAccepted}  {...props.getFieldProps('conditionsAccepted')} />}
                        label={<Typography className={myClasses.conditions}>He leído y acepto los  <Link href={process.env.REACT_APP_LEGAL_URL} target={"_blank"} color="secondary" style={{ textDecorationLine: 'underline', }}>
                            Términos Legales
                        </Link> y <Link href={process.env.REACT_APP_PRIVACY_URL} target={"_blank"} color="secondary" style={{ textDecorationLine: 'underline', }}>
                                Política de Privacidad
                        </Link></Typography>

                        }
                    />
                  {(props.errors.conditionsAccepted && (props.submitCount > 0)) ? <Typography className={myClasses.errorMessage}>Tienes que aceptar los términos legales y política de privacidad</Typography>:""}
                </FormGroup>
                <FormGroup className={myClasses.conditionsGroup}>
                    <FormControlLabel
                        control={<Checkbox id="marketingAccepted" checked={props.values.marketingAccepted}  {...props.getFieldProps('marketingAccepted')}/>}
                        label={<Typography className={myClasses.conditions}>Deseo recibir información y ofertas de {process.env.REACT_APP_PARTNER_NAME}</Typography>}
                    />

                </FormGroup>

                {errorMessage ? <Typography className={myClasses.errorMessage}>{errorMessage}</Typography>:""}
                <Box className={classes.footer}>
                    <Button
                        className={classes.acceptButton}
                        variant={"contained"}
                        color="primary"
                        fullWidth
                        //onClick={handleSubmit}
                        type="submit"
                        disabled={isRegistering}
                            >
                                {isRegistering ? "Creando...." : "Crear cuenta"}
                </Button>
                    <Button
                        //className={classes.acceptButton}
                        variant={"contained"}
                        color="primary"
                        fullWidth
                        onClick={onClickLogin}>
                        Ya tengo cuenta
                </Button>

                </Box>

            </form>)}
            </Formik>
        </Drawer>
    );


}

export default RegisterDrawer;

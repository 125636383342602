import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, Drawer} from "@material-ui/core";
import {drawerStyles} from '../cart/CartDrawer'
import DrawerHeader from '../cart/DrawerHeader'
import {getVouchersByClientIdAction} from "../../../redux/actions/voucher/getVouchersByClientIdAction";
import VouchersList from './VouchersList';

const VouchersHistoryDrawer = (props) => {
    const dispatch = useDispatch();
    const classes = drawerStyles();

    const { onHandleClose, open } = props

    const voucherSelected = useSelector(state => state["voucherReducer"].voucherSelected);
    const vouchers = useSelector(state => state["voucherReducer"].vouchers);
    const loadingVouchers = useSelector(state => state["voucherReducer"].loading);

    useEffect(() => {
        if (open) initVouchers();
        // eslint-disable-next-line
    }, [open]);

    const initVouchers = () => {
        console.log("INIT VOUCHERS")
        dispatch(getVouchersByClientIdAction(localStorage.getItem('clientId')));
    }

    return (
            <Drawer anchor={"right"} open={open} onClose={onHandleClose} classes={{paper: classes.paper}}>
                  <DrawerHeader title={"Tus Bonos"} onHandleClose={onHandleClose}/>
                  {
                loadingVouchers ? <CircularProgress /> : <VouchersList vouchers={vouchers} voucherSelected={voucherSelected}/>
            }
           </Drawer>
    );
}


export default VouchersHistoryDrawer;

export const DETAIL_ADDED_ERROR = 'DETAIL_ADDED_ERROR';
export const DETAIL_ADDED_SUCCESS = 'DETAIL_ADDED_SUCCESS';
export const DETAIL_SELECTED = 'DETAIL_SELECTED';

export const FETCH_FEATURED_DETAILS_SUCCESS = 'FETCH_FEATURED_DETAILS_SUCCESS';
export const FETCH_DETAILS = 'FETCH_DETAILS';
export const FETCH_DETAILS_ERROR = 'FETCH_DETAILS_ERROR';
export const FETCH_DETAILS_SUCCESS = 'FETCH_DETAILS_SUCCESS';

export const INIT_FETCH_FEATURED_DETAILS = 'INIT_FETCH_FEATURED_DETAILS';
export const CHANGE_VOUCHER_OPTIONS_VISIBILITY = 'CHANGE_VOUCHER_OPTIONS_VISIBILITY'

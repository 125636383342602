import React, { useEffect, useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Button,DialogContent, Drawer, InputBase, InputLabel, Paper, Snackbar, Typography } from '@material-ui/core';
import stripeIcon from '../../../assets/powered_stripe.png'
import { useDispatch, useSelector } from "react-redux";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {getSetupCreditCardClientSecretAction} from "../../../redux/actions/credit_card/getSetupCreditCardClientSecretAction"
import { drawerStyles } from '../cart/CartDrawer'
import DrawerHeader from '../cart/DrawerHeader'
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    paper: {
        background: "#FFFFFF",
        maxWidth: 400
    },
    actionBar: {
        marginTop: 20,
    },
    cardElement: {
        marginTop: 10,
        borderRadius: 4,
        borderColor: "#CCCCCC",
        borderWidth: 1,

        padding: 10
    },
    cardInputLabel: {
        paddingBottom: "4px"
    },
    nameInput: {
        padding: "0px",
        input: {
            padding: "0px"
        }
    },
    inlineElements: {
        display: "flex",
        flexDirection: "row"
    },
    logo:{
        height:"3em",
        float:"right",
        marginTop:"1rem"
    }
}))
const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: '16px',
            fontFamily: 'Roboto',
            color: 'rgba(0, 0, 0, 0.87)',
            letterSpacing: '0.025em',
            '::placeholder': {
                color: '#aab7c4',
            }
        },
        invalid: {
            color: '#9e2146',
        },
    },
};



const ErrorSnack = ({open,message,setErrorMessage}) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setErrorMessage(undefined);
      };
    return(
    <Snackbar
    open={open}
    autoHideDuration={6000}
    onClose={handleClose}
    anchorOrigin={{ vertical:"bottom", horizontal:"right" }}>
        <MuiAlert onClose={handleClose} severity="error">
          {message}
        </MuiAlert>
      </Snackbar>)
};
const SuccessSnack = ({open,message,setSuccessMessage}) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSuccessMessage(undefined);
      };
    return(
    <Snackbar
    open={open}
    autoHideDuration={6000}
    onClose={handleClose}
    anchorOrigin={{ vertical:"bottom", horizontal:"right" }}>
        <MuiAlert onClose={handleClose} severity="success">
          {message}
        </MuiAlert>
      </Snackbar>)
};

const CreditCardEditor = (props) => {
    const dispatch = useDispatch();
    const classes = drawerStyles();
    const myClasses = styles();

    const stripe = useStripe();
    const elements = useElements();

    const { open, onHandleClose } = props;

    const clientSecret = useSelector(state => state["cartReducer"].orderClientSecret);
    const setupClientSecret = useSelector(state => state["creditCardReducer"].setupClientSecret);


    const [form, setForm] = useState({
        name: ""
    });
    const [isLoading,setLoading] = useState(false
    );
    const [errorMessage,setErrorMessage] = useState(undefined
        );
    const [successMessage,setSuccessMessage] = useState(null
            );
    useEffect(() => {
        if (localStorage.getItem('clientId')) {
            dispatch(getSetupCreditCardClientSecretAction())
        }
    }, [open]);


    const onHandleChange = event => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }




    const onClickSaveButton = async event => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
           // dispatch(notifyOrderPaymentAction('error', payload.error.message));
           alert("SIN STRIPE OR ELEMENTS")
            return;
        }
        const cardElement = elements.getElement(CardNumberElement);

    //  alert("BEGIN CREATE")
    setLoading(true)

        const {setupIntent,error} = await stripe
            .confirmCardSetup(setupClientSecret,{
                payment_method:{
                    type:'card',
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: form.name,
                        address: {
                            postal_code: "33630",
                        }
                    }
                }
            });
        if (error) {
            setLoading(false)
            setErrorMessage(error.message)
           // dispatch(notifyOrderPaymentAction('error', payload.error.message));
        } else {
            setSuccessMessage("Tarjeta creada con éxito")
            setLoading(false)
            //onHandleClose();
        }
    }

    return (
        <Drawer anchor={"right"} disabled={isLoading} open={open} onClose={onHandleClose} classes={{ paper: classes.paper }} width={1 / 3}>

   <ErrorSnack open={errorMessage != undefined} message={errorMessage} setErrorMessage={setErrorMessage}></ErrorSnack>
   <SuccessSnack open={successMessage != undefined} message={successMessage} setSuccessMessage={setSuccessMessage}></SuccessSnack>

            <DrawerHeader title="Introduce los datos de la tarjeta" onHandleClose={onHandleClose} />
            <DialogContent style={{ width: 400, backgroundColor: "#FFFFFF" }}>
                <Paper variant="outlined" id="nombre" className={myClasses.cardElement}>
                    <InputLabel htmlFor="nombre" className={myClasses.cardInputLabel}>Nombre del propietario</InputLabel>

                    <InputBase fullWidth name="name" id="name" onChange={onHandleChange} classes={{ input: myClasses.nameInput }} placeholder="Nombre y apellidos"
                    />
                </Paper>
                <Paper variant="outlined" id="numero" className={myClasses.cardElement}>
                    <InputLabel htmlFor="numero" className={myClasses.cardInputLabel}>Número de tarjeta</InputLabel>
                    {/*<CardElement style={{base: {fontSize: '16px'}}}/>*/}
                    <CardNumberElement options={ELEMENT_OPTIONS} />
                </Paper>

                <div className={myClasses.inlineElements}>
                    <Paper variant="outlined" id="caducidad" className={myClasses.cardElement} style={{flexGrow:2}}>
                        <InputLabel htmlFor="caducidad" className={myClasses.cardInputLabel}>Fecha de caducidad</InputLabel>
                        <CardExpiryElement options={ELEMENT_OPTIONS} />
                    </Paper>
                    <Paper variant="outlined" id="cvc" className={myClasses.cardElement} style={{flexGrow:2,marginLeft:"1rem"}}>
                        <InputLabel htmlFor="cvc" className={myClasses.cardInputLabel}>CVC</InputLabel>
                        <CardCvcElement options={ELEMENT_OPTIONS} />
                    </Paper>
                </div>

                {errorMessage ? <Typography>{errorMessage}</Typography>:""}
                <div>
                    <div className={myClasses.actionBar}>
                        <Button variant="contained" className={classes.acceptButton} onClick={onClickSaveButton} disabled={setupClientSecret === undefined || isLoading}>
                           {isLoading ? "GUARDANDO...": "GUARDAR"}
                    </Button>
                    </div>
                    <img src={stripeIcon} className={myClasses.logo}/>
                </div>
            </DialogContent>

        </Drawer>
    );
}


export default CreditCardEditor;

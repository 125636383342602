import {useEffect} from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, Container, Typography} from "@material-ui/core";
import {fetchDetailsByCategoryAction} from "../../redux/actions/details/fetchDetailsByCategoryAction";
import DetailList from "../components/detail/DetailList";

const DetailsPage = () => {
    const dispatch = useDispatch();
    const {category_id} = useParams();

    const category = useSelector(state => state.categoryReducer.categorySelected);
    const categories = useSelector(state => state.categoryReducer.categories);
    const details = useSelector(state => state.detailReducer.details);
    const isLoading = useSelector(state => state.productReducer.loading);

    const getDetailsByCategoryId = () => {
        dispatch(fetchDetailsByCategoryAction(category_id));
    }

    useEffect(() => {
        if (category_id) getDetailsByCategoryId(category_id);

        // eslint-disable-next-line
    }, [category_id]);

    if (!category) return null;

    return (
        <Container>
            <Typography variant="h2" style={{textAlign:"center",margin:"3rem"}}>{category.name}</Typography>
            <div>
                {

                   // <CategoryList categories={categories}/> esta e
                    // <CategoriesTabBar categories={categories} />
                }
            </div>
            {
                isLoading
                    ? <CircularProgress/>
                    : <div>

                        <DetailList details={details}/>
                    </div>
            }
        </Container>
    )
}

export default DetailsPage;

import { createTheme } from '@material-ui/core/styles';
import "@fontsource/roboto-slab"
const black = "#FFFFFF"


/*export default createMuiTheme({
  palette: {
    primary: black,
    secondary: green
  },
});*/
export default createTheme({
  statusChipCanceled:{
    height:"32px",
    fontSize:"0.8125rem",
    textAlign:"center",
    paddingLeft:"12px",
    paddingRight:"12px",
    borderRadius:"1.5rem",
    color:"white",
    display:"inline-flex",
    backgroundColor:"red",
    alignItems:"center",
    fontFamily:"Roboto"
 },
 swalIcon:{
  width:"75px",
  height:"75px"
},
  mainDrawer:{
    overflowY: 'unset'
  },
  palette: {
    primary: {
      light: '#ffffff',
      main: '#fafafa',
      dark: '#c7c7c7',
      contrastText: '#000000',
    },
   /* secondary: {
      light: '#6d6d6d',
      main: '#424242',
    // main: '#90594C',
      dark: '#1b1b1b',
      contrastText: '#ffffff',
    },*/
    secondary: {
      light: '#ff8956',
      main: '#ec5729',
      dark: '#b22100',
      contrastText: '#ffffff',
    },
    delete:{
      light: '#FF0000',
      main: '#FF0000',
      dark: '#FF0000',
      contrastText: '#ffffff',
    }
  },
  typography:{
    fontFamily: "'Roboto Slab', serif",
    h1:{
      fontFamily:"'Roboto'"
    },
    h2:{
      fontFamily:"'Roboto'",
      color:"#b22100",
      fontWeight:"100"
    },h3:{
      fontFamily:"'Roboto'"
    },h4:{
      fontFamily:"'Roboto'"
    },h5:{
      fontFamily:"'Roboto'"
    },
    h6:{
      fontFamily:"'Roboto'",
      lineHeight:"1.2",
      fontSize:"18px"
    },
    body2:{
      fontSize:"13px",
      lineHeight:"1.5"
    },
    body1:{
      color:"#7A7A7A",
      fontSize:"13px",
      lineHeight:"1.5",
      fontWeight:"300"
    },
    subtitle2:{
      fontWeight:"700"
    },
    subtitle1:{
      lineHeight:"1.5",
      fontSize:"0.6rem"
    }
  },
  acceptButton: {
    marginBottom: "1rem",
    backgroundColor: "#4fd053",
    color:"white",
    '&:hover': {
        backgroundColor: '#47ba4a',
    },
},
overrides: {

  MuiCssBaseline: {
    '@global': {
      ".swal-button": {
        backgroundColor: "#90594C",
      },
      ".swal-title": {
        fontFamily: "'Roboto'",
        fontWeight:"normal"
      },
      ".swalert": {
        zIndex: "2000 !Important"
      },
      ".swal2-confirm":{

        minWidth: "150px !important",
        fontSize: "20px !important",
        fontFamily: 'Roboto',
      },
      ".swal2-cancel":{

        minWidth: "150px !important",
        fontSize: "20px !important",
        fontFamily: 'Roboto',
      },
      ".swal2-icon":{
       // borderColor:"white !Important",
      },
      ".swal2-title":{
        paddingTop:"20px !important",
        fontFamily: "'Roboto'",
        fontWeight:"normal"
      },

    },
  },
},
});

import axiosClient from "../config/axios";

export function getAddressesByClientIdAsync(clientId) {
    return axiosClient.get(`/clients/${clientId}/addresses?type=shipping`)
        .then(result => result.data)
        .catch(error => new Error(error))
}

export function createAddressByClientAsync(city, clientId, name, postalCode, street) {
    return axiosClient.post(`/clients/${clientId}/addresses`, {city, name, postalCode, street} )
        .then(result => result.data)
        .catch(error => new Error(error))
}

export function deleteAddressAsync(addressId) {
    return axiosClient.delete(`/addresses/${addressId}`)
        .then(result => result.data)
        .catch(error => new Error(error))
}
export function updateAddressByClientAsync({addressId,city, clientId, name, postalCode, street}) {
    return axiosClient.put(`/clients/${clientId}/addresses/${addressId}`, {city, name, postalCode, street} )
        .then(result => result.data)
        .catch(error => new Error(error))
}

import {IconButton, List, ListItem, ListItemIcon,ListItemText} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {changePaymentMethodSelectedAction} from "../../../redux/actions/payment_method/changePaymentMethodSelectedAction";
import {useDispatch} from "react-redux";
import {changeCreditCardEditorVisibilityAction} from "../../../redux/actions/credit_card/changeCreditCardEditorVisibilityAction";
import { Payment as CardIcon, Check as SelectedIcon } from "@material-ui/icons"


const useStyles = makeStyles((theme) => ({
    container: {
        margin: 0,
        width:400
    },
    paymentItem:{
        paddingLeft:15,
        paddingRight:0,
        alignItems:"center",
        justifyItems:"center",
        background:"white",
        borderBottom:"1px solid #f5f5f5"
  
    },
}));

const SelectPaymentMethodCell = ({paymentMethod, paymentMethodSelected}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    if (!paymentMethod) return null;

    const onClickPaymentMethod = () => {
        if (paymentMethod.creditCardRequired) {
            dispatch(changeCreditCardEditorVisibilityAction(true));
        }
        dispatch(changePaymentMethodSelectedAction(paymentMethod));
    }

    return (
        <ListItem  className = {classes.paymentItem} key={paymentMethod.id} onClick={onClickPaymentMethod}>
        <ListItemIcon>
                           <CardIcon />
                       </ListItemIcon>
       <ListItemText
           primary={ paymentMethod.name}
           secondary={paymentMethod.description}
       />
 
       {(paymentMethodSelected && paymentMethodSelected.id === paymentMethod.id) ?
           <ListItemIcon>
               <IconButton edge="end" aria-label="comments">
                    <SelectedIcon />
               </IconButton>
           </ListItemIcon>:""
           
           }
           </ListItem>
    )
};

const SelectPaymentMethodList = (props) => {
    const classes = useStyles();

    const { paymentMethods, paymentMethodSelected } = props;

    

    return (
        <div className={classes.container}>
            <List >
                {
                    paymentMethods.map(paymentMethod => <SelectPaymentMethodCell paymentMethod={paymentMethod} paymentMethodSelected={paymentMethodSelected} />)
                }
            </List>

        </div>

    )
}



export default SelectPaymentMethodList;

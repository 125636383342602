import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import {useDispatch,useSelector} from "react-redux";
import {drawerStyles} from '../cart/CartDrawer'
import DrawerHeader from '../cart/DrawerHeader'
import Drawer from '@material-ui/core/Drawer'
import {makeStyles} from '@material-ui/core/styles';
import {Formik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {loadClientAction} from "../../../redux/actions/client/loadClientAction"
import {updateClientAction} from "../../../redux/actions/client/updateClientAction"


const styles = makeStyles((theme) => ({
    paper:{
        background:"#FFFFFF",
        maxWidth:400
    },
    actionBar:{
        marginTop:20
    },
}))

const clientSchema = Yup.object({
    name: Yup.string().min(3, "Mínimo 3 carácteres").required('Debes introducir un nombre'),
    street: Yup.string().min(5, 'Mínimo 5 carácteres').required('Debes de introducir la calle, número y piso'),
    city: Yup.string().required('Debes de introducir el nombre de la ciudad'),
    postalCode: Yup.string().trim().matches(/^[0-9]+$/, "Código postal incorrecto").length(5, "Código postal incorrecto").required("Debes proporcionar un código postal"),
    phone: Yup.string().trim().min(9,"Faltan números").matches(/^[0-9]+$/, "El teléfono solo debe de estar compuesto por números").required('Debes introducir un teléfono'),
    cif:Yup.string().trim().length(9, "El CIF/NIF/NIE tiene que tener 9 caracteres").required('Debes introducir un CIF/NIF/NIE'),
    company: Yup.string().trim().min(3, "Mínimo 3 carácteres").required('Debes introducir  un nombre válido'),
})

const ClientDataDrawer = (props) => {
    const dispatch = useDispatch();
    const classes = drawerStyles();
    const myClasses = styles();

    const ReactSwal = withReactContent(Swal)
  

    const { onHandleClose, open } = props

   

    const isLoadingClient = useSelector(state => state["clientReducer"].loadingClient);
    const isUpdatingClient = useSelector(state => state["clientReducer"].updatingClient);
    const client = useSelector(state => state["clientReducer"].client);
    const updatingClientSuccess = useSelector(state => state["clientReducer"].updatingClientSuccess);
    const updatingClientError = useSelector(state => state["clientReducer"].updatingClientError);

    useEffect(() => {
        if (open) {
            dispatch(loadClientAction())
        }
    }, [open])

    useEffect(() => {
        if (updatingClientSuccess) {
           onHandleClose()
        }else if (updatingClientError){
            ReactSwal.fire({
                customClass:{
                    container:"swalert"
                },
                timer:2000,
                confirmButtonColor:"#90594c",
                timerProgressBar:true,
                title:updatingClientError,
                icon:"error",
                });
        }
    }, [updatingClientSuccess,updatingClientSuccess])

    return (
        <Drawer anchor={"right"} open={open} onClose={onHandleClose} classes={{paper: myClasses.paper}} width={1/3}>
        <DrawerHeader title="Datos de facturación" onHandleClose={onHandleClose}/>
        {client ? 
        <Formik
                initialValues={{
                    name: client ? client.name:"",
                    street: (client && client.billingInfo) ? client.billingInfo.street:"",
                    city: (client && client.billingInfo) ? client.billingInfo.city:"",
                    postalCode: client ? (client.billingInfo ? client.billingInfo.postalCode:client.postalCode):"",
                    phone: client ? client.phone:"",
                    cif:(client && client.billingInfo) ? client.billingInfo.nif:"",
                    company:(client && client.billingInfo) ? client.billingInfo.name:""
                }}
                enableReinitialize
                validationSchema={clientSchema}
                onSubmit={(values) => {
                    const clientForm = clientSchema.cast(values)//esto es necesario para que las transformaciones (por ejemplo, el trim) se apliquen
                    dispatch(updateClientAction(clientForm));
                }}>
                {props => (
                    <form onSubmit={props.handleSubmit}>
                        <DialogContent style={{ maxWidth: 400 }}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                name="name"
                                label="Nombre de contacto"
                                type="text"
                                color="secondary"
                                variant="outlined"
                                fullWidth
                                {...props.getFieldProps('name')}
                                helperText={props.touched.name && props.errors.name}
                                error={props.touched.name && props.errors.name}
                            />
                            <TextField
                                margin="dense"
                                id="street"
                                name="street"
                                label="Calle y Número"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('street')}
                                helperText={props.touched.street && props.errors.street}
                                error={props.touched.street && props.errors.street}
                            />
                            <TextField
                                margin="dense"
                                id="city"
                                name="city"
                                label="Ciudad"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('city')}
                                helperText={props.touched.city && props.errors.city}
                                error={props.touched.city && props.errors.city}
                            />
                            <TextField
                                margin="dense"
                                id="postalCode"
                                name="postalCode"
                                label="Código Postal"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('postalCode')}
                                helperText={props.touched.postalCode && props.errors.postalCode}
                                error={props.touched.postalCode && props.errors.postalCode}
                            />
                             <TextField
                                margin="dense"
                                id="phone"
                                name="phone"
                                label="Teléfono"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('phone')}
                                helperText={props.touched.phone && props.errors.phone}
                                error={props.touched.phone && props.errors.phone}
                            />
                            <TextField
                                margin="dense"
                                id="cif"
                                name="cif"
                                label="CIF/NIF/NIE"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('cif')}
                                helperText={props.touched.cif && props.errors.cif}
                                error={props.touched.cif && props.errors.cif}
                            />
                           
                            <TextField
                                margin="dense"
                                id="company"
                                name="company"
                                label="Razón Social"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('company')}
                                helperText={props.touched.company && props.errors.company}
                                error={props.touched.company && props.errors.company}
                            />
                            <div className={myClasses.actionBar}>
                                <Button fullWidth variant="contained" disable={isUpdatingClient || isLoadingClient}className={classes.acceptButton} type="submit" >
                                  {isUpdatingClient ?"GUARDANDO...":"GUARDAR"}
                    </Button>
                            </div>
                        </DialogContent>
                    </form>
                )}
            </Formik>
            :""}
               
     
            </Drawer>
    );
}


export default ClientDataDrawer;

import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import logo from '../../assets/footer_logo.png'
import {changeAvisoLegalVisibilityAction} from "../../redux/actions/info/changeAvisoLegalVisibilityAction"
import {changePrivacyPolicyVisibilityAction} from "../../redux/actions/info/changePrivacyPolicyVisibilityAction"
import {changeShopConditionsVisibilityAction} from "../../redux/actions/info/changeShopConditionsVisibilityAction"
import {changeNewsletterVisibilityAction} from "../../redux/actions/info/changeNewsletterVisibilityAction"
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
      },
  footer: {
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    position: "relative",

  },
  adornment: {
    width: "25em",
    verticalAlign: "bottom",
    [theme.breakpoints.down("md")]: {
      width: "21em"
    },
    [theme.breakpoints.down("xs")]: {
      width: "15em"
    }
  },
  mainContainer: {
    position: "absolute",
    backgroundColor: theme.palette.secondary.main,

  },
  link: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "0.75rem",
    textDecoration:"none"
  },


  gridItem: {
    margin: "3em"
  },
  icon: {
    height: "4em",
    width: "4em",
    [theme.breakpoints.down("xs")]: {
      height: "2.5em",
      width: "2.5em"
    }
  },
  socialContainer: {
    position: "absolute",
    marginTop: "-6em",
    right: "1.5em",
    [theme.breakpoints.down("xs")]: {
      right: "0.6em"
    }
  },
  title:{
      fontSize:"23px",
      color:"white",
      fontFamily:"Roboto Slab"
  },
  logo:{
    height:"8em",
    padding:"0.5em"
  },
  newsletter:{
    color: "white",
    fontFamily: "Roboto",
    fontSize: "0.75rem",
    fontWeight:"bold"
  }
}));





export default function Footer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showNewsletter = (event) => {event.preventDefault(); dispatch(changeNewsletterVisibilityAction(true));}

  return (
    <footer className={classes.footer}>

        <Grid container justify="left" direction={"row"}  alignItems={"flex-end"} className={classes.mainContainer}>
        <Grid item className={classes.gridItem} >
            <Link to={"/"} ><img src={logo} className={classes.logo}/></Link>
            </Grid>
        <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={2} >
              <Grid
                item
                className={classes.title}
              >
              Contacto
              </Grid>
              <Grid
                item
              >
                <a  className={classes.link}  href={`tel:${process.env.REACT_APP_CONTACT_PHONE}`} target={"_blank"}>  { process.env.REACT_APP_CONTACT_PHONE }</a>
              </Grid>
              <Grid
                item
              >
               <a  className={classes.link}  href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`} target={"_blank"}>  { process.env.REACT_APP_CONTACT_EMAIL }</a>
              </Grid>
              <Grid
                item
              >
                <a className={classes.link} href={process.env.REACT_APP_FACEBOOK_LINK}>Facebook</a>
                | <a className={classes.link} href={process.env.REACT_APP_INSTAGRAM_LINK}>Instagram</a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem} >
            <Grid item container direction="column" spacing={2} justifyContent="flex-end" >

              <Grid
                item
              >
                <a className={classes.link} href={process.env.REACT_APP_LEGAL_URL} target={"_blank"}>Aviso Legal</a>
              </Grid>
              <Grid
                item
              >
                <a className={classes.link} href={process.env.REACT_APP_PRIVACY_URL} target={"_blank"}>Política de Privacidad</a>
              </Grid>
              <Grid
                item
              >
                <a className={classes.link} href={process.env.REACT_APP_PRIVACY_URL} target={"_blank"}>Entregas y devoluciones</a>
              </Grid>
              <Grid
                item
                component={Link}
                onClick={showNewsletter}

                className={classes.newsletter}
              >
                Apúntate a nuestra newsletter
              </Grid>
            </Grid>
            <Grid/>
          </Grid>
      </Grid>
    </footer>
  );
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Drawer } from "@material-ui/core";
import { drawerStyles } from '../cart/CartDrawer'
import DrawerHeader from '../cart/DrawerHeader'
import OrderList from './OrdersList'
import { fetchOrdersHistoryAction } from "../../../redux/actions/order/fetchOrdersHistoryAction";
import OrderDetail from './OrderDetail';
import { changeOrderSelectedAction } from "../../../redux/actions/order/changeOrderSelectedAction";

const OrdersHistoryDrawer = (props) => {
    const dispatch = useDispatch();
    const classes = drawerStyles();

    const { onHandleClose, open } = props

    const orderSelected = useSelector(state => state["orderReducer"].orderSelected);
    const ordersHistory = useSelector(state => state["orderReducer"].ordersHistory);
    const loadingOrders = useSelector(state => state["orderReducer"].loading);

    useEffect(() => {
        if (open) initOrders();
        // eslint-disable-next-line
    }, [open,orderSelected]);

    const initOrders = () => {
        dispatch(fetchOrdersHistoryAction());
    }
    const onClickCloseButton = () => {
        onHandleClose()
    }
const onDetailClosed = () => {
        dispatch(changeOrderSelectedAction(null))
}


    if (orderSelected) {
        return (
            <OrderDetail order={orderSelected} onHandleClose={onDetailClosed}/>
        );
    } else {
        return (

            <Drawer anchor={"right"} open={open} onClose={onHandleClose} classes={{paper: classes.paper}}>
                <DrawerHeader title={"Tus Pedidos"} onHandleClose={onHandleClose} />
                {
                    loadingOrders ? <CircularProgress /> : <OrderList orders={ordersHistory} />
                }
            </Drawer>
        );
    }
}



export default OrdersHistoryDrawer;

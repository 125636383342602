import {CHANGE_PAY_ORDER_VISIBILITY} from "../../types/cartTypes";

export function changePayOrderVisibilityAction(visibility) {
    return (dispatch) => {
        dispatch(changeVisibility(visibility));
    }
}

const changeVisibility = visibility => ({
   type: CHANGE_PAY_ORDER_VISIBILITY,
   payload: visibility
});

import React, {useEffect} from 'react';
import SelectShippingMethodList from "../shipping_method/SelectShippingMethodList";
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress} from "@material-ui/core";
import {fetchShippingMethodsAction} from "../../../redux/actions/shipping_method/fetchShippingMethodsAction";
import {drawerStyles} from '../cart/CartDrawer'
import DrawerHeader from '../cart/DrawerHeader'
import Drawer from '@material-ui/core/Drawer'
import {makeStyles} from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
    paper:{
        background:"#FFFFFF",
        maxWidth:400
    }
}))


const SelectShippingMethodDrawer = (props) => {
    const dispatch = useDispatch();
    const classes = drawerStyles();
    const myClasses = styles();
    const { onHandleClose, open } = props

    const shippingMethodSelected = useSelector(state => state["shippingMethodReducer"].shippingMethodSelected);
    const shippingMethods = useSelector(state => state["shippingMethodReducer"].shippingMethods);
    const loadingShippingMethods = useSelector(state => state["shippingMethodReducer"].loading);

    useEffect(() => {
        if (open) initShippingMethods();
        // eslint-disable-next-line
    }, [open]);

    const initShippingMethods = () => {
        dispatch(fetchShippingMethodsAction());
    }

    

    return (
        <Drawer anchor={"right"} open={open} onClose={onHandleClose} classes={{paper: myClasses.paper}}>
             <DrawerHeader title="Elige un método de envío" onHandleClose={onHandleClose}/>
             {(loadingShippingMethods) ? <CircularProgress /> :
             <SelectShippingMethodList shippingMethods={shippingMethods} shippingMethodSelected={shippingMethodSelected} />}
        </Drawer>
    );
};



export default SelectShippingMethodDrawer;

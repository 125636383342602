import React, {useEffect} from 'react';
import CreditCardsList from "./CreditCardsList";
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, Drawer} from "@material-ui/core";
import {fetchCreditCardsAction} from "../../../redux/actions/credit_card/fetchCreditCardsAction";
import {drawerStyles} from '../cart/CartDrawer'
import DrawerHeader from '../cart/DrawerHeader'
import CreditCardEditor from './CreditCardEditor'
import {Elements} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {changeCreditCardEditorVisibilityAction} from "../../../redux/actions/credit_card/changeCreditCardEditorVisibilityAction";

const CreditCardSelector = (props) => {
    const dispatch = useDispatch();
    const classes = drawerStyles();
    const stripe = loadStripe("pk_test_51JoP8fFU3yPjTYKah16gg257J8VTGnh2kcQxTVdDKxlcmdiPnTyD2lV9LSu1n3u4uOxQtGDlQm01ehw5uUmfpNh30098dYfOou");

    const { onHandleClose, open } = props

    const accountDrawerOpened = useSelector(state => state["accountReducer"].accountDrawerOpened);
    const creditCardSelected = useSelector(state => state["creditCardReducer"].creditCardSelected);
    const creditCardEditorOpened = useSelector(state =>state["creditCardReducer"].creditCardEditorOpened);
    const creditCards = useSelector(state => state["creditCardReducer"].creditCards);
    const loadingCreditCards = useSelector(state => state["creditCardReducer"].loading);

    useEffect(() => {
        if (open){ initCards();
    }
        // eslint-disable-next-line
    }, [open]);


    const initCards = () => {
        dispatch(fetchCreditCardsAction());
    }
    const onClickCloseButton = () => {
        onHandleClose()
    }
    const onCloseCreditCardEditor = () => {
        dispatch(changeCreditCardEditorVisibilityAction(false));
    }
    return (

        <React.Fragment >
            <Drawer anchor={"right"} open={open} onClose={onHandleClose} classes={{paper: classes.paper}}>
                  <DrawerHeader title={accountDrawerOpened ? "Tus tarjetas":"Elige una tarjeta"} onHandleClose={onHandleClose}/>
                  {
                loadingCreditCards ? <CircularProgress /> : <CreditCardsList creditCards={creditCards} cardSelected={creditCardSelected} />
            }
           </Drawer>
            <Elements stripe={stripe}>
                <CreditCardEditor open={creditCardEditorOpened} onHandleClose={onCloseCreditCardEditor} />
            </Elements>
        </React.Fragment>
    );
}



export default CreditCardSelector;

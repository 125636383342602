import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Button, Typography } from "@material-ui/core";
import DetailsInCartList from "../detail/DetailsInCartList";
import { changeShoppingInfoVisibilityAction } from "../../../redux/actions/cart/changeShoppingInfoVisibilityAction";
import { changeRegisterDrawerVisibilityAction } from "../../../redux/actions/auth/changeRegisterDrawerVisibilityAction";
import { changeAuthFormVisibilityAction } from "../../../redux/actions/auth/changeAuthFormVisibilityAction";
import { useDispatch, useSelector } from "react-redux";
import { Box } from '@material-ui/core/';
import DrawerHeader from './DrawerHeader'

const drawerStyles = makeStyles((theme) => ({
    container: {
        width: 400
    },

    paper: {
        background: "#F5F5F5",
        maxWidth: 400,
        overflow: 'unset'
    },
    emptyCart: {
        flexGrow: 50,
        padding: 20,
        maxWidth: 400,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column"
    },

    continueButtonQuantity: {
        backgroundColor: "#CCC7",
        borderRadius: ".375rem",
        color: "#FFF",
        webKitBoxFlex: "0",
        webKitFlex: "0 0 2rem",
        flex: "0 0 2rem",
        fontSize: "1.125rem",
        lineHeight: "1.78",
        flex: 0.1
    },

    continueButtonLabel: {
        flex: 1
    },
    continueButtonImport: {
        flex: 0.1,
    },
    footer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        padding: '1rem',
        boxShadow: "0 -1px 8px 0 rgba(0,0,0,.1)"
    },
    acceptButton: {
        marginBottom: "1rem",
        backgroundColor: "#4fd053",
        color: "white",
        '&:hover': {
            backgroundColor: '#47ba4a',
        },
        width: "100%"
    },
    acceptButtonDisabled: {
        opacity: "0.5",
        backgroundColor: 'lightGray',
        color: "black"
    },
    deleteButton: {
        backgroundColor: "#ff373d",
        color: "white"
    }
}));

const CartDrawer = (props) => {
    const classes = drawerStyles();
    const dispatch = useDispatch();

    const { onHandleClose, open } = props;

    const details = useSelector(state => state["cartReducer"].detailsInCart);
    const isLogged = useSelector(state => state["authReducer"].isLogged);


    const onClickPaymentButton = () => {
        if (isLogged) {
            dispatch(changeShoppingInfoVisibilityAction(true));
        } else {
            if (process.env.REACT_APP_CREATE_ACCOUNT_DISABLED) {
                dispatch(changeAuthFormVisibilityAction(true))
            } else {
                dispatch(changeRegisterDrawerVisibilityAction(true))
            }
        }
    }
    const onClickCloseButton = () => {
        onHandleClose()
    }
    if (!details || details.length == 0) return (
        <Drawer anchor={"right"} open={open} onClose={onHandleClose} classes={{ paper: classes.paper }} width={1 / 3}>
            <DrawerHeader title="Carrito" onHandleClose={onHandleClose} />
            <Typography align="center" variant="h6" className={classes.emptyCart}>
                No tienes ningún producto en tu carrito en estos momentos
                </Typography>
        </Drawer>

    );
    return (
        <React.Fragment>
            <Drawer anchor={"right"} open={open} onClose={onHandleClose} classes={{ paper: classes.paper }}>
                <DrawerHeader title="Carrito" onHandleClose={onHandleClose} />
                <DetailsInCartList details={details} />
                <Box className={classes.footer}>
                    <Button variant={"contained"} type="submit" color="secondary" fullWidth disableElevation className={classes.continueButton} onClick={onClickPaymentButton} >
                        <span className={classes.continueButtonQuantity}>{details.length}</span>
                        <span className={classes.continueButtonLabel}>Ir a Pago y Envío</span>
                        <span className={classes.continueButtonImport}>{details.reduce((a, b) => a + (b.getPrice() || 0), 0).toFixed(2)}€</span>
                    </Button>
                </Box>
            </Drawer>
        </React.Fragment>
    );
}

export default CartDrawer;
export { drawerStyles };

import React, {useEffect} from 'react';
import { IconButton, Box, TextField, List, Typography,Container} from '@material-ui/core/';

import { useDispatch } from "react-redux";

import 'react-gallery-carousel/dist/index.css'
import { makeStyles } from '@material-ui/core/styles';
import { PlusCircle as PlusIcon, MinusCircle as MinusIcon, Trash2 as DeleteIcon, ShoppingCart as CartIcon, ShoppingBag as BagIcon, ShoppingBag } from "react-feather";



const VoucherOptions = (props) => {
    const dispatch = useDispatch();
    const {options,maxUnits,fullScreen} = props;
    const [onCart, setOnCart] = React.useState(true);
    
      
    
    


    const useStyles = makeStyles((theme) => ({

        paper: {
            background: "#F5F5F5",
            elevation: 1
        },
        formLabel: {
            paddingBottom: "1.5rem"
        },
        
        dialogContent: {
            display: 'flex',
            flexDirection: 'column',
            padding: "20px,24px,24px,24px",
            overflowY:"hidden",
            borderBottom:0

        },
      
        
        option: {
            width: "100%",
            display: "inline-flex",
            flexDirection: "row",
            marginTop: "1auto",
            borderRadius: "6px",
            justifyContent: "center",
            alignItems: "center",
            verticalAlign: "middle",
            marginBottom:"0.5rem"
        },
        optionResponsive:{
            width: "100%",
            display: "flex",
            flexWrap:"wrap",
            flexDirection: "row",
            marginTop: "1auto",
            borderRadius: "6px",
            justifyContent: "center",
            alignItems: "center",
            verticalAlign: "middle",
            marginBottom:"0.5rem",
            paddingRight:0,
            paddingLeft:0
        },
        detailUnits: {
            display: "-webkit-inline-box",
            display: "-webkit-inline-flex",
            display: "inline-flex",
            webkitBoxOrient: "vertical",
            webKitBoxDirection: "normal",
            webkitFlexDirection: "column",
            flexDirection: "column",
            webkitBoxAlign: "top",
            webkitAlignItems: "top",
            alignItems: "top",
            textAlign: "center",
            alignSelf:"center",
            paddingLeft:"5px"
        }, personsTextField: {
            marginRight: "1rem"
        },
        optionsList:{
            paddingTop:"0px",
            paddingBottom:"0px"
        },
        optionContainer:{
            display:"flex",
            flexDirection:"column",
            paddingLeft:0,
            paddingRight:0
        },
        optionTitle:{
            paddingLeft:"0px",
            fontWeight:"bold",
            fontSize:"14px"
        },
        optionDescription:{
            paddingLeft:"0px",
            paddingRight:"8px",
            fontSize:"13px"
        },
        optionValue:{
            fontSize:"15px",
            minWidth: "fit-content",
            padding: "0px",
            minWidth: "90px",
            alignSelf:"center"
        },
        optionTotal:{
            fontSize:"15px",
            minWidth: "fit-content",
            paddingLeft: "20px",
            paddingRight:"0px",
            minWidth:"90px",
            textAlign:"center",
            fontWeight:"bold",
            alignSelf:"center"
        },
        selectUnitsContainer:{
            display:"flex",
            wrap:"nowrap",
            paddingTop:"5px",
            paddingLeft:0,
            paddingRight:0
        },
        unitsTextField:{
            alignSelf:"center"
        }

    }));
    const classes = useStyles();
    

    const VoucherOption = ({option, maxUnits}) => {
        const [quantity, setQuantity] = React.useState(option.quantity ? option.quantity:0);
        const onPlusClicked = () => {
            //dispatch(incDetailInCartAction(detail));
            var newQuantity = quantity+1;
            setQuantity(newQuantity)
            option.quantity = newQuantity
        }
        const onMinusClicked = () => {
           // dispatch(decDetailInCartAction(detail))
           
         if (quantity > 0) {
            var newQuantity = quantity-1;
            setQuantity(newQuantity)
            option.quantity = newQuantity
         }
        
         
          
        }
        const onQuantityChanged = (event) => {
          
           setQuantity(event.target.value)
           option.quantity = quantity
        }
        /*const detailQuantityIsValid = () =>{
            if (option.quantity <= 0) return false
            if (minUnits && (option.quantity < minUnits)) return false
            if (maxUnits && (option.quantity > maxUnits)) return false
            return true
        }*/
      

        return (
        <React.Fragment key={option.name}>
            <Box className={fullScreen ? classes.optionResponsive:classes.option} >
                
                    <Container className={classes.optionContainer}>
                    <Typography className={classes.optionTitle}>{option.name}</Typography>
                    <Typography className={classes.optionDescription}>{option.description}</Typography>
                    </Container>
                    <Container className={classes.selectUnitsContainer}>
                    <Typography className={classes.optionValue}>{(option.value *1.10).toFixed(2)+" €/ud"}</Typography>
                    <TextField 
                        type="number" 
                        className={classes.unitsTextField}
                        color="secondary"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true
                        }}
                        placeholder="unidades" 
                        value={quantity} />

                <div className={classes.detailUnits} disablegutters="true">
                    <IconButton size="small" onClick={onPlusClicked} disabled={maxUnits >= 0 && maxUnits === quantity}><PlusIcon /></IconButton>
                    <IconButton size="small" onClick={onMinusClicked} disabled={quantity === 0 }><MinusIcon /></IconButton>
                </div>
                <Typography className={classes.optionTotal}>{quantity ? ((option.value*quantity*1.1).toFixed(2)+" €"):"0.00 €"}</Typography>
                </Container>
            </Box>
        </React.Fragment>
        )
    }
    ;
    
    
    

   
    
   // const acceptDisabled = isAGift ? (voucherShippingInfo ? !voucherShippingInfo.email : true) : false 
  
 
    if (!options  || options.count == 0) return null;

    return (
        <React.Fragment>

<List className={classes.optionsList}>
                {
                    options.map(option => <VoucherOption option={option} maxUnits={maxUnits}/>)
                }
            </List>
        </React.Fragment>
    );
}






export default VoucherOptions;
import React, { useEffect } from 'react';
import SelectShippingAddressList from "./SelectShippingAddressList";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Drawer } from "@material-ui/core";
import { fetchShippingAddressesAction } from "../../../redux/actions/address/fetchShippingAddressesAction";
import { drawerStyles } from '../cart/CartDrawer'
import DrawerHeader from '../cart/DrawerHeader'
import EditAddressDrawer from '../address/EditAddressDrawer'
import CreateAddressDrawer from '../address/CreateAddressDrawer'
import { changeShippingAddressSelectedAction } from "../../../redux/actions/address/changeShippingAddressSelectedAction";
import { changeEditingAddressAction } from "../../../redux/actions/address/changeEditingAddressAction";
import { Fragment } from 'react';
import { changeAddressEditorVisibilityAction } from "../../../redux/actions/address/changeAddressEditorVisibilityAction";

const SelectAddressDrawer = (props) => {
    const dispatch = useDispatch();
    const classes = drawerStyles();

    const { onHandleClose, open, isBilling } = props

    const accountDrawerOpened = useSelector(state => state["accountReducer"].accountDrawerOpened);
    const addressSelected = useSelector(state => state["addressReducer"].shippingAddressSelected);
    const addresses = useSelector(state => state["addressReducer"].addresses);
    const loadingAddresses = useSelector(state => state["addressReducer"].loading);
    const createAddressSuccess = useSelector(state => state["addressReducer"].createAddressSuccess);
    const editingAddress = useSelector(state => state["addressReducer"].editingAddress)
    const addressEditorOpened = useSelector(state => state["addressReducer"].addressEditorOpened);

    const onCloseAddressEditor = () => {
        dispatch(changeAddressEditorVisibilityAction(false));
    }

    useEffect(() => {
        if (open) initAddresses();
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        if (open && createAddressSuccess && !accountDrawerOpened) {
            onHandleClose(); //Esto lo ahcemos para que cuando se esté creando una dirección durante el proceso de pedido, cerremos el selector para dejar visible el propio pedido
        }
        // eslint-disable-next-line
    }, [createAddressSuccess]);

    const initAddresses = () => {
        dispatch(fetchShippingAddressesAction());
    }
    const onClickCloseButton = () => {
        onHandleClose()
    }
    const onAddressSelected = (address) => {
        accountDrawerOpened ?
            dispatch(changeEditingAddressAction(address))
            :
            dispatch(changeShippingAddressSelectedAction(address))
        onHandleClose()
    }


    return (

        <Fragment>
            <Drawer anchor={"right"} open={open} onClose={onHandleClose} classes={{ paper: classes.paper }}>
                <DrawerHeader title={accountDrawerOpened ? "Tus direcciones" : isBilling ? "Dirección de facturación" : "Dirección de entrega"} onHandleClose={onHandleClose} />
                {
                    loadingAddresses ? <CircularProgress /> : <SelectShippingAddressList addresses={addresses} addressSelected={addressSelected} onAddressSelected={onAddressSelected} forEditing={accountDrawerOpened} />
                }
            </Drawer>
            <EditAddressDrawer open={editingAddress} address={editingAddress} billing={isBilling} />
            <CreateAddressDrawer open={addressEditorOpened} onHandleClose={onCloseAddressEditor} />
        </Fragment>
    );
}



export default SelectAddressDrawer;
import React from "react";
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Detail from '../../../models/Detail'

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 400
    },
    paper: {
        background: "black"
    },
    detailItem: {
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        borderBottom: "1px solid #f5f5f5"

    },
    detailPrice: {
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "flex",
        webkitBoxOrient: "vertical",
        webKitBoxDirection: "normal",
        webkitFlexDirection: "column",
        flexDirection: "column",
        webkitBoxAlign: "end",
        webkitAlignItems: "flex-end",
        alignItems: "flex-end",
        overflow: "hidden",
        webkitFlexShrink: 0,
        flexShrink: 0,
        position: "relative"
    },
    detailUnits: {
        padding: "5px",
        display: "-webkit-inline-box",
        display: "-webkit-inline-flex",
        display: "inline-flex",
        webkitBoxOrient: "vertical",
        webKitBoxDirection: "normal",
        webkitFlexDirection: "column",
        flexDirection: "column",
        webkitBoxAlign: "center",
        webkitAlignItems: "center",
        alignItems: "center",
        textAlign: "center"
    },

    detailProductName: {
        alignItems: "center",
        lineHeight: "1"
    },
    detailProductImage: {
        marginTop: 0
    },
    detailUnitsLabel: {
        textAlign: "center"
    },
    MuiListItemRoot: {
        padding: "10px"
    }
}));

const DetailInOrderCell = ({ detail }) => {

    const theme = useTheme();
    const classes = useStyles();

    const onDetailClicked = () => {
        //dispatch(changeDetailSelectedAction(detail))
    }
    const onEditVoucherClicked = () => {
        console.log("EDIT CLICKED")
        // dispatch(changeDetailSelectedAction(detail))
        // dispatch(changeVoucherOptionsVisibilityAction(true))
    }
    const onPlusClicked = () => {
        console.log("PLUS CLICKED")
        //dispatch(incDetailInCartAction(detail));

    }
    const onMinusClicked = () => {
        //dispatch(decDetailInCartAction(detail))
    }



    if(!detail)
        return null;


    return (
        (detail.price.product.voucherInfo && detail.price.product.voucherInfo.type == "product") ?
            <ListItem alignItems="flex-start" key={detail.price.id} classes={{ paper: classes.paper }} className={classes.detailItem}>

                <ListItemAvatar className={classes.detailProductImage} onClick={onDetailClicked}>
                    <Avatar alt={detail.price.product.name} src={Detail.getMainPhoto(detail)} />

                </ListItemAvatar>
                <ListItemText
                    primary={
                        detail.price.product.name
                    }
                    primaryTypographyProps={
                        {
                            component: "span",
                            variant: "body2",
                            color: "text.primary"
                        }}
                    /*  secondary={`${detail.quantity} adultos
                      (${Detail.getUnitaryPrice(detail).toFixed()}€/adulto)`}*/
                    secondary={
                        <div>
                            <div>{detail.price.product.voucherInfo.type === "product" ?
                                `${detail.quantity} x adulto`:
                                `Valor: ${detail.quantity*detail.price.value} €`}
                            </div>
                            {detail.optionsSelected ?
                                detail.optionsSelected.map(option => option.quantity ? <div>{option.quantity} x {option.name}</div>:""):""
                            }

                        </div>


                    }
                    secondaryTypographyProps={
                        {
                            variant: "subtitle1",
                        }
                    }
                    onClick={onDetailClicked}
                    className={classes.detailProductName}
                />
                <ListItemText
                    primary={<React.Fragment>
                        <Typography
                            component="span"
                            variant="subtitle2"
                            color="text.primary"
                        >
                            {Detail.getPrice(detail).toFixed(2)}€
                        </Typography>
                    </React.Fragment>}
                    className={classes.detailPrice}
                />
            </ListItem>
            :
            <ListItem alignItems="flex-start" key={detail.price.id} classes={{ paper: classes.paper }} className={classes.detailItem}>

                <ListItemAvatar className={classes.detailProductImage} onClick={onDetailClicked}>
                    <Avatar alt={detail.price.product.name} src={Detail.getMainPhoto(detail)} />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        detail.price.product.name
                    }
                    primaryTypographyProps={
                        {
                            component: "span",
                            variant: "body2",
                            color: "text.primary"
                        }}
                    secondary={
                        detail.quantity +" x "+detail.price.value + "€/ud"}
                    secondaryTypographyProps={
                        {
                            variant: "subtitle1",
                        }

                    }
                    onClick={onDetailClicked}
                    className={classes.detailProductName}
                />
                <ListItemText
                    primary={<React.Fragment>
                        <Typography
                            component="span"
                            variant="subtitle2"
                            color="text.primary"
                        >
                            {Detail.getPrice(detail).toFixed(2)}€
                        </Typography>
                    </React.Fragment>}
                    className={classes.detailPrice}
                />
            </ListItem>
    )
}

const DetailsInOrderList = (props) => {
    const classes = useStyles();

    const { details } = props;

    if (!details) return <Typography>Carrito Vacío</Typography>

    return (
        <div className={classes.container}>
            <List>
                {

                    details.map(detail => <DetailInOrderCell detail={detail} />)
                }
            </List>

        </div>
    )
}



export default DetailsInOrderList;

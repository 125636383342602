import { IconButton, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { changeShippingMethodSelectedAction } from "../../../redux/actions/shipping_method/changeShippingMethodSelectedAction";
import { useDispatch, useSelector } from "react-redux";
import { Check as SelectedIcon, LocalShipping as ShippingIcon } from "@material-ui/icons"


const useStyles = makeStyles((theme) => ({
    container: {
        margin: 0,
        width: 400
    },
    shippingItem: {
        paddingLeft: 15,
        paddingRight: 0,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        borderBottom: "1px solid #f5f5f5"

    },
}));

const SelectShippingMethodCell = ({ shippingMethod, shippingMethodSelected }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const detailsInCart = useSelector(state => state["cartReducer"].detailsInCart);
    const shippingMethodValueDescription = () => {
        let cartValue = detailsInCart.reduce((a, b) => a + (b.getPrice() || 0), 0);
        if (shippingMethod.freeFrom && (cartValue > shippingMethod.freeFrom)) {
            return `Gratis a partir de ${shippingMethod.freeFrom}€`
        }
        if (shippingMethod.freeFrom) {
            if (cartValue > shippingMethod.freeFrom) {
                return `Gratis a partir de ${shippingMethod.freeFrom}€`
            }
            return `${shippingMethod.value.toFixed(2)}€ (Gratis a partir de ${shippingMethod.freeFrom}€)`
        }
        if (shippingMethod.value == 0) {
            return "Sin coste adicional"
        }
        return `${shippingMethod.value.toFixed(2)}€`;
    }


    if (!shippingMethod) return null;

    const onClickShippingMethod = () => {
        dispatch(changeShippingMethodSelectedAction(shippingMethod));
    }

    return (
        <ListItem key={shippingMethod.id} onClick={onClickShippingMethod} className={classes.shippingItem}>
            <ListItemIcon>
                <ShippingIcon />
            </ListItemIcon>
            <ListItemText
                primary={shippingMethod.name}
                secondary=
                {
                    shippingMethodValueDescription()

                }
            />
            {(shippingMethodSelected && shippingMethodSelected.id === shippingMethod.id) ?
                <ListItemIcon>
                    <IconButton edge="end" aria-label="comments">
                        <SelectedIcon />
                    </IconButton>
                </ListItemIcon> : ""
            }
        </ListItem>
    )
}

const SelectShippingMethodList = (props) => {
    const classes = useStyles();

    const { shippingMethods, shippingMethodSelected } = props;

    if (!shippingMethods) return (<p>Ningún método de envío disponible para tu dirección</p>)

    return (
        <div className={classes.container}>
            <List>
                {
                    shippingMethods.map(shippingMethod => <SelectShippingMethodCell shippingMethod={shippingMethod}
                        shippingMethodSelected={shippingMethodSelected} />)
                }
            </List>

        </div>

    )
}


export default SelectShippingMethodList;

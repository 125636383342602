import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React,{useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { changeVoucherSelectedAction } from "../../../redux/actions/voucher/changeVoucherSelectedAction";
import { useDispatch } from "react-redux";
//import { changeVoucherHistoryVisibilityAction } from "../../../redux/actions/voucher/changeVoucherHistoryVisibilityAction";
//import { changeVoucherDetailVisibilityAction } from "../../../redux/actions/voucher/changeVoucherDetailVisibilityAction";
import { Redeem as VoucherIcon } from "@material-ui/icons"


const useStyles = makeStyles((theme) => ({
    container: {
        margin: 0,
        width: 400,
        overflowY: 'scroll'
    },
    voucherItem: {
        paddingLeft: 15,
        paddingRight: 0,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        bvoucherBottom: "1px solid #f5f5f5"

    },
    voucherStatusActive:{
       
    },
}));




const VoucherCell = ({ voucher }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    if (!voucher) return null;

    const onClickVoucher = () => {
        dispatch(changeVoucherSelectedAction(voucher))
    }
  
    return (
        <React.Fragment>
        <ListItem alignItems="flex-start" className={classes.voucherItem} key={voucher.id} onClick={onClickVoucher}>
            <ListItemIcon>
                <VoucherIcon />
            </ListItemIcon>
            {(voucher.type == "product") ?
                <ListItemText
                    primary={voucher.product.name}
                    secondary={
                        "Número de adultos: " + voucher.amount
                    }
                />
                :
                <ListItemText
                    //primary={DateTime.fromISO(voucher.createdAt).setLocale('es').toLocaleString(DateTime.DATE_FULL)}
                    primary={voucher.product.name}
                    secondary={
                        "Importe restante: " + voucher.getRemainingAmount().toFixed(2) + "€"
                    }
                />
            }
            <ListItemIcon>
                <IconButton aria-label="comments">
                    <span   style={voucher.getStatusStyle()}>{voucher.getStatus().toUpperCase()}</span>
                </IconButton>
            </ListItemIcon>
            
        </ListItem>
        <Divider />
        </React.Fragment>
       
    )
}

const VouchersList = ({ vouchers, voucherSelected }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    if (!vouchers) vouchers = [];
    /* if (vouchers.length > 5){
        vouchers = vouchers.slice(0, 5)
     } */
     useEffect(() => {
        vouchers = vouchers
        // eslint-disable-next-line
    }, [vouchers]);

    

    return (
        <div className={classes.container}>
            <List >
                {
                    vouchers.map(voucher => <VoucherCell voucher={voucher} voucherSelected={voucherSelected} />)
                }
            </List>
            
        </div>

    )

}

export default VouchersList;
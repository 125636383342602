import {
    ADDRESS_CREATED_ERROR,
    ADDRESS_CREATED_SUCCESS,
    ADDRESS_DELETED_ERROR, 
    ADDRESS_DELETED_SUCCESS,
    ADDRESS_UPDATED_ERROR,
    ADDRESS_UPDATED_SUCCESS,
    CHANGE_ADDRESS_SELECTOR_VISIBILITY, 
    CHANGE_CREATE_ADDRESS_VISIBILITY,
    CHANGE_EDITING_ADDRESS,
    CHANGE_SHIPPING_ADDRESS_SELECTED,
    FETCH_ADDRESSES_SUCCESS,
    INIT_CREATE_ADDRESS,
    INIT_DELETE_ADDRESS,
    INIT_FETCH_ADDRESS,
    INIT_UPDATE_ADDRESS
} from "../types/addressTypes";

const initialState = {
    addresses: [],
    addressEditorOpened: false,
    addressSelectorOpened: false,
    billingAddressSelected: null,
    loading: false,
    shippingAddressSelected: null,
    deletingAddress:false,
    deleteAddressError:null,
    deleteAddressSuccess:null,
    creatingAddress:false,
    createAddressError:null,
    createAddressSuccess:null,
    updatingAddress:false,
    updateAddressError:null,
    updateAddressSuccess:null
}

const state = (state = initialState, action) => {
    switch (action.type) {
        case INIT_UPDATE_ADDRESS:
            return {
                ...state,
                updatingAddress: true,
                updateAddressError:null,
                updateAddressSuccess:null
            }
 
          
        case ADDRESS_UPDATED_SUCCESS:
            const index = state.addresses.findIndex((address) => address.id === action.payload.id)
            const updatedAddresses = state.addresses
            updatedAddresses[index] = action.payload
            return {
                ...state,
                addresses: updatedAddresses,
                updatingAddress:false,
                updateAddressError:null,
                updateAddressSuccess:true,
                addressEditorOpened:false
            }
            case ADDRESS_UPDATED_ERROR:
                return {
                    ...state,
                    updatingAddress:false,
                    updateAddressSuccess:false,
                    updateAddressError:action.payload
                }
        case INIT_DELETE_ADDRESS:
            return {
                ...state,
                deletingAddress: true,
                deleteAddressError:null,
                deleteAddressSuccess:null
            }
 
          
        case ADDRESS_DELETED_SUCCESS:
           
            return {
                ...state,
                addresses: state.addresses.filter(address => address.id !== action.payload),
                deletingAddress:false,
                deleteAddressError:null,
                deleteAddressSuccess:true
            }
            case ADDRESS_DELETED_ERROR:
                return {
                    ...state,
                    deletingAddress:false,
                    deleteAddressSuccess:false,
                    deleteAddressError:action.payload
                }
                case ADDRESS_CREATED_SUCCESS:
                    return {
                        ...state,
                        creatingAddress: false,
                        addresses: [...state.addresses, action.payload],
                        shippingAddressSelected: action.payload,
                        creatingAddressSuccess:true,
                        creatingAddressError:false,
                        addressEditorOpened:false
                    }
        case INIT_FETCH_ADDRESS:
            return {
                ...state,
                addresses: [],
                loading: true
            }

        case FETCH_ADDRESSES_SUCCESS:
            return {
                ...state,
                addresses: action.payload,
                loading: false
            }

        case INIT_CREATE_ADDRESS:
            return {
                ...state,
                creatingAddress:true,
                creatingAddressSuccess:false,
                creatingAddressError:false
            }
            case ADDRESS_CREATED_ERROR:
                return {
                    ...state,
                    creatingAddress:false,
                    createAddressSuccess:false,
                    createAddressError:action.payload
                }

        case CHANGE_ADDRESS_SELECTOR_VISIBILITY:
            return {
                ...state,
                addressSelectorOpened: action.payload
            }

        case CHANGE_CREATE_ADDRESS_VISIBILITY:
            return {
                ...state,
                addressEditorOpened: action.payload
            }

        case CHANGE_SHIPPING_ADDRESS_SELECTED:
            return {
                ...state,
                billingAddressSelected: action.payload,
                shippingAddressSelected: action.payload,
                addressSelectorOpened: false
            }
            case CHANGE_EDITING_ADDRESS:
                return {
                    ...state,
                    editingAddress: action.payload,
                }

        default:
            return state;
    }
}

export default state;

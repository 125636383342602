import React from 'react';
import { Box, Button, Card, CardActions, Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core/';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from "react-redux";

import Carousel from 'react-gallery-carousel'
import 'react-gallery-carousel/dist/index.css'
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, GetAppOutlined as DownloadIcon, ChevronRight as RightIcon } from "@material-ui/icons"

import { changeVoucherOptionsVisibilityAction } from '../../redux/actions/details/changeVoucherOptionsVisibilityAction';
import VoucherOrderOptionsDialog from "./VoucherInOrderOptionsDialog"
import { DateTime } from 'luxon'
import grey from '@material-ui/core/colors/grey';

const VoucherDetailDialog = (props) => {
    const dispatch = useDispatch();
    const voucherOptionsOpened = useSelector(state => state["detailReducer"].voucherOptionsOpened);
    const voucherUpdatedResult = useSelector(state => state["voucherReducer"].voucherUpdatedResult);
    const [onCart, setOnCart] = React.useState(true);
    const { voucher, open, onHandleClose } = props;




    const useStyles = makeStyles((theme) => ({

        paper: {
            background: "#F5F5F5",
            elevation: 1
        },
        voucherDataHeader:{
            display:"flex",
            gap:"1rem",
            alignItems:"baseline"
        },
        voucherDataTitle: {
            paddingBottom: "0.5rem"
        },
        resendButton: {
            marginTop: "1rem"
        },
        voucherActions:{
            padding:0
        },
        voucherDataCard: {
            marginLeft: "-1rem",
            textAlign: "left",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            background: grey[100]

        },
        closeButton: {
            position: "absolute",
            right: "0px",
            top: "0px"
        },
        voucherDescription: {
            overflow: "auto",
            flexGrow: 3,
            marginBottom: "1rem"
        },
        dialogTitle: {
            paddingBottom: 0,
            paddingTop: 0,
            paddingRight: 4
        },
        dialogContent: {
            display: 'flex',
            flexDirection: 'row',
            padding: "0px 16px 16px 16px",
        }

    }));

    const classes = useStyles();
    if (!voucher) return null;

    const onClickSendMail = () => {

        dispatch(changeVoucherOptionsVisibilityAction(true))
        setOnCart({ onCart: true });

    }

    const onCloseVoucherOptions = () => {
        dispatch(changeVoucherOptionsVisibilityAction(false));
        if(voucherUpdatedResult){
            onHandleClose()
        }//SI VENIMOS DE ACTUALIZAR EL CUPÓN, CERRAMOS Y PUNTO PELOTA
       
    }
    function onClickDownload() {
        var a = document.createElement("a");
        a.href = `https://staging.distributio.es:3013/vouchers/${voucher.id}/pdf`;
        a.setAttribute("download", voucher.product.name+".pdf");
        a.click();
      }

    const GetButtonForVoucher = ({ voucher }) => {
        return (
            <Card  className={classes.voucherDataCard}  >
                <div className={classes.voucherDataHeader}>
                <Typography className={classes.voucherDataTitle} variant="h6">Estado del cupón:</Typography>
                
                <span   style={voucher.getStatusStyle()}>{voucher.getStatus().toUpperCase()}</span>
                </div>
                {
                    voucher.type === "product" ?
                        <React.Fragment>
                            <Typography>{`Número de usos inicial: ${voucher.amount}`}</Typography>
                            <Typography>{`Número de usos restantes: ${voucher.getRemainingAmount()}`}</Typography>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Typography>{`Valor restante: ${voucher.getRemainingAmount().toFixed(2)}€`}</Typography>
                            <Typography>{`Valor inicial: ${voucher.amount.toFixed(2)}€`}</Typography>
                        </React.Fragment>
                }
                <Typography>{`Fecha de compra: ${DateTime.fromISO(voucher.createdAt).setLocale('es').toLocaleString(DateTime.DATE_FULL)}`}</Typography>
                <Typography>{`Válido hasta: ${DateTime.fromISO(voucher.expirationDate).setLocale('es').toLocaleString(DateTime.DATE_FULL)}`}</Typography>
                
                <CardActions className={classes.voucherActions}>
                <Button variant="contained" color="secondary" className={classes.resendButton} onClick={onClickDownload}  disabled={voucher.status !== "active"} download endIcon={<DownloadIcon />}>
                    DESCARGAR
                </Button>
                <Button variant="contained" color="secondary" className={classes.resendButton}  onClick={onClickSendMail} disabled={voucher.status !== "active"} endIcon={<RightIcon />}>
                    ENVIAR POR EMAIL
                </Button>
                </CardActions>
            </Card>
        )
    };


    //esto lo pide el componente de carrusel
    const images = voucher.product.photoUrls.map((imageURL) => ({ src: imageURL }));
     if (!voucher) return null 
    return (
        <React.Fragment>
            <Dialog
                onClose={onHandleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={"md"}
                fullWidth>
                <DialogTitle className={"dialogTitle"} classes={{ root: classes.dialogTitle }}>
                    <Box display="flex" alignItems="center" style={{ padding: 0 }}>
                        <Box flexGrow={1} style={{ padding: 0 }} >{voucher.product.families[0].name}</Box>
                        <Box>
                            <IconButton onClick={onHandleClose}><CloseIcon /></IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                  
                <DialogContent dividers className={classes.dialogContent} >
                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center", padding: 24 }}>
                        <Carousel
                            images={images}
                            canAutoPlay={false}
                            hasIndexBoard={false}
                            style={{ backgroundColor: "#FFFFFF" }}
                        />
                    </div>
                    <div style={{ width: "50%", padding: 16, display: "flex", flexDirection: "column" }}>
                        <Typography variant="h1"  >
                            {
                                voucher.product.name
                            }
                        </Typography>

                        <Typography variant="h1" className={classes.voucherDescription}>
                            {
                                <div dangerouslySetInnerHTML={{ __html: voucher.product.description }} />
                            }
                        </Typography>
                        <GetButtonForVoucher voucher={voucher} />
                    </div>
                </DialogContent>
            </Dialog>
            <VoucherOrderOptionsDialog open={voucherOptionsOpened} onHandleClose={onCloseVoucherOptions} voucher={voucher} />
        </React.Fragment>
    );
}



export default VoucherDetailDialog;

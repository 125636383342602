import React from 'react';
import {Grid, makeStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {ShoppingCart as CartIcon} from "react-feather";

const useStyles = makeStyles((theme) => ({
    
}));

const ProductCell = ({product}) => {
    const classes = useStyles();

    const onClickCartButton = () => {}

    return (
        <Grid item={true}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={product.photoUrls[1]}
                        title={product.name}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h4" component="h3">
                            {
                                product.name
                            }
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="div">
                            {
                                <div className={classes.description} dangerouslySetInnerHTML={{__html: product.description}} />
                            }
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.actions}>
                    <Typography variant={"h6"} color={"primary"} justify={"right"}>
                        100 €
                    </Typography>
                    <Button size="small" color="primary" onClick={ onClickCartButton }>
                        <CartIcon/>
                    </Button>
                </CardActions>
            </Card>
        </Grid>

    );
}

const ProductList = ({products}) => {
    const classes = useStyles();

    if (!products) return "Sin productos";

    return(
        <Grid container={true} style={{display: 'flex', justifyContent: 'center'}}>
            {
                products.map((product) => {
                  return <ProductCell className={classes.gridContainer} key={product.id} product={product} />
                })
            }
        </Grid>
    )
}

export default ProductList;

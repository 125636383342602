import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatMs, makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IconButton, Box } from '@material-ui/core/';
import { Close as CloseIcon } from "@material-ui/icons"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  body: {
    overflowY: "auto"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    background: "#F5F5F5",
    elevation: 1
  },
  formLabel: {
    paddingBottom: "1.5rem",
    color: "#555555",
    display: "flex"
  },
  sectionLabel: {
    alignSelf: "center",
    paddingLeft: "10px",
    lineHeight: "1.3"
  },
  closeButton: {
    position: "absolute",
    right: "0px",
    top: "0px"
  },
  dialogBody: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 112px)",
    paddingBottom:"20px"
  },
  dialogTitle: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: 4,
    boxShadow: "-1px -1px 12px 0 #d5d5d5"
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: "20px,24px,24px,24px",
    overflowY: "hidden",
    borderBottom: 0

  },
  
  emailTextField: {
    minWidth:"500px",
    paddingBottom:"30px"
  },
  
  dialogActions: {
    display: "flex",
    padding: "12px",
    boxShadow: "-1px -1px 12px 0 #d5d5d5"
  },

  errorMessage: {
    top: "20px",
    borderColor: "red",
    borderStyle: "dashed",
    borderWidth: "thin",
    padding: "10px",
    borderRadius: "8px",
    color: "red",
  },

  dialogButtons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
    marginBottom: "2rem",
    paddingRight: "20px"
  }
,
bigTitle:{
  padding:"24px",
  fontSize:"40px"
}
}));

const newsletterSchema = Yup.object({
  email: Yup.string().email("Debes de introducir un email válido").trim().required('Debes introducir un email válido'),
  name: Yup.string().trim().required('Debes introducir un nombre'),
  surname: Yup.string().trim().required('Debes introducir, al menos, un apellido'),
})
const mailchimpURL = "https://distributioapp.us9.list-manage.com/subscribe/post?u=ff46a0d185de23795375559ec&id=3c76104ffe";




export default function NewsletterDialog({ open, onHandleClose }) {
  const classes = useStyles();
  

  return (
    <Dialog
      open={open}
      onClose={onHandleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
    >
    {/*  <DialogTitle className={"dialogTitle"} classes={{ root: classes.dialogTitle }}>

        <Box display="flex" alignItems="center" style={{ padding: 0 }}>

          <Box flexGrow={1} style={{ padding: 0 }} ></Box>
          <Box>
            <IconButton onClick={onHandleClose}><CloseIcon /></IconButton>
          </Box>
        </Box>
      </DialogTitle>*/}
      <Typography className={classes.bigTitle} variant="h2">Boletín de noticias, novedades y eventos</Typography>
      <MailchimpSubscribe url={mailchimpURL} render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
          onHandleClose={onHandleClose}
        />
      )}>

      </MailchimpSubscribe>




    </Dialog>
  );
}

const CustomForm = ({ status, message, onValidated,onHandleClose }) => {
  const classes = useStyles();
  const ReactSwal = withReactContent(Swal)
  const messageTranslated = () => {
    if (message.includes("already")){
      return "Ya estás apuntado a esta lista"
    }
    return "Se ha producido un error"
  }


  React.useEffect(() => {
    if (status === "success") {
      //clearFields();
      ReactSwal.fire({
        icon: "success",
        text: "Te mantendremos informado",
        customClass: {
          container: "swalert"
        },
        confirmButtonColor: "#90594c",
        timer:3000,
        timerProgressBar:true,
        title: "Gracias por subscribirte",
      });
      onHandleClose()
    }
   
  }, [status])


  return (<Formik
    initialValues={{
      email: "",
      name: ""
    }
    }
    validationSchema={
      newsletterSchema
    }
    onSubmit={(values) => {
      const form = newsletterSchema.cast(values)//esto es necesario para que las transformaciones (por ejemplo, el trim) se apliquen
      onValidated({
        EMAIL: form.email,
        MERGE1: form.name,
        MEGE2: form.surname
      });
      //  onHandleSuccess()

    }}
  >
    {props => (
      <form
        onSubmit={props.handleSubmit}
      >
        <div className={classes.dialogBody}>
          <DialogContent  >
            <TextField id="email" className={classes.emailTextField} color="secondary" label="Email" variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Escriba su email"
              {...props.getFieldProps('email')}
              helperText={props.touched.email && props.errors.email}
              error={props.touched.email && props.errors.email}
            />
         
            <TextField id="name" className={classes.emailTextField} color="secondary" label="Nombre" variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Escriba su nombre"
              {...props.getFieldProps('name')}
              helperText={props.touched.name && props.errors.name}
              error={props.touched.name && props.errors.name}
            />
             <TextField id="surname" className={classes.emailTextField} color="secondary" label="Apellidos" variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Escriba su/s apellido/s"
              {...props.getFieldProps('surname')}
              helperText={props.touched.surname && props.errors.surname}
              error={props.touched.surname && props.errors.surname}
            />
          </DialogContent>
        </div>
        <DialogActions dividers className={classes.dialogActions}>
          {status === "error" ?
            <Typography variant="body1" className={classes.errorMessage}>{messageTranslated()} </Typography>
            : ""
          }
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={status === "sending"}

          >
            {status === "sending" ? "ENVIANDO..." : "SUBSCRÍBETE"}
          </Button>
        </DialogActions>
      </form>
    )}
  </Formik>)
}
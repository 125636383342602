import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import {Box,Container,Typography,makeStyles} from "@material-ui/core";
import NotificationDialog from "../../dialogs/NotificationDialog";

import {selectNotificationAction} from "../../../redux/actions/notification/selectNoticationAction";
import {Card,CardMedia} from "@material-ui/core/";
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';
import {
    Info,
  } from '@mui-treasury/components/info';

import Carousel from 'react-gallery-carousel'



const useStyles = makeStyles(() => ({
    card: {
      boxShadow: 'none',
      position: 'relative',
      minWidth: "100%",
      minHeight: "100vh",
      display:"flex",
      justifyContent:"center",
      flexDirection:"column",


      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        bottom: 0,
        zIndex: 1,
      },
    },
    content: {
      position: 'absolute',
      zIndex: 2,
      bottom: "10%",
      width: '100%',
      paddingLeft:"3rem"
    },
    contentResponsive:{
      zIndex: 2,
      position: "relative",
      width: '100%',
      textAlign:"center"
    },
    carousel:{
        backgroundColor:"#FFFFFF",
        width:"100%"
    },
    container:{
        height: "100%",
        maxWidth:"100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        padding: 0
    },
    title:{
        color: "white",
        fontSize: "2rem",
        fontWeight: "bold",
        fontFamily: "Roboto"
    },
    subtitle:{
      color: "white",
      fontSize: "1.5rem",
      fontWeight: "semiBold",
      fontFamily: "Roboto"
  },
    titleResponsive:{
      color: "white",
      fontSize: "2rem",
      fontWeight: "bold",
      fontFamily: "Roboto"
    },
    subtitleResponsive:{
      color: "white",
      fontSize: "2em",
      fontWeight: "semiBold",
      fontFamily: "Roboto"
    }



}));
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
const NotificationCell = ({notification,fullScreen}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const mediaStyles = useCoverCardMediaStyles({ bgPosition: 'top' });


    if (!notification) {
        return "NO SE PUEDE CARGAR LA NOTIFICACIÓN";
    }

    const onClickNotification = () => {
        switch(notification.action){
            case "web": openInNewTab(notification.actionable);break;
            case "product": break;
            case "family":break;
            case "":break;
            default: /*dispatch(selectNotificationAction(notification))*/;
        }

    }

    return (
            <Card className={classes.card} onClick={onClickNotification}>
            <CardMedia
              classes={mediaStyles}
              image={
                notification.photoUrl
              }
            />
            <Box py={3} px={2} className={fullScreen ? classes.contentResponsive:classes.content}>
              <Info styles={{paddingBottom:"5rem"}}>
                <Typography className={fullScreen ? classes.subtitleResponsive : classes.subtitle}>{notification.description}</Typography>
                <Typography className={fullScreen ? classes.titleResponsive : classes.title}>{notification.title}</Typography>
                {/**
                <InfoCaption>{notification.description}</InfoCaption>*/}
              </Info>
            </Box>
          </Card>

    );
}

const NotificationsCarouselFull = ({notifications,fullScreen}) => {
    const dispatch = useDispatch();
    const styles = useStyles();
    const notificationSelected = useSelector(state => state.notificationReducer.notificationSelected);

    const onHandleNotificationDialogClose = () => {
        dispatch(selectNotificationAction(null));
    }

    if (!notifications) {
        return "";
    }

    return (
        <Container className={styles.container}>
        <Carousel
            children={
                notifications.map(notification => {
                   return <NotificationCell key={notification.id} notification={notification} fullScreen={fullScreen}/>
                })
            }
            isAutoPlaying={true}
            hasIndexBoard={false}
            hasThumbnails={false}

            autoPlayInterval={8000}//en miliseconds
            className={styles.carousel}
            maxIcon={""}
            pauseIcon={""}
            playIcon={""}
            hasDotButtons={'bottom'}
            hasRightButton={false}
            hasLeftButton={false}
        />
         {
                <NotificationDialog open={notificationSelected !== null} notification={notificationSelected} handleClose={onHandleNotificationDialogClose} />
            }

        </Container>
    );
}

export default NotificationsCarouselFull;

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { Box, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import HomeIcon from '@material-ui/icons/Home';
import RedeemIcon from '@material-ui/icons/Redeem';
import Receipt from '@material-ui/icons/Receipt';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Button, Drawer, IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { changeAddressSelectorVisibilityAction } from "../../../redux/actions/address/changeAddressSelectorVisibilityAction";
import { changeCreditCardSelectorVisibilityAction } from "../../../redux/actions/credit_card/changeCreditCardSelectorVisibilityAction";
import { HistoryOutlined as HistoryIcon, ChevronRight as RightIcon } from "@material-ui/icons";
import DrawerHeader from "../cart/DrawerHeader"
import { changeOrdersHistoryVisibilityAction } from "../../../redux/actions/order/changeOrdersHistoryVisibilityAction";
import { changeVouchersVisibilityAction } from "../../../redux/actions/voucher/changeVouchersVisibilityAction";
import { logoutUserAction } from "../../../redux/actions/auth/logoutUserAction";
import ClientDataDrawer from "./ClientDataDrawer"
import { loadClientAction } from "../../../redux/actions/client/loadClientAction"

const useStyles = makeStyles((theme) => ({
    container: {
        width: '400px',
        backgroundColor: theme.palette.background.paper,
    },
    topbar: {
        boxShadow: "0 8px 24px 0 rgba(37, 36, 47, .06), inset 0 -1px 0 0 #e5e5e5",
        display: "flex",
        height: 56,
        paddingLeft: 5,

        backgroundColor: "white"
    },
    paper: {
        background: "#F5F5F5",
    },
    title: {
        flexGrow: 100,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column"
    },
    emptyCart: {
        flexGrow: 50,
        padding: 20,
        maxWidth: 400,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column"
    },
    backbutton: {
        padding: '1rem',
        position: "absolute",
        top: 0,
        left: 0,
        margin: 0
    },
    footer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        padding: '1rem',
        boxShadow: "0 -1px 8px 0 rgba(0,0,0,.1)"
    },
    sectionDivider: {
        height: "10px"
    },
    bottomItem: {

    },
    closeButton: {
        backgroundColor: "#ff373d",
        color: "white"
    }
}));

const AccountDrawer = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();





    const { onHandleClose, open } = props;

    React.useEffect(() => {
        if (open) {
            dispatch(loadClientAction())
        }
    }, [open])

    const [editingClient, setEditingClient] = React.useState(false)

    const client = useSelector(state => state["clientReducer"].client);
    const details = useSelector(state => state["cartReducer"].detailsInCart);

    if (!details) return null;
    const amount = details.reduce((a, b) => a + (b.getPrice() || 0), 0);

    const onClickSelectShippingAddress = () => {
        dispatch(changeAddressSelectorVisibilityAction(true));
    }

    const onClickSelectCreditCard = () => {
        dispatch(changeCreditCardSelectorVisibilityAction(true));
    }
    const onClickSelectOrderHistory = () => {
        dispatch(changeOrdersHistoryVisibilityAction(true));
    }
    const onClickCloseSession = () => {
        dispatch(logoutUserAction());
        onHandleClose();
    }
    const onClickVouchersHistory = () => {
        dispatch(changeVouchersVisibilityAction(true));
    }
    const onClickUserData = () => {
        setEditingClient(true)
    }
    const handleCloseEditingClient = () => {
        setEditingClient(false)
    }

    const canSelectPaymentMethods = () => {
        if ((client && client.paymentMethod && (client.paymentMethod.creditCardRequired == false))) return false
        return true
    }


    return (
        <React.Fragment >
            <Drawer anchor={"right"} open={open} onClose={onHandleClose} >
                <DrawerHeader title="Tu cuenta" onHandleClose={onHandleClose} />
                <div className={classes.container}>
                    <List component="nav" aria-label="main mailbox folders">
                        <ListItem button onClick={onClickSelectShippingAddress}>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            {/*shippingAddressSelected ?
                            <ListItemText primary={"Dirección de Envío"} secondary={shippingAddressSelected.street} />
                            :
                            <ListItemText primary={"Dirección de Envío"} secondary={shippingAddressSelected.description} />
                            */}
                            <ListItemText primary={"Tus direcciones"} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <RightIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        {canSelectPaymentMethods() ? <>
                            <ListItem button onClick={onClickSelectCreditCard}>
                                <ListItemIcon>
                                    <CreditCardIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Datos de pago"} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="comments">
                                        <RightIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                        </> : ""}
                        <ListItem button onClick={onClickSelectOrderHistory}>
                            <ListItemIcon>
                                <HistoryIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Historial de pedidos"} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <RightIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        {process.env.REACT_APP_VOUCHERS_DISABLED ? "" :
                            <><ListItem button onClick={onClickVouchersHistory}>
                                <ListItemIcon>
                                    <RedeemIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Mis Bonos"} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="comments">
                                        <RightIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                                <Divider /></>
                        }
                        <ListItem button onClick={onClickUserData}>
                            <ListItemIcon>
                                <Receipt />
                            </ListItemIcon>
                            <ListItemText primary={"Datos de facturación"} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <RightIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />

                    </List>

                    <Box className={classes.footer}>
                        <Button variant={"contained"} type="submit" className={classes.closeButton} fullWidth onClick={onClickCloseSession}>
                            Cerrar sesión
                    </Button>
                    </Box>

                </div>
            </Drawer>
            <ClientDataDrawer open={editingClient} onHandleClose={handleCloseEditingClient}></ClientDataDrawer>
        </React.Fragment>
    );
}

export default AccountDrawer;

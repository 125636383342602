import {ADD_DETAIL_TO_CART} from "../../types/cartTypes";

export function incDetailInCartAction(detail) {
    detail.addQuantity(1)
    console.log(detail)
    return (dispatch) => {
        dispatch(addDetailToCart(detail))
    }
}

const addDetailToCart = detail => ({
    type:  ADD_DETAIL_TO_CART,
    payload: detail
});

import React from 'react';
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router";
import {selectCategoryAction} from "../../../redux/actions/category/selectCategoryAction";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        background: "black"
    },
    root: {
        maxWidth: 360,
        width:360,
        margin: 15
    },
    media: {
        height: 360,
    },
    
    content: {
        display: "flex",
  justifyContent: "center",
  alignItems: "center",
        position: 'absolute',
        zIndex: 2,
        left: 0,
    right: 0,
    top:0,
    bottom:0,
    margin: "auto",
    position: "absolute", 
    width: "80%",
    textAlign:"center",
    height:"100%",
    lineHeight:"100%"
  
      },
    description: {
        listStylePosition: "inside",
        overflow: "hidden"
    },
    actions: {
        display: "flex",
       // justifyContent: "space-between",
        zIndex: 1,
        minHeight: 50,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 15
    },
    card: {
        boxShadow: 'none',
        position: 'relative',
        maxWidth: 360,
        width:360,
        margin: 15,
        
        
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%',
          bottom: 0,
          zIndex: 1,
          background: 'linear-gradient(to top, #000, rgba(0,0,0,0))',
        },
      },
      title:{
        color: "white",
        fontSize: "1.5rem",
        height:"360",
        fontFamily:"Roboto"
        
    },
    
}));

const CategoryCell = ({ category }) => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [onCart, setOnCart] = React.useState(true);



   

    const onClickCategory = () => {
        dispatch(selectCategoryAction(category));
        history.push(`/categories/${category.id}/details`);
    }

   
    return (
        <Grid item={true} styles={{flexGrow:5}} >
            <Card className={classes.card} onClick={onClickCategory}>
                
                    <CardMedia
                        className={classes.media}
                        image={category.photoUrl}
                        title={category.name}
                    />
                     <Box py={3} px={2} className={classes.content}>
              
                <Typography className={classes.subtitle}>{category.description}</Typography>
                <Typography className={classes.title}>{category.name}</Typography>
             
            </Box>
                
            </Card>
        </Grid>

    );
}

const CategoryCardList = ({ categories }) => {
    const classes = useStyles();

    if (!categories) return "Categorías no encontradas";

    return (

        <Grid container style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {
                categories.map((category) => {
                    return <CategoryCell key={category.id} category={category} />
                })
            }
        </Grid>

    )
}

export default CategoryCardList;
import React, {useEffect} from 'react';
import SelectPaymentMethodList from "./SelectPaymentMethodList";
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, Drawer} from "@material-ui/core";
import {fetchPaymentMethodsAction} from "../../../redux/actions/payment_method/fetchPaymentMethodsAction";
import {drawerStyles} from '../cart/CartDrawer'
import DrawerHeader from '../cart/DrawerHeader'

const SelectPaymentMethodDrawer = (props) => {
    const dispatch = useDispatch();
    const classes = drawerStyles();

    const { onHandleClose, open } = props

    const paymentMethodSelected = useSelector(state => state["paymentMethodReducer"].paymentMethodSelected);
    const paymentMethods = useSelector(state => state["paymentMethodReducer"].paymentMethods);
    const loadingPaymentMethods = useSelector(state => state["paymentMethodReducer"].loading);

    useEffect(() => {
        if (open) initPaymentMethods();
        // eslint-disable-next-line
    }, [open]);

    const initPaymentMethods = () => {
        dispatch(fetchPaymentMethodsAction());
    }

    return (
          <Drawer anchor={"right"} open={open} onClose={onHandleClose} classes={{paper: classes.paper}}>
          <DrawerHeader title="Elige un método de pago" onHandleClose={onHandleClose}/>
             {loadingPaymentMethods ?  <CircularProgress/> : 
             <SelectPaymentMethodList paymentMethods={paymentMethods} paymentMethodSelected={paymentMethodSelected} />}
        </Drawer>
    );
}



export default SelectPaymentMethodDrawer;

class Detail {


    constructor(amountModeSelected, clientNote, commercialNote, estimatedAmount, finalAmount, quantity, price) {
        this.amountModeSelected = amountModeSelected;
        this.clientNote = clientNote;
        this.commercialNote = commercialNote;
        this.estimatedAmount = estimatedAmount ? estimatedAmount:1;
        this.finalAmount = finalAmount;
        this.quantity = quantity;
        this.price = price;

    }


    static createFromPrice(price) {
        return new Detail(
            'units',
            undefined,
            undefined,
            0,
            undefined,
            0,
            price
        );
    }
    static getPrice(detail) {

        return detail.quantity * Detail.getUnitaryPrice(detail)
    }
    static getUnitaryPrice(detail, withTaxes = true) {
        let price = detail.price.value ;

        if (detail.optionsSelected) {
            detail.optionsSelected.forEach(option => {
                price += option.value * option.quantity;
            });
        }
        if (withTaxes) {
            price += Detail.getTaxes(detail);
        }

        return price;
    }
    static getAmount(detail) {
        return detail.finalAmount ? detail.finalAmount : detail.estimatedAmount;
    }

    static getTaxes(detail) {
        let detailTaxes =  detail.price.value * (detail.price.product.tax.value / 100);
        if (detail.optionsSelected) {
            detail.optionsSelected.forEach(option => {
                detailTaxes += option.value * option.quantity * (detail.price.product.tax.value / 100)
            });
        }
        return detailTaxes;
    }

    static  getMainPhoto(detail) {
        if (detail.price.product.photoUrls.length > 0) return detail.price.product.photoUrls[0];
        return "";
    }


    getAmount() {
        return this.finalAmount ? this.finalAmount : this.estimatedAmount;
    }

    getUnitTaxes() {
        let detailTaxes = this.getAmount() * this.price.value * (this.price.product.tax.value / 100);


        return detailTaxes;
    }
    getOptionTaxes(){
        let optionTaxes = 0;
        if (this.price.product.options) {
            this.price.product.options.forEach(option => {
                if (option.quantity){
                    optionTaxes += option.value * option.quantity * (this.price.product.tax.value / 100)
                }
            });
        }
        return optionTaxes;
    }

    getPrice() {
        return (this.quantity * this.getPricePerUnit())+this.getOptionsPrice()
    }
    getUnitaryPrice(withTaxes = true){
        let price = this.price.value * this.getAmount();
        if (withTaxes) {
            price += this.getAmount() * this.price.value * (this.price.product.tax.value / 100);
        }
        return price;
    }
    getOptionsPrice(withTaxes = true){
        let optionsPrice = 0;

        if (this.price.product.options) {
            this.price.product.options.forEach(option => {
                if (option.quantity){
                    optionsPrice += option.value * option.quantity;
                }
            });
        }
        if (withTaxes) {
            optionsPrice += this.getOptionTaxes();
        }

        return optionsPrice
    }

    getPricePerUnit(withTaxes = true) {
        let price = this.price.value * this.getAmount();

        if (withTaxes) {
            price += this.getUnitTaxes();
        }

        return price;
    }

    addQuantity(quantity = 1) {
        if (this.amountModeSelected === 'units') {
            this.quantity += quantity;
            //this.estimatedAmount = this.quantity * this.price.product.amount;
        } else {
            this.quantity = 1;
            this.estimatedAmount = quantity;
        }
    }

    getMainPhoto() {
        if (this.price.product.photoUrls.length > 0) return this.price.product.photoUrls[0];
        return "";
    }
}

export default Detail;

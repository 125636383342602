import {FETCH_CREDIT_CARDS_SUCCESS, INIT_FETCH_CREDIT_CARD} from "../../types/creditCardTypes";
import {fetchCreditCardsByClientIdAsync} from "../../../services/creditCardServices";

export function fetchCreditCardsAction(clientId = localStorage.getItem("clientId")) {
    return async (dispatch) => {
        dispatch(initFetch())

        const creditCards = await fetchCreditCardsByClientIdAsync(clientId);
        if (creditCards instanceof Error) {
            console.log("Ver error", creditCards);
        } else {
            dispatch(loadCreditCards(creditCards));
        }
    }
}

const initFetch = () => ({
    type: INIT_FETCH_CREDIT_CARD
});

const loadCreditCards = creditCards => ({
    type: FETCH_CREDIT_CARDS_SUCCESS,
    payload: creditCards
});

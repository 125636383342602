import {useEffect} from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getProductsByCategoryAction} from "../../redux/actions/product/getProductsByCategoryAction";
import {CircularProgress, Container} from "@material-ui/core";
import ProductList from "../components/product/ProductList";

const ProductsPage = () => {
    const dispatch = useDispatch();
    const { category_id } = useParams();

    const category = useSelector(state => state.categoryReducer.categorySelected);
    const products = useSelector(state => state.productReducer.products);
    const isLoading = useSelector(state => state.productReducer.loading);

    const getProductsByCategoryId = () => {
        dispatch(getProductsByCategoryAction(category_id));
    }

    useEffect(() => {
        if (category_id) getProductsByCategoryId(category_id);

        // eslint-disable-next-line
    }, [category_id]);

    if (!category) return null;

    return (
        <Container>
            {
                isLoading
                    ? <CircularProgress />
                    : <div>
                        <p>Productos de { category.name } </p>
                        <ProductList products={products} />
                    </div>
            }
        </Container>
    )
}

export default ProductsPage;

import React, {useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch, useSelector } from "react-redux";
import { createAddressAction } from "../../../redux/actions/address/createAddressAction";
import { updateAddressAction } from "../../../redux/actions/address/updateAddressAction";
import { drawerStyles } from '../cart/CartDrawer'
import DrawerHeader from '../cart/DrawerHeader'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles';
import { changeEditingAddressAction } from "../../../redux/actions/address/changeEditingAddressAction";
import { deleteAddressAction } from "../../../redux/actions/address/deleteAddressAction";
import {Formik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const styles = makeStyles((theme) => ({
    paper: {
        background: "#FFFFFF",
        maxWidth: 400
    },
    actionBar: {
        marginTop: 20
    },  
    closeButton:{
        backgroundColor:"#ff373d",
        color:"white"
    }

}));

const addressSchema = Yup.object({
    name: Yup.string().min(3, "Mínimo 3 carácteres")
        .required('Debes introducir un nombre'),
    street: Yup.string()
        .min(5, 'Mínimo 5 carácteres')
        .required('Debes de introducir la calle, número y piso'),
    city: Yup.string()
        .required('Debes de introducir el nombre de la ciudad'),
    postalCode: Yup.string().trim().matches(/^[0-9]+$/, "Código postal incorrecto")
    .length(5, "Código postal incorrecto")
    .required("Debes proporcionar un código postal"),
})

const EditAddressDrawer = ({ open, editingAddress,isBilling}) => {
    const dispatch = useDispatch();
    const classes = drawerStyles();
    const myClasses = styles();
    const address = useSelector(state => state["addressReducer"].editingAddress);
    const deletingAddress = useSelector(state => state["addressReducer"].deletingAddress);
    const deleteAddressSuccess = useSelector(state => state["addressReducer"].deleteAddressSuccess);
    const deleteAddressError = useSelector(state => state["addressReducer"].deleteAddressError);
    const createAddressError = useSelector(state => state["addressReducer"].createAddressError);
    const creatingAddress = useSelector(state => state["addressReducer"].creatingAddress);
    const updateAddressError = useSelector(state => state["addressReducer"].updateAddressError);
    const updateAddressSuccess = useSelector(state => state["addressReducer"].updateAddressSuccess);
    const updatingAddress = useSelector(state => state["addressReducer"].updatingAddress);



    const ReactSwal = withReactContent(Swal)
  

useEffect(() => {
    if (deleteAddressSuccess){     
    ReactSwal.fire({
        customClass:{
            container:"swalert"
        },
        title:"Dirección Borrada",
        timer:"1500",
        timerProgressBar:true,
        icon:"success",
        showConfirmButton:false
        });
        onHandleClose()
    }
   
}, [deleteAddressSuccess])
useEffect(() => {
    if (deleteAddressError){
        ReactSwal.fire({
            customClass:{
                container:"swalert"
            },
            timer:2000,
            confirmButtonColor:"#90594c",
            timerProgressBar:true,
            title:deleteAddressError,
            icon:"error",
            });
    }
}, [deleteAddressError])
useEffect(() => {
    if (createAddressError){
        ReactSwal.fire({
            customClass:{
                container:"swalert"
            },
            timer:2000,
            confirmButtonColor:"#90594c",
            timerProgressBar:true,
            title:createAddressError,
            icon:"error",
            });
    }
}, [createAddressError])
useEffect(() => {
    if (updateAddressError){
        ReactSwal.fire({
            customClass:{
                container:"swalert"
            },
            timer:2000,
            confirmButtonColor:"#90594c",
            timerProgressBar:true,
            title:updateAddressError,
            icon:"error",
            });
    }
}, [updateAddressError])
useEffect(() => {
    if (updateAddressSuccess){
       onHandleClose()
    }
}, [updateAddressSuccess])


    const onClickDeleteAddress = event => {
        event.preventDefault();
        ReactSwal.fire({
            customClass:{
                container:"swalert",
                confirmButton:"okButton"
            },
            title: '¿Seguro que quieres borrar la dirección?',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Quiero borrarla',
            confirmButtonColor:"#ff373d",
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteAddressAction(address.id));
            }
          })
        
    }
    const onHandleClose = event => {
        dispatch(changeEditingAddressAction(null));
    }
    //  if (!form) return null
    return (
        <Drawer anchor={"right"} open={open} onClose={onHandleClose} classes={{ paper: myClasses.paper }} width={1 / 3}>
            <DrawerHeader title="Editar dirección" onHandleClose={onHandleClose} />
            <Formik
                initialValues={{
                    name: address ? address.name : "",
                    street: address ? address.street : "",
                    city: address ? address.city : "",
                    postalCode: address ? address.postalCode : ""
                }}
                validationSchema={addressSchema}
                onSubmit={(values) => {
                    const addressForm = addressSchema.cast(values)//esto es necesario para que las transformaciones (por ejemplo, el trim) se apliquen
                    if (!address){
                        dispatch(createAddressAction(addressForm));
                    }else{
                        addressForm.addressId = address.id;
                        dispatch(updateAddressAction(addressForm));
                    }
                }}>
                {props => (
                    <form onSubmit={props.handleSubmit}>
                        <DialogContent style={{ maxWidth: 400 }}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                name="name"
                                label="Nombre del destinatario"
                                type="text"
                                color="secondary"
                                variant="outlined"
                                fullWidth
                                {...props.getFieldProps('name')}
                                helperText={props.touched.name && props.errors.name}
                                error={props.touched.name && props.errors.name}
                            />
                            <TextField
                                margin="dense"
                                id="street"
                                name="street"
                                label="Calle y Número"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('street')}
                                helperText={props.touched.street && props.errors.street}
                                error={props.touched.street && props.errors.street}
                            />
                            <TextField
                                margin="dense"
                                id="city"
                                name="city"
                                label="Ciudad"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('city')}
                                helperText={props.touched.city && props.errors.city}
                                error={props.touched.city && props.errors.city}
                            />
                            <TextField
                                margin="dense"
                                id="postalCode"
                                name="postalCode"
                                label="Código Postal"
                                type="text"
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                {...props.getFieldProps('postalCode')}
                                helperText={props.touched.postalCode && props.errors.postalCode}
                                error={props.touched.postalCode && props.errors.postalCode}
                            />
                            <div className={myClasses.actionBar}>
                                <Button fullWidth variant="contained" disable={creatingAddress || updatingAddress} className={classes.acceptButton} type="submit" >
                                    {(creatingAddress || updatingAddress) ? "GUARDANDO...":"GUARDAR"}
                    </Button>
                                <Button fullWidth variant="contained" disabled={deletingAddress} className={classes.deleteButton} onClick={onClickDeleteAddress} >
                                    {deletingAddress ? "ELIMINANDO...":"ELIMINAR"}
                    </Button>
                            </div>
                        </DialogContent>
                    </form>
                )}
            </Formik>
        </Drawer>
    );
}



export default EditAddressDrawer;

import {ADDRESS_UPDATED_ERROR,ADDRESS_UPDATED_SUCCESS, INIT_UPDATE_ADDRESS} from "../../types/addressTypes";
import {updateAddressByClientAsync} from "../../../services/addressServices";

export function updateAddressAction({addressId,city, name, postalCode, street}) {
    return async (dispatch) => {
        dispatch(initUpdate());
        const clientId = localStorage.getItem("clientId")
        const updatedAddress = await updateAddressByClientAsync({addressId, city, clientId, name, postalCode, street});
        
        if (updatedAddress instanceof Error) {
            dispatch(addressUpdatedError("Error actualizando la dirección"));
        } else {
            dispatch(addressUpdated(updatedAddress));
        }
    }
}

const initUpdate = () => ({
    type: INIT_UPDATE_ADDRESS
});

const addressUpdated = address => ({
    type: ADDRESS_UPDATED_SUCCESS,
    payload: address
});

const addressUpdatedError = error => ({
    type: ADDRESS_UPDATED_ERROR,
    payload: error
});
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./redux/store";
import {createStyles, makeStyles} from "@material-ui/core";
import PagesContainer from "./views/pages/PagesContainer";
import {ThemeProvider} from "@material-ui/styles";
import theme from './views/ui/Theme.js'
import CssBaseline from '@material-ui/core/CssBaseline'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


const mainStyles = makeStyles((theme) => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            height: '100%',
            width: '100%'
        },
        '#root': {
            height: '100%',
            width: '100%'
        }
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {

            display: 'flex',
            height: '100%',
            overflow: 'hidden',
            width: '100%'
    },
    wrapper: {
        display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
            paddingTop: 64
    },
    contentContainer: {
        display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
            height: '100%',
            overflow: 'auto'
    },

}));

function App() {

    // TODO Esta mierda es provisional. Cuando alguien haga login hay que cambiarlo.
    localStorage.setItem('partnerId', process.env.REACT_APP_PARTNER_ID); //MUNDO GUANTE

    const classes = useStyles();

    mainStyles();

  return (
      <BrowserRouter>
          <Provider store={store}>
              <ThemeProvider theme={theme}>
              <CssBaseline />
              <div className={classes.root}>

                  <div className={classes.wrapper}>
                      <div className={classes.contentContainer}>
                          <PagesContainer />
                      </div>
                  </div>
              </div>
              </ThemeProvider>
          </Provider>

      </BrowserRouter>
  );
}
export default App;

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import HomeIcon from '@material-ui/icons/Home';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Drawer, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Today as DateSelectedIcon, Info as StatusIcon } from "@material-ui/icons";
import DrawerHeader from "../cart/DrawerHeader"
import { DateTime } from 'luxon'
import Detail from "../../../models/Detail"
import DetailsInOrderList from './DetailsInOrderList';
import { drawerStyles } from '../cart/CartDrawer';


const useStyles = makeStyles((theme) => ({
    statusTypography: {
        textAlign: "center"
    },
    orderStatus: {
        width: '100%',
        height: "50px",
        margin: 'auto',
        backgroundColor: "red"

    },
    container: {
        width: '400px',
        backgroundColor: theme.palette.background.paper,
        overflowY: 'scroll',
        paddingRight: "0px"
    },
    topbar: {
        boxShadow: "0 8px 24px 0 rgba(37, 36, 47, .06), inset 0 -1px 0 0 #e5e5e5",
        display: "flex",
        height: 56,
        paddingLeft: 5,

        backgroundColor: "white"
    }, paper: {
        background: "#F5F5F5",
    },

    topbar: {
        boxShadow: "0 8px 24px 0 rgba(37, 36, 47, .06), inset 0 -1px 0 0 #e5e5e5",
        display: "flex",
        height: 56,
        paddingLeft: 5,

        backgroundColor: "white"
    },
    title: {
        flexGrow: 100,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column"
    },
    emptyCart: {
        flexGrow: 50,
        padding: 20,
        maxWidth: 400,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column"
    },
    backbutton: {
        padding: '1rem',
        position: "absolute",
        top: 0,
        left: 0,
        margin: 0
    },
    footer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        padding: '1rem',
        boxShadow: "0 -1px 8px 0 rgba(0,0,0,.1)"
    },
    sectionDivider: {
        height: "10px"
    },
    bottomItem: {

    }
}));



const OrderDetail = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { onHandleClose, order } = props;


    const onClickCloseButton = () => {
        onHandleClose()
    }

    useEffect(() => {

    }, [order]);

    if (!order) return null;

    const shippingMethodValueDescription = () => {
        let cartValue = order.details.reduce((a, b) => a + (Detail.getPrice(b) || 0), 0);
        if (cartValue > order.shippingMethod.freeFrom) {
            return `Gratis`
        }
        return `${order.shippingMethod.value.toFixed(2)} €`;
    }
    const shippingMethodActualValue = () => {

        let cartValue = order.details.reduce((a, b) => a + (Detail.getPrice(b) || 0), 0);
        if (cartValue > order.shippingMethod.freeFrom) {
            return 0
        }
        return order.shippingMethod.value;
    }
    return (
        <React.Fragment >
            <Drawer classes={{
                paper: drawerStyles.paper
            }} anchor={"right"} open={order} onClose={onHandleClose} >
                <DrawerHeader title="Pedido" onHandleClose={onHandleClose} />
                <div className={classes.container}>
                    <List component="nav" aria-label="main mailbox folders" >
                        <ListItem>
                            <ListItemIcon>
                                <StatusIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Estado del pedido"} secondary={order.status.toUpperCase()} />
                        </ListItem>
                        <Divider className={classes.sectionDivider} />
                        <ListItem button>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Dirección de Envío"} secondary={order.shippingAddress.street} />
                        </ListItem>
                        <Divider />
                        <ListItem button>
                            <ListItemIcon>
                                <LocalShippingIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Método de envío"} secondary={order.shippingMethod.name} />
                        </ListItem>
                        <Divider />
                        <ListItem button >
                            <ListItemIcon>
                                <DateSelectedIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Fecha de pedido"} secondary={DateTime.fromISO(order.createdAt).setLocale('es').toLocaleString(DateTime.DATE_FULL)} />
                        </ListItem>
                        <Divider />
                        <ListItem button >
                            <ListItemIcon>
                                <DateSelectedIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Fecha de entrega"} secondary={DateTime.fromISO(order.estimatedDeliveryDate).setLocale('es').toLocaleString(DateTime.DATE_FULL)} />
                        </ListItem>
                        <Divider />
                        <ListItem button>
                            <ListItemIcon>
                                <CreditCardIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Método de pago"} secondary={order.paymentMethod.name} />
                        </ListItem>
                        <Divider />
                        <Divider className={classes.sectionDivider} />
                        <DetailsInOrderList details={order.details}></DetailsInOrderList>
                        <Divider className={classes.sectionDivider} />
                        <ListItem className={classes.bottomItem} style={{ paddingBottom: 0 }}>

                            <ListItemText primary="Compra " secondary={order.details.length + ((order.details.length > 1) ? " productos" : " producto")} />
                            <ListItemSecondaryAction>
                                {order.finalPrice.toFixed(2)} €
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem className={classes.bottomItem} style={{ paddingTop: 0 }}>

                            <ListItemText primary="Gastos de envío" secondary={order.shippingMethod.name} />
                            <ListItemSecondaryAction>
                                {shippingMethodValueDescription()}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary="Total" primaryTypographyProps={{ variant: "h6" }} />
                            <ListItemSecondaryAction>
                                <Typography variant="h6">{(order.finalPrice + shippingMethodActualValue()).toFixed(2)} €</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>



                </div>
            </Drawer>

        </React.Fragment>
    );
}

export default OrderDetail;

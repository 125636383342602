import { IconButton, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { changeOrderSelectedAction } from "../../../redux/actions/order/changeOrderSelectedAction";
import { useDispatch} from "react-redux";
//import { changeOrderHistoryVisibilityAction } from "../../../redux/actions/order/changeOrderHistoryVisibilityAction";
//import { changeOrderDetailVisibilityAction } from "../../../redux/actions/order/changeOrderDetailVisibilityAction";
import { LocalMallOutlined as OrderIcon, ChevronRight as RightIcon } from "@material-ui/icons"
import {DateTime} from 'luxon'

const useStyles = makeStyles((theme) => ({
    container: {
        margin: 0,
        width: 400,
        overflowY:'scroll'
    },
    orderItem: {
        paddingLeft: 15,
        paddingRight: 0,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        borderBottom: "1px solid #f5f5f5"

    },
}));



const OrderCell = ({ order}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    if (!order) return null;

    const onClickOrder = () => {
        dispatch(changeOrderSelectedAction(order))
    }

    return (
        <ListItem alignItems="flex-start" className={classes.orderItem} key={order.id} onClick={onClickOrder}>
            <ListItemIcon>
                <OrderIcon/>
            </ListItemIcon>
            <ListItemText
                primary={DateTime.fromISO(order.createdAt).setLocale('es').toLocaleString(DateTime.DATE_FULL)}
                secondary={
                    "Importe total: "+(order.finalPrice + order.taxes).toFixed(2) + "€"
                }
            />
            <ListItemIcon>
                <IconButton edge="end" aria-label="comments">
                    <RightIcon />
                </IconButton>
            </ListItemIcon>
        </ListItem>
    )
}

const OrdersList = ({ orders, orderSelected }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    if (!orders) orders = [];
   /* if (orders.length > 5){
       orders = orders.slice(0, 5)
    } */

   

    return (
        <div className={classes.container}>
            <List >
                {
                    orders.map(order => <OrderCell order={order} orderSelected={orderSelected} />)
                }
            </List>

        </div>

    )

}

export default OrdersList;
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Container,Typography} from "@material-ui/core";
import {useHistory} from "react-router";
import {selectCategoryAction} from "../../../redux/actions/category/selectCategoryAction";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const CategoryList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const categories = useSelector(state => state["categoryReducer"].categories);

    const handleChange = (event, newValue) => {

            const category = categories[newValue];

            if (!category) return
            dispatch(selectCategoryAction(category));
           history.push(`/categories/${category.id}/details`);
           setValue(newValue);

            // eslint-disable-next-line
        }


    if (!categories) {
        return "NO HAY CATEGORÍAS"
    }

    return (
        <Container style={{maxWidth:"2000px"}}>
             <Tabs
         // value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="white"
          centered
          selectedTab={value}
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
            {
                categories.map((category, index) => {
                    if (index < 5) {
                        return <Tab  label={<Typography style={{fontFamily:"Roboto",fontSize:"0.9rem", color:"white"}}>{category.name}</Typography>} key={category.id} category={category} />
                    } else {
                        return <></>
                    }
                })
            }

        </Tabs>

        </Container>
    )
}

export default CategoryList;

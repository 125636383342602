import { IconButton, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { changeCreditCardSelectedAction } from "../../../redux/actions/credit_card/changeCreditCardSelectedAction";
import { useDispatch, useSelector } from "react-redux";
import { changeCreditCardSelectorVisibilityAction } from "../../../redux/actions/credit_card/changeCreditCardSelectorVisibilityAction";
import { changeCreditCardEditorVisibilityAction } from "../../../redux/actions/credit_card/changeCreditCardEditorVisibilityAction";
//import { deleteCreditCardAction } from "../../../redux/actions/credit_card/deleteCreditCardAction";
import { Add as AddIcon, CreditCard as CreditCardIcon, DeleteOutlined as DeleteIcon, Check as SelectedIcon} from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
    container: {
        margin: 0,
        width: 400,
        overflow: "auto"
    },
    creditCardItem: {
        paddingLeft: 15,
        paddingRight: 0,
        alignItems: "center",
        justifyItems: "center",
        background: "white",
        borderBottom: "1px solid #f5f5f5"

    },
}));



const CreditCardCell = ({ creditCard, creditCardSelected }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const accountDrawerOpened = useSelector(state => state["accountReducer"].accountDrawerOpened);

    if (!creditCard) return null;

    const onClickCreditCard = () => {
        accountDrawerOpened ?
        dispatch(changeCreditCardSelectedAction(creditCard))
        //dispatch(deleteCreditCardAction(creditCard))
        :
        dispatch(changeCreditCardSelectedAction(creditCard))
    }

    return (
        <ListItem alignItems="flex-start" className={classes.creditCardItem} key={creditCard.id} onClick={onClickCreditCard}>
            <ListItemIcon>
                <CreditCardIcon />
            </ListItemIcon>
            <ListItemText
                primary={creditCard.brand.toUpperCase() + " ****" + creditCard.lastCardNumbers}
                secondary={
                    <div>
                         <div>{"Válida hasta: "+creditCard.expMonth+"/"+creditCard.expYear}</div>
                <div>{creditCard.ownerName.toUpperCase()}</div>
               
              </div>
                }
                styles={{textAlign:"center"}}
            />

            {
            accountDrawerOpened ?
            <ListItemIcon>
                    <IconButton edge="end" aria-label="comments">
                        <DeleteIcon />
                    </IconButton>
                </ListItemIcon>
            :
            ((creditCardSelected && creditCardSelected.id === creditCard.id) ?
                <ListItemIcon>
                    <IconButton edge="end" aria-label="comments">
                        <SelectedIcon />
                    </IconButton>
                </ListItemIcon> : "")
            }
        </ListItem>
    )
}

const CreditCardsList = ({ creditCards, creditCardSelected }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const accountDrawerOpened = useSelector(state => state["accountReducer"].accountDrawerOpened);
    if (!creditCards) creditCards = [];

    const onClickCreateNewCreditCard = () => {
        if (!accountDrawerOpened){
            dispatch(changeCreditCardSelectorVisibilityAction(false));
        }
        dispatch(changeCreditCardEditorVisibilityAction(true));
    }
   
    return (
        <div className={classes.container}>
            <List >
                {
                    
                    creditCards.map(creditCard => <CreditCardCell creditCard={creditCard} creditCardSelected={creditCardSelected} />)
                }

                <ListItem key={"listItem"} onClick={onClickCreateNewCreditCard}>
                    <ListItemIcon>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={`AÑADIR UNA NUEVA TARJETA`}
                    />
                </ListItem>
            </List>

        </div>

    )

}

export default CreditCardsList;

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Drawer} from "@material-ui/core";
import {drawerStyles} from '../cart/CartDrawer'
import DrawerHeader from '../cart/DrawerHeader'
import LuxonUtils from '@date-io/luxon';
import {DateTime} from 'luxon'
import {changeDeliveryDateSelectedAction} from "../../../redux/actions/delivery_date/changeDeliveryDateSelectedAction";
import {changeDeliveryDateSelectorVisibilityAction} from "../../../redux/actions/delivery_date/changeDeliveryDateSelectorVisibilityAction";
import { DatePicker,MuiPickersUtilsProvider } from '@material-ui/pickers'
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import Theme from "../../ui/Theme"
import { makeStyles } from '@material-ui/core/styles';


const myStyles = makeStyles((theme) => ({
    calendarWrapper:{
        justifyContent:"center",
        alignContent:"center",
        display:"flex"
    },
    actionBar:{
        marginTop:20,
        marginRight:20,
        float:"right",
        
    }
}))
const SelectDeliveryDateDrawer = (props) => {
    const palette = Theme.palette;
    const dispatch = useDispatch();
    const materialTheme = createMuiTheme({
        overrides: {
            MuiPickersDay:{
                daySelected:{
                    backgroundColor: "#888",
                    color:"#FFF",
                    '&:hover': {
                        backgroundColor: "#888",
                      }
                },
                dayDisabled:{
                    color:"#ccc"
                }
                ,
                day:{
                    color:"#000000",
                    fontWeight:"bold"
                },
                
            }
        },
        palette
    });
    const classes = drawerStyles();
   const myClasses = myStyles()

    const { onHandleClose, open } = props
    
    
    const deliveryDateSelected = useSelector(state => state["deliveryDateReducer"].deliveryDateSelected);
    const shippingMethodSelected = useSelector(state => state["shippingMethodReducer"].shippingMethodSelected);
    //const [selectedDate, setValue] = useState(DateTime.now())


    useEffect(() => {
       // if (open) initDates();
        // eslint-disable-next-line
    }, [open]);

    const initDates = () => {
      ///  dispatch(fetchShippingAddressesAction());
    }
    const onClickCloseButton = () => {
        onHandleClose()
    }
    const onSelectDeliveryDate = ((selectedDate) => {
       //Esta ñapa es porque sin ella cuando no tienes deliveryDate seleccionada, este drawer se cerraba nada más abrirse al dispararse el onAccept 
       //TO_DO lo malo es que ahora al cliente se le selecciona de maner automática la primera fecha disponible una vez que entra quí
     if (deliveryDateSelected){
         dispatch(changeDeliveryDateSelectorVisibilityAction(false))
     }
       dispatch(changeDeliveryDateSelectedAction(selectedDate))
    });
    const onClickSave = () => {
        console.log("SAVE")
     
       //dispatch(changeDeliveryDateSelectedAction(selectedDate))
    };
    const onChangeDeliveryDate = ((selectedDate) => {
       
       // dispatch(changeDeliveryDateSelectedAction(selectedDate))
    });
    const disableDates = ((date) => {
      
        let preparationDays = shippingMethodSelected.preparationDays;
        if(DateTime.now().hour > shippingMethodSelected.limitHour){
            preparationDays = preparationDays + 1;
        }
        
        //si la fecha es inferior a la primera fecha disponible desabilitamos esa fecha
        var firstDateAvailable = DateTime.now().plus({days: preparationDays});
        if (date < firstDateAvailable) return true;

        //comprobamos si la fecha está entre los días de reparto y si no desabilitamos la fecha
        var weekDayName = date.setLocale("en").weekdayLong.toLowerCase();
        return shippingMethodSelected.deliveryDays.includes(weekDayName) == false;
       
      });
    return (
   
       
        <Drawer anchor={"right"} open={open} onClose={onHandleClose}  classes={{paper: classes.paper}}>
            <DrawerHeader title="Elige la fecha de entrega" onHandleClose={onHandleClose}/>
            <div className={classes.container} style={{backgroundColor:"white"}}>    
            <div className={myClasses.calendarWrapper} >
                    <ThemeProvider theme={materialTheme}>
                      <MuiPickersUtilsProvider utils={LuxonUtils} locale={"es"}>
                            <DatePicker   variant="static" disablePast openTo="date" value = {deliveryDateSelected} shouldDisableDate={disableDates}
                               onChange={onChangeDeliveryDate} onAccept={onSelectDeliveryDate} />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                    
                    </div>
                    <div className={myClasses.actionBar}>
                  
                    </div> 
            </div>  
           
        </Drawer>
    );
}



export default SelectDeliveryDateDrawer;
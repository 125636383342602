import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ClientDataDrawer from "./../account/ClientDataDrawer"
import { Box, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import HomeIcon from '@material-ui/icons/Home';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Button, Checkbox, Drawer, IconButton, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { changeShippingMethodSelectorVisibilityAction } from "../../../redux/actions/shipping_method/changeShippingMethodSelectorVisibilityAction";
import { changePaymentMethodSelectorVisibilityAction } from "../../../redux/actions/payment_method/changePaymentMethodSelectorVisibilityAction";
import { changeDeliveryDateSelectorVisibilityAction } from "../../../redux/actions/delivery_date/changeDeliveryDateSelectorVisibilityAction";
import { changeShippingAddressSelectedAction } from "../../../redux/actions/address/changeShippingAddressSelectedAction";
import { fetchPaymentMethodsAction } from "../../../redux/actions/payment_method/fetchPaymentMethodsAction";
import { fetchShippingMethodsAction } from "../../../redux/actions/shipping_method/fetchShippingMethodsAction";
import { fetchDigitalShippingMethodAction } from "../../../redux/actions/shipping_method/fetchDigitalShippingMethod"
import { fetchShippingAddressesAction } from "../../../redux/actions/address/fetchShippingAddressesAction";
import { changePaymentMethodSelectedAction } from "../../../redux/actions/payment_method/changePaymentMethodSelectedAction";
import { changeShippingMethodSelectedAction } from "../../../redux/actions/shipping_method/changeShippingMethodSelectedAction";
import { changeDeliveryDateSelectedAction } from "../../../redux/actions/delivery_date/changeDeliveryDateSelectedAction";
import { changeCreditCardSelectorVisibilityAction } from "../../../redux/actions/credit_card/changeCreditCardSelectorVisibilityAction";
import { createOrderAction } from "../../../redux/actions/order/createOrderAction";
import { changePayOrderVisibilityAction } from "../../../redux/actions/cart/changePayOrderVisibilityAction";
import { CalendarToday as DateIcon, Today as DateSelectedIcon, Receipt, ChevronRight as RightIcon } from "@material-ui/icons";
import DrawerHeader from "../cart/DrawerHeader"
import { DateTime } from 'luxon'
import { getPayCreditCardPaymentClientSecretAction } from "../../../redux/actions/cart/getPayCreditCardPaymentClientSecretAction";
import { notifyOrderPaymentAction } from "../../../redux/actions/order/notifyOrderPaymentAction";
import { useStripe } from "@stripe/react-stripe-js";
import { Fragment } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import SelectAddressDrawer from "../address/SelecAddressDrawer"
import { loadClientAction } from '../../../redux/actions/client/loadClientAction';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '400px',
        backgroundColor: theme.palette.background.paper,
    },
    topbar: {
        boxShadow: "0 8px 24px 0 rgba(37, 36, 47, .06), inset 0 -1px 0 0 #e5e5e5",
        display: "flex",
        height: 56,
        paddingLeft: 5,

        backgroundColor: "white"
    }, paper: {
        background: "#F5F5F5",
    },

    topbar: {
        boxShadow: "0 8px 24px 0 rgba(37, 36, 47, .06), inset 0 -1px 0 0 #e5e5e5",
        display: "flex",
        height: 56,
        paddingLeft: 5,

        backgroundColor: "white"
    },
    title: {
        flexGrow: 100,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column"
    },
    emptyCart: {
        flexGrow: 50,
        padding: 20,
        maxWidth: 400,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column"
    },
    backbutton: {
        padding: '1rem',
        position: "absolute",
        top: 0,
        left: 0,
        margin: 0
    },
    footer: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        padding: '1rem',
        boxShadow: "0 -1px 8px 0 rgba(0,0,0,.1)"
    },
    sectionDivider: {
        height: "10px"
    },
    bottomItem: {

    },
    swalIcon: {
        width: "60px",
        height: "60px"
    }
}));


const ShoppingInfoComponent = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const stripeHook = useStripe();
    const { onHandleClose, open } = props;

    const [onlyVouchers, setOnlyVouchers] = useState(false)

    const details = useSelector(state => state["cartReducer"].detailsInCart);
    const shippingAddressSelected = useSelector(state => state["addressReducer"].shippingAddressSelected);
    const shippingAddresses = useSelector(state => state["addressReducer"].shippingAddresses);
    const billingAddressSelected = useSelector(state => state["addressReducer"].billingAddressSelected);
    const shippingMethodSelected = useSelector(state => state["shippingMethodReducer"].shippingMethodSelected);
    const digitalShippingMethod = useSelector(state => state["shippingMethodReducer"].digitalShippingMethod);
    const shippingMethods = useSelector(state => state["shippingMethodReducer"].shippingMethods);
    const deliveryDateSelected = useSelector(state => state["deliveryDateReducer"].deliveryDateSelected);
    const paymentMethodSelected = useSelector(state => state["paymentMethodReducer"].paymentMethodSelected);
    const newOrder = useSelector(state => state["orderReducer"].newOrder);
    const creditCardSelected = useSelector(state => state["creditCardReducer"].creditCardSelected);
    const paymentMethods = useSelector(state => state["paymentMethodReducer"].paymentMethods);
    const orderClientSecret = useSelector(state => state["cartReducer"].orderClientSecret);
    const client = useSelector(state => state["clientReducer"].client)



    const [selectingAddress, setSelectingAddress] = useState(false)
    const [editingClientData, setEditingClientData] = useState(false)
    const [companyBillingRequired, setCompanyBillingRequired] = useState(false)
    const canSelectDeliveryDate = !process.env.REACT_APP_SELECT_DELIVERY_DATE_DISABLED

    const ReactSwal = withReactContent(Swal)

    const onClickCloseButton = () => {
        onHandleClose()
    }

    useEffect(() => {
        if (newOrder) payOrder();
        // eslint-disable-next-line
    }, [newOrder]);

    useEffect(() => {
        if (client && client.paymentMethod) {
            dispatch(changePaymentMethodSelectedAction(client.paymentMethod));
        }
    }, [client])


    useEffect(() => {
        if (orderClientSecret) captureMoney()
    }, [orderClientSecret]);

    useEffect(() => {
        if (open) {
            initPaymentMethods();
            initClient()
            if (!shippingAddressSelected) {
                initAddressess()
            }
            //initShippingMethods()
            setOnlyVouchers(details.findIndex(detail => !detail.price.product.voucherInfo) === -1);
            if (onlyVouchers) {
                dispatch(changeDeliveryDateSelectedAction(DateTime.now()))
            }
        }
        if (open && !digitalShippingMethod) {
            dispatch(fetchDigitalShippingMethodAction())
        }
        // eslint-disable-next-line
    }, [open]);
    useEffect(() => {
        //QUEREMOS SELECCIONAR AUTOMÁTICAMENTE LA DIRECCIÓN DE ENVÍO CUANDO SOLO HAY UNA DISPONIBLE
        if (!shippingAddressSelected && shippingAddresses && shippingAddresses.length === 1) {
            dispatch(changeShippingAddressSelectedAction(shippingAddresses[0]))
        }

        // eslint-disable-next-line
    }, [shippingAddresses]);

    useEffect(() => {
        if (shippingAddressSelected) {
            initShippingMethods()
        }
    }, [shippingAddressSelected]);

    useEffect(() => {
        //QUEREMOS SELECCIONAR AUTOMÁTICAMENTE EL MËTODO DE ENVÍO CUANDO SOLO HAY UNO DISPONIBLE
        //PERO SI SOLO HAY VOUCHERS SELECCIONAMOS EL ENVÍO DIGITAL
        if (onlyVouchers && digitalShippingMethod) {
            dispatch(changeShippingMethodSelectedAction(digitalShippingMethod))
        } else if (!shippingMethodSelected && !onlyVouchers && shippingMethods && shippingMethods.length === 1) {
            dispatch(changeShippingMethodSelectedAction(shippingMethods[0]))
        }

    }, [shippingMethods]);
    useEffect(() => {
        //SI SOLO HAY VOUCHERS SELECCIONAMOS EL ENVÍO DIGITAL
        if (onlyVouchers && digitalShippingMethod) {
            dispatch(changeShippingMethodSelectedAction(digitalShippingMethod))
            dispatch(changeDeliveryDateSelectedAction(DateTime.now()))
        }

    }, [digitalShippingMethod]);

    const initPaymentMethods = () => {
        dispatch(fetchPaymentMethodsAction());
    }
    const initAddressess = () => {
        dispatch(fetchShippingAddressesAction());
    }
    const initClient = () => {
        dispatch(loadClientAction());
    }
    const initShippingMethods = () => {
        dispatch(fetchShippingMethodsAction(shippingAddressSelected.province.code));
    }


    if (!details) return null;

    const amount = details.reduce((a, b) => a + (b.getPrice() || 0), 0);


    const createOrder = () => {
        details.forEach(element => {
            if (element.price.product.voucherInfo) {
                element.voucherData = {
                    shippingType: 'digital',
                    email: element.email,
                    receiver: element.receiver,
                    dedication: element.dedication
                }
            }
            if (element.price.product.options) {
                for (let option of element.price.product.options) {
                    if (option.quantity >= 1) {
                        if (element.optionsSelected) {
                            element.optionsSelected.push(option)
                        } else {
                            element.optionsSelected = [option]
                        }
                    }
                }
            }
        });

        if (!shippingAddressSelected) return
        let orderBody = {
            billingAddressId: billingAddressSelected.id,
            details,
            paymentMethodId: paymentMethodSelected.id,
            shippingAddressId: shippingAddressSelected.id,
            shippingMethodId: shippingMethodSelected.id,
            estimatedDeliveryDate: deliveryDateSelected?.toISO()

        };

        if (creditCardSelected) {
            orderBody = {
                ...orderBody,
                paymentInfo: {
                    creditCard: {
                        stripeId: creditCardSelected.stripeId,
                        lastCardNumbers: creditCardSelected.lastCardNumbers,
                        brand: creditCardSelected.brand,
                        ownerName: billingAddressSelected.name,
                        expMonth: creditCardSelected.expMonth.toString(),
                        expYear: creditCardSelected.expYear.toString()
                    },
                    captureStatus: 'pending',
                    status: 'pending',
                    paymentIntentId: creditCardSelected.id,
                    totalToPay: amount
                }
            }
        }
        dispatch(createOrderAction(orderBody));
    }

    const payOrder = () => {

        if (newOrder.paymentMethod.creditCardRequired && (!newOrder.paymentMethod.paymentInfo || newOrder.paymentMethod.paymentInfo.status !== 'confirmed')) {
            // dispatch(changePayOrderVisibilityAction(true));
            dispatch(getPayCreditCardPaymentClientSecretAction(newOrder.id));

        } else {
            dispatch(notifyOrderPaymentAction('success'));
            //  console.log('El pedido no fae falta que se pague');
        }
    }


    const captureMoney = async event => {
        ReactSwal.fire({
            customClass: {
                container: "swalert"
            },
            title: 'Comprobando los datos...',
            didOpen: () => {
                Swal.showLoading()
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
            }
        })
        const payload = await stripeHook
            .confirmCardPayment(orderClientSecret, {
                payment_method: creditCardSelected.stripeId
            });
        ReactSwal.close()
        if (payload.error) {
            dispatch(notifyOrderPaymentAction('error', payload.error.message));
        } else {
            dispatch(notifyOrderPaymentAction('success'));
        }
        onHandleClose();
    }
    const onHandlePayOrderClose = () => {
        dispatch(changePayOrderVisibilityAction(false));
    }

    const onClickSelectShippingAddress = () => {
        // dispatch(changeAddressSelectorVisibilityAction(true));
        setSelectingAddress(true)
    }

    const onClickSelectShippingMethod = () => {
        dispatch(changeShippingMethodSelectorVisibilityAction(true));
    }
    const onClickSelectDeliveryDate = () => {
        dispatch(changeDeliveryDateSelectorVisibilityAction(true));
    }

    const onClickSelectPaymentMethod = () => {
        if (paymentMethods.length == 1 && paymentMethods[0].creditCardRequired) {
            dispatch(changePaymentMethodSelectedAction(paymentMethods[0]));
            dispatch(changeCreditCardSelectorVisibilityAction(true));
        } else if (paymentMethods.length > 1) {
            dispatch(changePaymentMethodSelectorVisibilityAction(true));
        } else {
            dispatch(changePaymentMethodSelectedAction(paymentMethods[0]));
        }
    }

    const onClickPayButton = () => {
        if (!onlyVouchers) {
            if (!checkOrderParameters()) {
                return;
            }
        }
        if (onlyVouchers) {

            dispatch(changeDeliveryDateSelectedAction(DateTime.now()))
            if (digitalShippingMethod) {
                dispatch(changeShippingMethodSelectedAction(digitalShippingMethod))
            }
            if (!checkVoucherParameters()) {
                return
            }
        }
        if (!newOrder) {
            createOrder();
        } else {
            payOrder();
        }
    }

    const checkShippingParameters = () => {
        if (!shippingAddressSelected) {
            ReactSwal.fire({
                customClass: {
                    container: "swalert"
                },
                confirmButtonColor: "#90594c",
                title: onlyVouchers ? "Tienes que elegir una dirección de facturación" : "Tienes que elegir una dirección de envío",
                iconHtml: onlyVouchers ? <Receipt className={classes.swalIcon}></Receipt> : <HomeIcon className={classes.swalIcon} />,
            });
            return false
        }
        if (!shippingMethodSelected) {
            ReactSwal.fire({
                customClass: {
                    container: "swalert"
                },
                confirmButtonColor: "#90594c",
                title: "Tienes que elegir un método de envío",
                iconHtml: <LocalShippingIcon className={classes.swalIcon} />,
            });
            return false
        }
        return true;
    }
    const checkDeliveryDateParameters = () => {
        if (canSelectDeliveryDate && !deliveryDateSelected) {
            ReactSwal.fire({
                customClass: {
                    container: "swalert"
                },
                confirmButtonColor: "#90594c",
                title: "Tienes que elegir una fecha de entrega",
                iconHtml: <DateIcon className={classes.swalIcon} />,
            });
            return false
        }
        return true
    }
    const checkPaymentParameters = () => {
        if (!paymentMethodSelected) {
            ReactSwal.fire({
                customClass: {
                    container: "swalert"
                },
                confirmButtonColor: "#90594c",
                title: "Tienes que elegir un método de pago",
                iconHtml: <CreditCardIcon className={classes.swalIcon} />,
            });
            return false
        }
        return true;
    }


    const checkVoucherParameters = () => {
        return checkShippingParameters() && checkPaymentParameters()
    }
    const checkOrderParameters = () => {
        return checkShippingParameters() && checkDeliveryDateParameters() && checkPaymentParameters()
        /* if (!creditCardSelected){
             ReactSwal.fire({
                 icon:"success",
                 text:"Esperamos que disfrutes de tu compra",
                 customClass:{
                     container:"swalert"
                 },
                 confirmButtonColor:"#90594c",
                 title:"PEDIDO COMPLETADO",
                 });
             return false
         }*/

    }

    const shippingMethodValueDescription = () => {

        if (!shippingMethodSelected) return "--"
        let cartValue = details.reduce((a, b) => a + (b.getPrice() || 0), 0);
        if (cartValue > shippingMethodSelected.freeFrom) {
            return `Gratis`
        }
        return `${shippingMethodSelected.value.toFixed(2)} €`;
    }
    const shippingMethodActualValue = () => {

        if (!shippingMethodSelected) return 0
        let cartValue = details.reduce((a, b) => a + (b.getPrice() || 0), 0);
        if (cartValue > shippingMethodSelected.freeFrom) {
            return 0
        }
        return shippingMethodSelected.value;
    }

    const creditCardNotNeeded = client && client.paymentMethod && client.paymentMethod.creditCardRequired == false

    const isPaymentMethodSelectorDisabled = () => {
        if (!paymentMethods) return true
        if (creditCardNotNeeded) return true
        if (onlyVouchers) return false
        return !deliveryDateSelected
    }

    const onClickShowEditClientData = () => {
        setEditingClientData(true)
    }
    const onSelectAddressClosed = () => {
        setSelectingAddress(false)
    }
    const onClientDataClosed = () => {
        setEditingClientData(false)
    }
    const companyBillingChanged = (event, value) => {
        if (value === true && (!client.billingInfo || !client.billingInfo.nif)) {
            setEditingClientData(true)
        } else {
            setCompanyBillingRequired(value)
        }
    }

    const paymentMethodText = () => {
        if (client && client.paymentMethod) {
            if (client.paymentMethod.creditCardRequired && creditCardSelected) {
                return creditCardSelected ? (creditCardSelected.brand.toUpperCase() + " ****" + creditCardSelected.lastCardNumbers) : "";
            } else {
                return client.paymentMethod.name;
            }
        }

        return undefined
    }



    const paymentDisabled = details.length === 0 || shippingMethodSelected === null || billingAddressSelected === null || shippingAddressSelected === null || paymentMethodSelected === null;
    //const paymentDisabled = details.length === 0 ||  paymentMethodSelected === null;

    return (
        <React.Fragment >
            <Drawer anchor={"right"} open={open} onClose={onHandleClose} >
                <DrawerHeader title="Pago y envío" onHandleClose={onHandleClose} />
                <div className={classes.container}>
                    <List component="nav">
                        {onlyVouchers ?
                            <Fragment>
                                <ListItem button onClick={onClickSelectShippingAddress}>
                                    <ListItemIcon>
                                        <Receipt />
                                    </ListItemIcon>
                                    <ListItemText primary={"Dirección de Facturación"} secondary={
                                        <div>
                                            <div>{shippingAddressSelected ? shippingAddressSelected.street : ""}</div>
                                            <div>{shippingAddressSelected ? shippingAddressSelected.province.name : ""}</div>
                                        </div>
                                    } />
                                    <ListItemSecondaryAction >
                                        <IconButton edge="end" aria-label="comments">
                                            <RightIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                <ListItem button>
                                    <ListItemIcon>
                                        <LocalShippingIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Envío por email"} secondary={"El cupón se enviará a su destinatario por correo electrónico"} />
                                </ListItem>
                                <Divider />
                            </Fragment>
                            :
                            <Fragment>
                                <ListItem button onClick={onClickSelectShippingAddress}>
                                    <ListItemIcon>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Dirección de Envío"} secondary={
                                        <div>
                                            <div>{shippingAddressSelected ? shippingAddressSelected.street : ""}</div>
                                            <div>{shippingAddressSelected ? shippingAddressSelected.province.name : ""}</div>
                                        </div>
                                    } />
                                    <ListItemSecondaryAction >
                                        <IconButton edge="end" aria-label="comments">
                                            <RightIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                <ListItem button disabled={!shippingAddressSelected} onClick={onClickSelectShippingMethod}>
                                    <ListItemIcon>
                                        <LocalShippingIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Método de envío"} secondary={shippingMethodSelected ? shippingMethodSelected.name : ""} />
                                    <ListItemSecondaryAction >
                                        <IconButton edge="end" aria-label="comments">
                                            <RightIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                {canSelectDeliveryDate ? <>
                                    <ListItem button disabled={!shippingMethodSelected} onClick={onClickSelectDeliveryDate}>
                                        <ListItemIcon>
                                            {deliveryDateSelected ? <DateSelectedIcon /> : <DateIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary={"Fecha de entrega"} secondary={deliveryDateSelected ? deliveryDateSelected.setLocale('es').toLocaleString(DateTime.DATE_FULL) : ""} />
                                        <ListItemSecondaryAction >
                                            <IconButton edge="end" aria-label="comments">
                                                <RightIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                </> : ""
                                }
                            </Fragment>
                        }

                        <ListItem button disabled={isPaymentMethodSelectorDisabled()} onClick={onClickSelectPaymentMethod}>
                            <ListItemIcon>
                                <CreditCardIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Método de pago"} secondary={
                                paymentMethodText()
                            } />
                            <ListItemSecondaryAction >
                                <IconButton edge="end" aria-label="comments">
                                    <RightIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <Divider />
                        {/*
                        <ListItem disabled={!client}>
                            <ListItemIcon>
                                <Receipt />
                            </ListItemIcon>
                            <ListItemText primary={<div>Quiero factura de empresa</div>} secondary={<div style={{ textDecoration: "underline", cursor: "pointer" }} onClick={onClickShowEditClientData}>Editar datos de facturación</div>} />
                            <ListItemSecondaryAction >
                                <Checkbox disabled={!client || !paymentMethodSelected} id="companyBilling" checked={companyBillingRequired} onChange={companyBillingChanged} ></Checkbox>
                            </ListItemSecondaryAction>

                        </ListItem>

                        <Divider />

*/}
                        <Divider className={classes.sectionDivider} />
                        <ListItem className={classes.bottomItem} style={{ paddingBottom: 0 }}>

                            <ListItemText primary="Compra" secondary={details.length + ((details.length > 1) ? " productos" : " producto")} />
                            <ListItemSecondaryAction>
                                {amount.toFixed(2)} €
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem className={classes.bottomItem} style={{ paddingTop: 0 }}>

                            <ListItemText primary="Gastos de envío" secondary={((shippingMethodSelected) ? shippingMethodSelected.name : "No seleccionado")} />
                            <ListItemSecondaryAction>
                                {(shippingMethodSelected ? shippingMethodValueDescription() : "--")}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        <ListItem>

                            <ListItemText primary="Total" primaryTypographyProps={{ variant: "h6" }} />
                            <ListItemSecondaryAction>
                                <Typography variant="h6">{(amount + shippingMethodActualValue()).toFixed(2)} €</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>

                    </List>

                    <Box className={classes.footer}>
                        <Button variant={"contained"} type="submit" color="secondary" fullWidth className={classes.continueButton} onClick={onClickPayButton}>

                            {creditCardNotNeeded ?
                                <span className={classes.continueButtonLabel}> Finalizar pedido </span>
                                :
                                <span className={classes.continueButtonLabel}> Pagar {amount.toFixed(2)} €</span>
                            }
                        </Button>
                    </Box>


                </div>
            </Drawer>
            <SelectAddressDrawer onHandleClose={onSelectAddressClosed} open={selectingAddress} />
            <ClientDataDrawer onHandleClose={onClientDataClosed} open={editingClientData} />
        </React.Fragment>
    );
}

export default ShoppingInfoComponent;

import { CREATE_ORDER_ERROR, CREATE_ORDER_SUCCESS, INIT_CREATE_ORDER } from "../../types/orderTypes";
import {createOrderAsync} from "../../../services/orderServices";

export function createOrderAction({billingAddressId, details, paymentInfo, paymentMethodId, shippingAddressId, shippingMethodId,estimatedDeliveryDate}) {
    return async (dispatch) => {
        dispatch(initCreateOrder());

        const createOrderBody = {
            details: details.map(detail => {
                return {
                    quantity: detail.quantity,
                    amountModeSelected: 'units',
                    productId: detail.price.product.id,
                    voucherData:detail.voucherData
                }
            }),
            paymentInfo,
            paymentMethodId,
            shippingAddressId,
            billingAddressId,
            shippingMethodId,
            estimatedDeliveryDate,
            from: 'web'
        }

        const newOrder = await createOrderAsync(localStorage.getItem('clientId'), createOrderBody);
        if (newOrder instanceof Error) {
            dispatch(orderCreatedError(newOrder));
            return;
        }

        dispatch(orderCreatedSuccess(newOrder));
    }
}

const initCreateOrder = () => ({
    type: INIT_CREATE_ORDER
});

const orderCreatedSuccess = order => ({
    type: CREATE_ORDER_SUCCESS,
    payload: order
});

const orderCreatedError = error => ({
   type: CREATE_ORDER_ERROR,
   payload: error
});

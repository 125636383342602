import React , { useState, useEffect } from 'react';
import { Button, IconButton, Dialog, Typography,  Box, DialogTitle, FormControlLabel, Checkbox, TextField, FormLabel, FormGroup} from '@material-ui/core/';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch} from "react-redux";


import 'react-gallery-carousel/dist/index.css'
import { makeStyles } from '@material-ui/core/styles';
import { PlusCircle as PlusIcon, MinusCircle as MinusIcon, Trash2 as DeleteIcon,ShoppingCart as CartIcon, ShoppingBag as BagIcon, ShoppingBag } from "react-feather";
import { Close as CloseIcon, CardGiftcardOutlined as GiftIcon, EmailOutlined as EmailIcon,} from "@material-ui/icons"
import { addDetailToCartAction } from "../../redux/actions/cart/addDetailToCartAction";
import Collapse from '@material-ui/core/Collapse';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from 'react-dom/cjs/react-dom.development';
import VoucherOptions from "../components/voucher/VoucherOptions"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const VoucherOptionsDialog = (props) => {
    const dispatch = useDispatch();
    const [onCart, setOnCart] = React.useState(true);
    const { detail, open, onHandleClose,onHandleSuccess } = props;

    const [isAGift,setAsGift] = React.useState(detail ? detail.receiver != null:false)
    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    //const detail = useSelector(state => state["cartReducer"].detailsInCart.find(({ id }) => detail ? (id === detail.id):false));
    const [state, setState] = useState({
        voucherData: detail ? detail.voucherData:null,
        voucherShippingInfo:{
            email:detail ? detail.email:"",
            shippingType:"digital",
            dedication:detail ? detail.dedication:"",
            receiver:detail ? detail.receiver:""
        },
        emailError:null,
        quantity:detail ? detail.quantity:0,
        voucherInfo:detail ? detail.price.product.voucherInfo:null
    });
    useEffect(() => {
        
        let shippingInfo = {
            email:detail ? detail.email:"",
            shippingType:"digital",
            dedication:detail ? detail.dedication:"",
            receiver:detail ? detail.receiver:""
        }
       setState({...state, voucherShippingInfo: shippingInfo })
       if(!state.quantity){
       setState({...state, quantity: (props.detail ? props.detail.quantity:0)})
       }
      
       if(props.detail && props.detail.price.product.voucherInfo && !state.voucherInfo){
        setState({...state, voucherInfo: (props.detail ? JSON.parse(JSON.stringify(props.detail.price.product.voucherInfo)):null)})
    
    }
       setAsGift(detail ? detail.receiver != null:false)
      },[detail]);
   
      useEffect(() => {
        if(detail ){
            setState({...state, voucherInfo: detail ? detail.price.product.voucherInfo:null})
        }
      },[open]);
    


    const useStyles = makeStyles((theme) => ({

        paper: {
            background: "#F5F5F5",
            elevation: 1
        },
        formLabel: {
            paddingBottom: "1.5rem",
            color:"#555555",
            display:"flex"
        },
        sectionLabel:{
            alignSelf: "center",
            paddingLeft: "10px",
            lineHeight:"1.3"
        },
        closeButton: {
            position: "absolute",
            right: "0px",
            top: "0px"
        },
        dialogBody:{
            overflowY:"auto",
            maxHeight:"calc(100vh - 112px)"
        },
        dialogTitle: {
            paddingBottom: 0,
            paddingTop: 0,
            paddingRight: 4,
            boxShadow: "-1px -1px 12px 0 #d5d5d5"
        },
        dialogContent: {
            display: 'flex',
            flexDirection: 'column',
            padding: "20px,24px,24px,24px",
            overflowY:"hidden",
            borderBottom:0

        },
        giftInfo: {
            display: 'flex',
            flexDirection: 'column',
            padding: "12px 24px 0px 24px",
        },
        emailTextField: {
           
        },
        destinataryTextField: {
            marginBottom: "1rem",
        },
        dedicationTextField: {
            marginBottom: "1rem"
        },
        dialogActions: {
            display: "flex",
            padding: "12px",
            boxShadow: "-1px -1px 12px 0 #d5d5d5"
        },
        personPicker: {
            width: "100%",
            display: "inline-flex",
            flexDirection: "row",
            marginTop: "1auto",
            borderRadius: "6px",
            justifyContent: "center",
            alignItems: "center",
            verticalAlign: "middle"
        },
        detailUnits: {
            display: "-webkit-inline-box",
            display: "-webkit-inline-flex",
            display: "inline-flex",
            webkitBoxOrient: "vertical",
            webKitBoxDirection: "normal",
            webkitFlexDirection: "column",
            flexDirection: "column",
            webkitBoxAlign: "top",
            webkitAlignItems: "top",
            alignItems: "top",
            textAlign: "center"
        }, personsTextField: {
            marginRight: "1rem"
        },
        errorMessage: {
            marginTop: "30px",
            top: "20px",
            borderColor: "red",
            borderStyle: "dashed",
            borderWidth:"thin",
            padding: "10px",
            borderRadius: "8px",
            color: "red",
        }, 
        optionTotal:{
            fontSize:"15px",
            minWidth: "fit-content",
            paddingLeft: "20px",
            paddingRight:"0px",
            minWidth:"90px",
            fontWeight:"bold",
            textAlign:"center"
        },
        dialogButtons:{
            display:"flex",
            justifyContent:"flex-end",
            gap:"1rem",
            marginBottom:"2rem",
            paddingRight:"20px"
        }


    }));
    const classes = useStyles();
    const { voucherInfo,voucherShippingInfo} = state;
    if (!voucherInfo) return null;

    const voucherOptionsSchema = Yup.object({
        email: Yup.string().email("Debes de introducir un email válido").trim().required('Debes introducir un email válido'),
        isAGift: Yup.bool(),
        receiver: Yup.string().when("isAGift", {
            is: true,
            then: Yup.string()
        }),
        dedication: Yup.string(),
        quantity: Yup.number().min(voucherInfo.minUnits,`La cantidad mínima es ${voucherInfo.minUnits} €`)
    })
   

    const SelectNumberOfPeopleButton = (props) => (
        <React.Fragment>
            <Box className={classes.personPicker} classes={{ paper: classes.paper }}>
                {<React.Fragment>
                    <TextField
                        label="Número de personas" 
                        type="number" 
                        fullWidth
                        className={classes.personsTextField}
                        color="secondary"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true
                        }}
                        {...props.getFieldProps('quantity')}
                        placeholder="Número de adultos" 
                        value={state.quantity} />
                </React.Fragment>
                    // <Typography className={classes.quantitySelectorLabel}> {detail.quantity} {detail.quantity == 1 ? "unidad":"unidades"} en el carrito</Typography> 
                }
                <div className={classes.detailUnits} disableGutters={true}>
                    <IconButton size="small" onClick={onPlusClicked} disabled={detail.price.product.voucherInfo.maxUnits === state.quantity}><PlusIcon /></IconButton>
                    <IconButton size="small" onClick={onMinusClicked} disabled={detail.price.product.voucherInfo.minUnits === state.quantity}><MinusIcon /></IconButton>
                </div>
                <Typography className={classes.optionTotal}>{state.quantity ? ((detail.getUnitaryPrice()*state.quantity).toFixed(2)+" €"):"0.00 €"}</Typography>
            </Box>
            <Typography style={{ paddingLeft: "14px" }} color="secondary" variant="subtitle1">Entre {detail.price.product.voucherInfo.minUnits} y {detail.price.product.voucherInfo.maxUnits} adultos</Typography>
        </React.Fragment>
    );
    const onPlusClicked = () => {
        //dispatch(incDetailInCartAction(detail));
        setState({...state,quantity:state.quantity+1})
        setOnCart({ onCart: true });
    }
    const onMinusClicked = () => {
       // dispatch(decDetailInCartAction(detail))
       setState({...state,quantity:state.quantity-1})
        if (state.quantity == 0) {
            setOnCart({ onCart: true });
        } else {
            setOnCart({ onCart: false });
        }
    }
    const onQuantityChanged = (event) => {
        detail.quantity = event.target.value
       setOnCart({onCart:true})
    }
    const detailQuantityIsValid = () =>{
        if (detail.quantity <= 0) return false
        if (voucherInfo.minUnits && (detail.quantity < voucherInfo.minUnits)) return false
        if (voucherInfo.maxUnits && (detail.quantity > voucherInfo.maxUnits)) return false
        return true
    }
    const SelectVoucherQuantityButton = (props) => (
        <React.Fragment>
            <FormLabel className={classes.formLabel}>Elige el importe del bono. Podrá canjearse, total o parcialmente, en cualquiera de nuestros restaurantes:</FormLabel>
            <Box className={classes.personPicker} classes={{ paper: classes.paper }}>
                {<React.Fragment>
                    <TextField type="number" fullWidth className={classes.personsTextField} color="secondary" label="Importe del cupón (en €)" variant="outlined" 
                    //onChange={onQuantityChanged}
                    {...props.getFieldProps('quantity')}
                        helperText={(props.touched.quantity && props.errors.quantity) ? props.errors.quantity:`Cualquier importe a partir de ${detail.price.product.voucherInfo.minUnits} €`}
                        error={props.touched.quantity && props.errors.quantity}
                        InputLabelProps={{
                            shrink: true
                        }}
                        
                        placeholder="120"  />
                </React.Fragment>
                    // <Typography className={classes.quantitySelectorLabel}> {detail.quantity} {detail.quantity == 1 ? "unidad":"unidades"} en el carrito</Typography> 
                }
               
            </Box>
            {/*props.touched.quantity && props.errors.quantity ?
            <Typography style={{ paddingLeft: "14px" }} color="secondary" variant="subtitle1">Cualquier importe a partir de {detail.price.product.voucherInfo.minUnits} €</Typography>            
:
<Typography className={classes.errorMessage} variant="subtitle1">Elige una cantidad válida </Typography>
            */}
            </React.Fragment>
    );
    

    const handleChange = (event) => {
        let isAGift = event.target.id == "notGift" ? !event.target.checked : event.target.checked
        if (isAGift == false){
                voucherShippingInfo.dedication = ""
                voucherShippingInfo.email = state.voucherShippingInfo.email
                voucherShippingInfo.receiver = ""
        }
        setAsGift(isAGift)
        setState({ ...state, voucherShippingInfo:voucherShippingInfo });
    };
    const handleEmailChanged = (event) => {
        voucherShippingInfo.email = event.target.value
        setState({ ...state, voucherShippingInfo: voucherShippingInfo ,emailError:null});
    };
    const handleDedicatoryChanged = (event) => {
        voucherShippingInfo.dedication = event.target.value
        setState({ ...state, voucherShippingInfo: voucherShippingInfo });

    };
    const handleReceiverChanged = (event) => {
        voucherShippingInfo.receiver = event.target.value
        setState({ ...state, voucherShippingInfo: voucherShippingInfo });
    };
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const handleAccept = (event) => {
        if (!validateEmail(voucherShippingInfo.email)){
            setState({...state,emailError:"Debes introducir un email válido"})
            return;
        }
        detail.email = voucherShippingInfo.email;
        detail.shippingType = voucherShippingInfo.shippingType;
        detail.dedication = voucherShippingInfo.dedication;
        detail.receiver = voucherShippingInfo.receiver;
        dispatch(addDetailToCartAction(detail));
        onHandleSuccess()
    };
    const handleClose = () => {
        setState({...state,quantity:detail.quantity,voucherInfo:detail.price.product.voucherInfo})
        onHandleClose()
    }
   
    
   // const acceptDisabled = isAGift ? (voucherShippingInfo ? !voucherShippingInfo.email : true) : false 
  
 
    if (!detail  || !detail.price.product.voucherInfo) return null;
    const acceptDisabled = isAGift ? (!voucherShippingInfo.receiver || !voucherShippingInfo.dedication):!voucherShippingInfo.email

    return (
        <React.Fragment>

            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={"md"}
                fullScreen={fullScreen}
                fullWidth>

                <DialogTitle className={"dialogTitle"} classes={{ root: classes.dialogTitle }}>

                    <Box display="flex" alignItems="center" style={{ padding: 0 }}>

                        <Box flexGrow={1} style={{ padding: 0 }} >Opciones del cupón</Box>
                        <Box>
                            <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <Formik
                    initialValues={{
                        quantity:state.quantity ? state.quantity: (voucherInfo && voucherInfo.minUnits) ? voucherInfo.minUnits:0,
                        email: voucherShippingInfo ? voucherShippingInfo.email : "",
                        shippingType: "digital",
                        dedication: voucherShippingInfo ? voucherShippingInfo.dedication : "",
                        receiver: voucherShippingInfo ? voucherShippingInfo.receiver : "",
                        isAGift:voucherShippingInfo ? (voucherShippingInfo.receiver != undefined):false,
                    }
                    }
                    validationSchema={
                        voucherOptionsSchema
                    }
                    onSubmit={(values) => {
                        const form = voucherOptionsSchema.cast(values)//esto es necesario para que las transformaciones (por ejemplo, el trim) se apliquen
                        detail.email = form.email;
                        detail.shippingType = form.shippingType;
                        detail.dedication = form.dedication;
                        detail.receiver = form.receiver;
                        detail.quantity = form.quantity;
                        detail.price.product.voucherInfo = voucherInfo;
                        
                        dispatch(addDetailToCartAction(detail));
                        onHandleSuccess()
                       
                    }}
                >
                     {props => (
                        <form
                            onSubmit={props.handleSubmit}
                        >
                <div className={classes.dialogBody}>
                <DialogContent dividers className={classes.dialogContent} >
                    {voucherInfo.type === "product" ?
               
                    <SelectNumberOfPeopleButton {...props}/>
                :<SelectVoucherQuantityButton {...props}/>
                }
                </DialogContent>
                {detail.price.product.options ?
                <DialogContent dividers>
               <VoucherOptions options={detail.price.product.options} maxUnits = {state.quantity} fullScreen = {fullScreen}/> 
               </DialogContent>
                :  ""
            }
                
                <DialogContent dividers className={classes.dialogContent} >
                <div className={classes.formLabel}>
                        <EmailIcon styles={{alignSelf:"center"}}/>
                    <FormLabel className={classes.sectionLabel} >El cupón se enviará por <b>correo electrónico</b> a la siguiente dirección:</FormLabel>
                    </div>
                    <TextField id="email" className={classes.emailTextField} value={voucherShippingInfo.email} color="secondary" label="Email" variant="outlined"
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={handleEmailChanged}
                        placeholder="Email del destinatario del cupón"
                        {...props.getFieldProps('email')}
                        helperText={props.touched.email && props.errors.email}
                        error={props.touched.email && props.errors.email}
                        />
                </DialogContent>
                {  }
                 <DialogContent dividers className={classes.dialogContent} >
                     <div className={classes.formLabel}>
                         <GiftIcon styles={{alignSelf:"center"}}/>
                 <FormLabel className={classes.sectionLabel}>Si es un <b>regalo</b>, puedes añadir una <b>dedicatoria</b> al email:</FormLabel>
                 </div>
                        <TextField className={classes.destinataryTextField} value={voucherShippingInfo.receiver} color="secondary" label="Destinatario" variant="outlined"
                        InputLabelProps={{
                            shrink: true
                        }}
                       // onChange={handleReceiverChanged}
                        placeholder="Nombre del destinatario"
                        {...props.getFieldProps('receiver')}
                        helperText={props.touched.receiver && props.errors.receiver}
                        error={props.touched.receiver && props.errors.receiver}
                        />

                    <TextField className={classes.dedicationTextField} value={voucherShippingInfo.dedication} rows={3} multiline color="secondary" label="Dedicatoria" variant="outlined"
                        InputLabelProps={{
                            shrink: true
                        }}
                       // onChange={handleDedicatoryChanged}
                        placeholder="Un texto que acompañará al cupón en el correo que recibirá el destinatario" 
                        {...props.getFieldProps('dedication')}
                    helperText={props.touched.dedication && props.errors.dedication}
                    error={props.touched.dedication && props.errors.dedication}
                    />
                </DialogContent>
                <div className={classes.dialogButtons}>
                <Button variant="outlined" onClick={handleClose}>Cancelar</Button>
                    <Button variant="outlined"  variant={"contained"} type="submit" color="secondary">Aceptar</Button>
                    </div>
                </div>
               
               {/* <DialogActions dividers className={classes.dialogActions}>
                    <Button variant="outlined" onClick={handleClose}>Cancelar</Button>
                    <Button variant="outlined"  color="primary" type="submit" color="secondary">Aceptar</Button>
                        </DialogActions>*/}
                </form>)}
                </Formik>
            </Dialog>
        </React.Fragment>
    );
}






export default VoucherOptionsDialog;
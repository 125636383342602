import React, { useEffect } from 'react';
import { Box, Button, Checkbox, Dialog, DialogTitle, FormControlLabel, FormGroup, FormLabel, IconButton, TextField, Typography } from '@material-ui/core/';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch,useSelector } from "react-redux";
import 'react-gallery-carousel/dist/index.css'
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from "@material-ui/icons"
import Collapse from '@material-ui/core/Collapse';
import { drawerStyles } from '../components/cart/CartDrawer';
import { updateVoucherShippingDataAction } from "../../redux/actions/voucher/updateVoucherShippingDataAction";
import { Formik } from 'formik';
import * as Yup from 'yup';


const useStyles = makeStyles((theme) => ({
    paper: {
        background: "#F5F5F5",
        elevation: 1
    },
    formLabel: {
        paddingBottom: "1.5rem"
    },
    closeButton: {
        position: "absolute",
        right: "0px",
        top: "0px"
    },
    dialogTitle: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 4
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: "24px",
    },
    giftInfo: {
        display: 'flex',
        flexDirection: 'column',
        padding: "24px 24px 0px 24px",
    },
    emailTextField: {
        marginBottom: "1rem"
    },
    dedicationTextField: {
        marginBottom: "1rem"
    },
    dialogActions: {
        display: "flex",
        padding: "12px",
        boxShadow: "-1px -1px 12px 0 #d5d5d5"
    },
    personPicker: {
        width: "100%",
        display: "inline-flex",
        flexDirection: "row",
        marginTop: "1auto",
        borderRadius: "6px",
        justifyContent: "center",
        alignItems: "center",
        verticalAlign: "middle"
    },
    detailUnits: {
        display: "inline-flex",
        webkitBoxOrient: "vertical",
        webKitBoxDirection: "normal",
        webkitFlexDirection: "column",
        flexDirection: "column",
        webkitBoxAlign: "top",
        webkitAlignItems: "top",
        alignItems: "top",
        textAlign: "center"
    }, 
    personsTextField: {
        marginRight: "1rem"
    },
    errorMessage: {
        marginTop:"5px",
        borderColor: "red",
        color: "red",
        fontSize:"15px"
    }

}));

const voucherOptionsSchema = Yup.object({
    email: Yup.string().email("Debes de introducir un email váilido").trim().required('Debes introducir un email válido'),
    isAGift: Yup.bool(),
    receiver: Yup.string().when("isAGift", {
        is: true,
        then: Yup.string()
    }),
    dedication: Yup.string(),
})

const VoucherInOrderOptionsDialog = (props) => {
    const dispatch = useDispatch();

    const { voucher, open, onHandleClose } = props;
    const [isAGift, setAsGift] = React.useState(voucher ? ((voucher.receiver != null) && (voucher.receiver.length > 0)) : false)
    const [isUpdating, setIsUpdating] = React.useState(false)
    const voucherUpdatedResult = useSelector(state => state["voucherReducer"].voucherUpdatedResult);
    //const detail = useSelector(state => state["cartReducer"].detailsInCart.find(({ id }) => detail ? (id === detail.id):false));
    
    useEffect(() => {
        setAsGift((voucher.receiver != null) && (voucher.receiver.length > 0))
    }, [props.voucher]);


    useEffect(() => {
        if(voucherUpdatedResult) setIsUpdating(false)

    }, [voucherUpdatedResult]);

   
    const classes = useStyles();
    const drawerClasses = drawerStyles()

    const handleChange = (event) => {
        let isAGift = event.target.id == "notGift" ? !event.target.checked : event.target.checked
        setAsGift(isAGift)
    };

    if (!voucher) return null;
    if(voucherUpdatedResult === "success") onHandleClose()

    return (
        <React.Fragment>
            <Dialog
                onClose={onHandleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={"xs"}
                fullWidth>

                <DialogTitle className={"dialogTitle"} classes={{ root: classes.dialogTitle }}>
                    <Box display="flex" alignItems="center" style={{ padding: 0 }}>
                        <Box flexGrow={1} style={{ padding: 0 }} >Reenviar el cupón</Box>
                        <Box>
                            <IconButton onClick={onHandleClose}><CloseIcon /></IconButton>
                        </Box>
                    </Box>
                </DialogTitle>

                <Formik
                    initialValues={{
                        email: voucher ? voucher.shippingInfo.email : "",
                        shippingType: "digital",
                        dedication: voucher ? voucher.dedication : "",
                        receiver: voucher ? voucher.receiver : "",
                        isAGift:voucher ? (voucher.receiver != undefined):false,
                    }
                    }
                    validationSchema={
                        voucherOptionsSchema
                    }
                    onSubmit={(values) => {
                        setIsUpdating(true)
                        const form = voucherOptionsSchema.cast(values)//esto es necesario para que las transformaciones (por ejemplo, el trim) se apliquen
                        dispatch(updateVoucherShippingDataAction(
                            isAGift ? form.dedication:"",
                            isAGift ? form.receiver:"",
                            form.email, 
                            voucher.id)
                        );
                       
                    }}
                >
                    {props => (
                        <form
                            onSubmit={props.handleSubmit}
                        >
                            <DialogContent dividers className={classes.dialogContent} >
                                <FormLabel className={classes.formLabel}>El cupón se envió al siguiente email ¿Quieres modificarlo y/o volver a enviarlo?</FormLabel>
                                <TextField id="email" className={classes.emailTextField}  color="secondary" label="Email" variant="outlined"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                   // onChange={handleEmailChanged}
                                    placeholder="Email del destinatario del cupón"
                                    {...props.getFieldProps('email')}
                                    helperText={props.touched.email && props.errors.email}
                                    error={props.touched.email && props.errors.email}
                                    />
                                    {(voucherUpdatedResult === "error") ? <Typography className={classes.errorMessage}>Se ha producido un error</Typography>:""}
                            </DialogContent>
                           
                            
                            <DialogContent dividers className={classes.dialogContent} >
                                <FormLabel component="legend">¿Quieres añadir una dedicatoria en el email?</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox id="notGift" checked={!isAGift} onChange={handleChange} />}
                                        label="No hace falta."
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={isAGift} onChange={handleChange} />}
                                        label="Sí, quiero añadir una dedicatoria"
                                    />
                                </FormGroup>
                            </DialogContent>

                            <Collapse
                                in={isAGift}
                                collapsedHeight={0}
                            >
                                <DialogContent className={classes.giftInfo} >
                                    <TextField id="receiver"className={classes.emailTextField} color="secondary" label="Destinatario" variant="outlined"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                       // onChange={handleReceiverChanged}
                                        placeholder="Nombre del destinatario" 
                                        {...props.getFieldProps('receiver')}
                                    helperText={props.touched.receiver && props.errors.receiver}
                                    error={props.touched.receiver && props.errors.receiver}
                                        />

                                    <TextField id="dedication" className={classes.dedicationTextField}  rows={3} multiline color="secondary" label="Dedicatoria" variant="outlined"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                       // onChange={handleDedicatoryChanged}
                                        placeholder="Un texto que acompañará al cupón en el correo que recibirá el destinatario" 
                                        {...props.getFieldProps('dedication')}
                                    helperText={props.touched.dedication && props.errors.dedication}
                                    error={props.touched.dedication && props.errors.dedication}
                                        />
                                </DialogContent>

                            </Collapse>
                     


                <DialogActions dividers className={classes.dialogActions}>
               
                    <Button 
                    className={drawerClasses.acceptButton} 
                    classes={{ disabled: drawerClasses.acceptButtonDisabled }} 
                    type="submit" 
                    color="secondary"
                    disabled={isUpdating}
                    >
                        {isUpdating ? "ENVIANDO...":"ENVIAR DE NUEVO"}
                        </Button>
                </DialogActions>
                </form>)}
                 </Formik>
            </Dialog>
                 
               
        </React.Fragment>
    );
}





export default VoucherInOrderOptionsDialog;

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseAdmin = firebase.initializeApp(
    // {
    //         apiKey: "AIzaSyD8oTmHnv84eAKKyEuxtMA2My9eHRqdopY",
    //         authDomain: "mundoguanteapp.firebaseapp.com",
    //         projectId: "mundoguanteapp",
    //         storageBucket: "mundoguanteapp.appspot.com",
    //         messagingSenderId: "749751757042",
    //         appId: "1:749751757042:web:01e18cf439b744719ec86b",
    //         measurementId: "G-ML4JYXG53R"
    // }
    {
        apiKey: "AIzaSyCWLV48NG-et83kItEyiIvZ6QdfLfuwiEI",
        authDomain: "mundoguanteapp-f4653.firebaseapp.com",
        projectId: "mundoguanteapp-f4653",
        storageBucket: "mundoguanteapp-f4653.appspot.com",
        messagingSenderId: "163505802203",
        appId: "1:163505802203:web:c8da1fe3ca91b3a51df5fe",
        measurementId: "G-DRP9WC1YSD"
    }
);

export default firebaseAdmin.auth();

import React from "react";
import {Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {changeDetailSelectedAction} from "../../../redux/actions/details/changeDetailSelectedAction";
import {Trash2 as DeleteIcon, MinusCircle as MinusIcon, PlusCircle as PlusIcon} from "react-feather";
import {  Edit as EditIcon} from "@material-ui/icons";
import {incDetailInCartAction} from "../../../redux/actions/cart/incDetailInCartAction";
import {decDetailInCartAction} from "../../../redux/actions/cart/decDetailInCartAction";
import { changeVoucherOptionsVisibilityAction } from '../../../redux/actions/details/changeVoucherOptionsVisibilityAction';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth:400,
        minWidth:400
    },
    paper:{
        background:"black"
    },
    detailItem:{
        paddingLeft:10,
        paddingRight:10,
        alignItems:"center",
        justifyItems:"center",
        background:"white",
        borderBottom:"1px solid #f5f5f5"
  
    },
    detailPrice:{
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "flex",
        webkitBoxOrient: "vertical",
        webKitBoxDirection: "normal",
        webkitFlexDirection: "column",
        flexDirection: "column",
        webkitBoxAlign: "end",
        webkitAlignItems: "flex-end",
        alignItems: "flex-end",
        overflow: "hidden",
        webkitFlexShrink: 0,
        flexShrink: 0,
        position:"relative"
    },
    detailUnits:{
        padding:"5px",
        display: "-webkit-inline-box",
        display: "-webkit-inline-flex",
        display: "inline-flex",
        webkitBoxOrient: "vertical",
        webKitBoxDirection: "normal",
        webkitFlexDirection: "column",
        flexDirection: "column",
        webkitBoxAlign: "center",
        webkitAlignItems: "center",
        alignItems:"center",
        textAlign:"center"
    },
   
    detailProductName:{
        alignItems:"center",
        lineHeight:"1"
    },
    detailProductImage:{
       marginTop:0
    },
    detailUnitsLabel:{
        textAlign:"center"
    },
    MuiListItemRoot:{
        padding:"10px"
    }
}));

const DetailsInCartCell = ({detail}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles();
    const voucherInfo = detail.price.product.voucherInfo

    const onDetailClicked = () => {
        dispatch(changeDetailSelectedAction(detail))
      }
    const onEditVoucherClicked = () => {
        console.log("EDIT CLICKED")
        dispatch(changeDetailSelectedAction(detail))
      //dispatch(changeVoucherOptionsVisibilityAction(true))
    }
    const onPlusClicked =() => {
        console.log("PLUS CLICKED")
        dispatch(incDetailInCartAction(detail));
        
    }
    const onMinusClicked =() => {
        dispatch(decDetailInCartAction(detail))
    }

    




    return (
        (voucherInfo) ?
        <ListItem alignItems="flex-start" key={detail.price.id}  classes={{paper: classes.paper}} className={classes.detailItem}>
          <div  className={classes.detailUnits} disablegutters="true">
                <IconButton size="small" onClick={onEditVoucherClicked} style={{paddingBottom:"0.5rem"}}><EditIcon /></IconButton>
                    
                    
                <IconButton size="small" onClick={onMinusClicked}
                    style={
                    {
                        paddingTop:"0.5rem",
                            color:"#f44336"
                    }
                }
                ><DeleteIcon/></IconButton>
            </div>
            <ListItemAvatar    className = {classes.detailProductImage} onClick={onDetailClicked}>
                <Avatar alt={detail.price.product.name} src={detail.getMainPhoto()}/>
                
            </ListItemAvatar>
           
            <ListItemText 
                primary={
                    detail.price.product.name
                  }
                  primaryTypographyProps={
                       {
                           component:"span",
                  variant:"body2",
                  color:"text.primary"}}
                secondary={ 
                    <div>
                    <div>{voucherInfo.type === "product" ? 
                    `${detail.quantity} x adulto`:
                    `Valor: ${detail.quantity*detail.price.value} €`}
                    </div>
                    {detail.price.product.options ? 
                        detail.price.product.options.map(option => option.quantity ? <div>{option.quantity} x {option.name}</div>:""):""
                    }

                    </div>

                
                }
                secondaryTypographyProps={
                    {
                        variant:"subtitle1",
                    }
                }
                onClick={onDetailClicked}
                className = {classes.detailProductName}
            /> 
            <ListItemText
                primary={<React.Fragment>
                    <Typography
                        component="span"
                        variant="subtitle2"
                        color="text.primary"
                    >
                        
                        {detail.getPrice().toFixed(2)}€
                    </Typography>
                </React.Fragment>} 
                className={classes.detailPrice}
            />
        </ListItem>
        :
        <ListItem alignItems="flex-start" key={detail.price.id}  classes={{paper: classes.paper}} className={classes.detailItem}>
            <div  className={classes.detailUnits} disablegutters="true">
                <IconButton size="small" onClick={onPlusClicked} ><PlusIcon /></IconButton>
                    {detail.quantity}
                    
                <IconButton size="small" onClick={onMinusClicked}
                    style={
                    detail.quantity == 1 ? {
                            color:"#f44336"
                    }:{}
                }
                >{detail.quantity > 1 ? <MinusIcon/>:<DeleteIcon/>}</IconButton>
            </div>
            <ListItemAvatar    className = {classes.detailProductImage} onClick={onDetailClicked}>
                <Avatar alt={detail.price.product.name} src={detail.getMainPhoto()}/>
            </ListItemAvatar>
            <ListItemText 
                primary={
                    detail.price.product.name
                  }
                  primaryTypographyProps={
                       {
                           component:"span",
                  variant:"body2",
                  color:"text.primary"}}
                secondary={
                    detail.getUnitaryPrice().toFixed(2)+"€/ud"}
                secondaryTypographyProps={
                    {
                        variant:"subtitle1",
                    }
                    
                }
                onClick={onDetailClicked}
                className = {classes.detailProductName}
            />
            <ListItemText
                primary={<React.Fragment>
                    <Typography
                        component="span"
                        variant="subtitle2"
                        color="text.primary"
                    >
                        {detail.getPrice().toFixed(2)}€
                    </Typography>
                </React.Fragment>} 
                className={classes.detailPrice}
            />
        </ListItem>
    )
}

const DetailsInCartList = (props) => {
    const classes = useStyles();

    const { details } = props;

    if (!details) return <Typography>Carrito Vacío</Typography>

    return (
        <div className={classes.container}>
            <List>
                {
                    details.map(detail => <DetailsInCartCell detail={detail}/>)
                }
            </List>

        </div>
    )
}



export default DetailsInCartList;

import { makeStyles } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Route, Switch } from "react-router-dom";
import swal from 'sweetalert';
import { changeAccountDrawerVisibilityAction } from "../../redux/actions/account/changeAccountDrawerVisibilityAction";
import { changeAddressEditorVisibilityAction } from "../../redux/actions/address/changeAddressEditorVisibilityAction";
import { changeAddressSelectorVisibilityAction } from "../../redux/actions/address/changeAddressSelectorVisibilityAction";
import { changeAuthFormVisibilityAction } from "../../redux/actions/auth/changeAuthFormVisibilityAction";
import { changeRegisterDrawerVisibilityAction } from "../../redux/actions/auth/changeRegisterDrawerVisibilityAction";
import { changeCartVisibilityAction } from "../../redux/actions/cart/changeCartVisibilityAction";
import { changeShoppingInfoVisibilityAction } from "../../redux/actions/cart/changeShoppingInfoVisibilityAction";
import { clearCartAction } from "../../redux/actions/cart/clearCartAction";
import { changeCreditCardEditorVisibilityAction } from "../../redux/actions/credit_card/changeCreditCardEditorVisibilityAction";
import { changeCreditCardSelectorVisibilityAction } from "../../redux/actions/credit_card/changeCreditCardSelectorVisibilityAction";
import { changeDeliveryDateSelectorVisibilityAction } from "../../redux/actions/delivery_date/changeDeliveryDateSelectorVisibilityAction";
import { changeDetailSelectedAction } from "../../redux/actions/details/changeDetailSelectedAction";
import { changeVoucherOptionsVisibilityAction } from "../../redux/actions/details/changeVoucherOptionsVisibilityAction";
import { changeOrdersHistoryVisibilityAction } from "../../redux/actions/order/changeOrdersHistoryVisibilityAction";
import { changePaymentMethodSelectorVisibilityAction } from "../../redux/actions/payment_method/changePaymentMethodSelectorVisibilityAction";
import { changeShippingMethodSelectorVisibilityAction } from "../../redux/actions/shipping_method/changeShippingMethodSelectorVisibilityAction";
import { changeVoucherSelectedAction } from "../../redux/actions/voucher/changeVoucherSelectedAction";
import { changeVouchersVisibilityAction } from "../../redux/actions/voucher/changeVouchersVisibilityAction";
import {fetchFeaturedDetailsAction} from "../../redux/actions/details/fetchFeaturedDetailsAction";
import {changeAvisoLegalVisibilityAction} from "../../redux/actions/info/changeAvisoLegalVisibilityAction"
import {changePrivacyPolicyVisibilityAction} from "../../redux/actions/info/changePrivacyPolicyVisibilityAction"
import {changeShopConditionsVisibilityAction} from "../../redux/actions/info/changeShopConditionsVisibilityAction"
import {changeNewsletterVisibilityAction} from "../../redux/actions/info/changeNewsletterVisibilityAction"
import AccountDrawer from "../components/account/AccountDrawer";
import CreateAddressDrawer from "../components/address/CreateAddressDrawer";
import SelectAddressDrawer from "../components/address/SelecAddressDrawer";
import LoginDrawer from "../components/auth/LoginDrawer";
import RegisterDrawer from "../components/auth/RegisterDrawer";
import CartDrawer from "../components/cart/CartDrawer";
import ShoppingInfoComponent from "../components/cart/ShoppingInfoComponent";
import CreditCardSelector from "../components/credit_card/CreditCardSelector";
import SelectDeliveryDateDrawer from "../components/delivery_date/SelectDeliveryDateDrawer";
import OrdersHistoryDrawer from "../components/order/OrdersHistoryDrawer";
import SelectPaymentMethodDrawer from "../components/payment_method/SelectPaymentMethodDrawer";
import SelectShippingMethodDrawer from "../components/shipping_method/SelectShippingMethodDrawer";
import TopBar from "../components/TopBar";
import VouchersHistoryDrawer from "../components/voucher/VouchersHistoryDrawer";
import DetailDialog from "../dialogs/DetailDialog";
import VoucherDetailDialog from "../dialogs/VoucherDetailDialog";
import VoucherOptionsDialog from "../dialogs/VoucherOptionsDialog";
import DetailsPage from "./DetailsPage";
import MainPage from "./MainPage";
import ProductsPage from "./ProductsPage";
import Footer from '../components/Footer'
import AvisoLegalDialog from '../dialogs/AvisoLegalDialog'
import PrivacyPolicyDialog from '../dialogs/PrivacyPolicyDialog'
import ShopConditionsDialog from '../dialogs/ShopConditionsDialog'
import NewsletterDialog from '../dialogs/NewsletterDialog'


const useStyles = makeStyles({
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
        backgroundColor: '#f4f4f4',
    },
    allButFooter:{
        minHeight:"calc(100vh - 329px)",
        paddingBottom:"5rem",
        backgroundColor: '#f4f4f4',
    }
});


const PagesContainer = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const stripe = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

    const accountDrawerOpened = useSelector(state => state["accountReducer"].accountDrawerOpened)
    const addressEditorOpened = useSelector(state => state["addressReducer"].addressEditorOpened);
    const addressSelectorOpened = useSelector(state => state["addressReducer"].addressSelectorOpened);
    const authFormOpen = useSelector(state => state["authReducer"].authFormOpen);
    const creditCardSelectorOpened = useSelector(state => state["creditCardReducer"].creditCardSelectorOpened);
    const creditCardEditorOpened = useSelector(state => state["creditCardReducer"].creditCardEditorOpened);
    const editingAddress = useSelector(state => state["addressReducer"].editingAddress)
    const registerDrawerOpen = useSelector(state => state["authReducer"].registerDrawerOpen);
    const cartOpen = useSelector(state => state["cartReducer"].cartOpened);
    const deliveryDateSelectorOpened = useSelector(state => state["deliveryDateReducer"].deliveryDateSelectorOpened);
    const detailSelected = useSelector(state => state["detailReducer"].detailSelected);
    const voucherOptionsOpened = useSelector(state => state["detailReducer"].voucherOptionsOpened);
    const ordersHistoryOpened = useSelector(state => state["orderReducer"].ordersHistoryOpened);
    const paymentMethodSelectorOpened = useSelector(state => state["paymentMethodReducer"].paymentMethodSelectorOpened);
    const paymentAlert = useSelector(state => state["orderReducer"].paymentAlert);
    const shippingMethodSelectorOpened = useSelector(state => state["shippingMethodReducer"].shippingMethodSelectorOpened);
    const shoppingInfoOpened = useSelector(state => state["cartReducer"].shoppingInfoOpened);
    const voucherHistoryOpened = useSelector(state => state["voucherReducer"].vouchersVisibility);
    const voucherSelected = useSelector(state => state["voucherReducer"].voucherSelected);
    const isLogged = useSelector(state => state["authReducer"].isLogged)
    const avisoLegalOpened = useSelector(state => state["infoReducer"].avisoLegalOpened);
    const privacyPolicyOpened = useSelector(state => state["infoReducer"].privacyPolicyOpened);
    const shopConditionsOpened = useSelector(state => state["infoReducer"].shopConditionsOpened);
    const newsletterOpened = useSelector(state => state["infoReducer"].newsletterOpened);

    useEffect(() => {
        if (paymentAlert) {
            swal({
               title: paymentAlert.message,
               icon: paymentAlert.status
            });

            if (paymentAlert.status === 'success') {
                clearCart();
                dispatch(fetchFeaturedDetailsAction());
            }
        }
    }, [paymentAlert]);

    const clearCart = () => {
        dispatch(clearCartAction());
    }

    const onCloseLoginForm = () => {
        dispatch(changeAuthFormVisibilityAction(false));
        if(cartOpen && isLogged){
            //SI EL CARRITO ESTÁ ABIERTO AL HACER LOGIN TENEMOS QUE PASAR A SHOPPING_INFO
            dispatch(changeShoppingInfoVisibilityAction(true))
        }
    }

    const onCloseRegisterDrawer = () => {
        dispatch(changeRegisterDrawerVisibilityAction(false));
        if(cartOpen && isLogged){
            //SI EL CARRITO ESTÁ ABIERTO AL HACER LOGIN TENEMOS QUE PASAR A SHOPPING_INFO
            dispatch(changeShoppingInfoVisibilityAction(true))
        }
    }
    const onCloseAccountDrawer = () => {
        dispatch(changeAccountDrawerVisibilityAction(false));
    }

    const onCloseCart = () => {
        dispatch(changeCartVisibilityAction(false));
    }

    const onCloseShippingInfo = () => {
        dispatch(changeShoppingInfoVisibilityAction(false));
    }
    const onCloseShoppingInfo = () => {
        dispatch(changeShoppingInfoVisibilityAction(false));
    }

    const onCloseDetail = () => {
        dispatch(changeDetailSelectedAction(null));
    }

    const onCloseOrdersHistoryDrawer = () => {
        dispatch(changeOrdersHistoryVisibilityAction(false));
    }

    const onCloseSelectAddress = () => {
        dispatch(changeAddressSelectorVisibilityAction(false));
    }

    const onCloseAddressEditor = () => {
        dispatch(changeAddressEditorVisibilityAction(false));
    }

    const onCloseSelectShippingMethod = () => {
        dispatch(changeShippingMethodSelectorVisibilityAction(false));
    }

    const onCloseSelectPaymentMethod = () => {
        dispatch(changePaymentMethodSelectorVisibilityAction(false));
    }
    const onCloseSelectDeliveryDate = () => {
        dispatch(changeDeliveryDateSelectorVisibilityAction(false));
    }
    const onCloseVoucherOptions = () => {
        dispatch(changeVoucherOptionsVisibilityAction(false));
    }
    const onVoucherOptionsSuccess = () => {
        dispatch(changeVoucherOptionsVisibilityAction(false));
        dispatch(changeDetailSelectedAction(null))
        dispatch(changeCartVisibilityAction(true));
    }
    const onCloseCreditCardEditor = () => {
        dispatch(changeCreditCardEditorVisibilityAction(false));
    }
    const onCloseCreditCardSelector = () => {
        dispatch(changeCreditCardSelectorVisibilityAction(false));
    }

    const onCloseVoucherDrawer = () => {
        dispatch(changeVouchersVisibilityAction(false));
    };
    const onCloseVoucherDetail = ()=>{
        dispatch(changeVoucherSelectedAction(null))
     }
     const onCloseAvisoLegal = ()=>{
        dispatch(changeAvisoLegalVisibilityAction(false))
     }
     const onCloseShopConditions = ()=>{
        dispatch(changeShopConditionsVisibilityAction(false))
     }
     const onClosePrivacyPolicy= ()=>{
        dispatch(changePrivacyPolicyVisibilityAction(false))
     }
     const onCloseNewsletter= ()=>{
        dispatch(changeNewsletterVisibilityAction(false))
     }

    return (
        <div className={classes.content}>
             <div className={classes.allButFooter}>
            <TopBar open/>

            <Switch>
                <Route exact path={"/"} component={ MainPage } />
                <Route exact path={"/categories/:category_id/products"} component={ ProductsPage } />
                <Route exact path={"/categories/:category_id/details"} component={ DetailsPage } />

            </Switch>
                <div>
                <AccountDrawer open={accountDrawerOpened} onHandleClose={onCloseAccountDrawer}/>
                <CartDrawer open={cartOpen} onHandleClose={onCloseCart} />
                <CreateAddressDrawer open={addressEditorOpened} onHandleClose={onCloseAddressEditor} />
                <CreditCardSelector open={creditCardSelectorOpened} onHandleClose={onCloseCreditCardSelector}/>
                <DetailDialog open={detailSelected !== null} onHandleClose={onCloseDetail} detail={detailSelected} />
                <LoginDrawer open={authFormOpen} onHandleClose={onCloseLoginForm} />
                <OrdersHistoryDrawer onHandleClose={onCloseOrdersHistoryDrawer} open={ordersHistoryOpened} />
                <RegisterDrawer open={registerDrawerOpen} onHandleClose={onCloseRegisterDrawer} />
                <SelectAddressDrawer onHandleClose={onCloseSelectAddress} open={addressSelectorOpened} />
                <SelectDeliveryDateDrawer onHandleClose={onCloseSelectDeliveryDate} open={deliveryDateSelectorOpened} />
                <SelectPaymentMethodDrawer onHandleClose={onCloseSelectPaymentMethod} open={paymentMethodSelectorOpened} />
                <SelectShippingMethodDrawer onHandleClose={onCloseSelectShippingMethod} open={shippingMethodSelectorOpened} />
                <Elements stripe={stripe}>
                <ShoppingInfoComponent open={shoppingInfoOpened} onHandleClose={onCloseShoppingInfo}/>
                </Elements>
                <VoucherOptionsDialog open={voucherOptionsOpened} onHandleClose={onCloseVoucherOptions} onHandleSuccess={onVoucherOptionsSuccess} detail={detailSelected} />
                <VouchersHistoryDrawer onHandleClose={onCloseVoucherDrawer} open={voucherHistoryOpened} />
                <VoucherDetailDialog open={voucherSelected} voucher={voucherSelected} onHandleClose={onCloseVoucherDetail}/>
                <AvisoLegalDialog open={avisoLegalOpened} onHandleClose={onCloseAvisoLegal}/>
                <PrivacyPolicyDialog open={privacyPolicyOpened} onHandleClose={onClosePrivacyPolicy}/>
                <ShopConditionsDialog open={shopConditionsOpened} onHandleClose={onCloseShopConditions}/>
                <NewsletterDialog open={newsletterOpened} onHandleClose={onCloseNewsletter}/>
            </div>
            </div>
            <Footer/>

        </div>
    )
}

export default PagesContainer;

/*
 * IMPORTS
 */
import React, {useEffect,useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CircularProgress,
    Container,
    Typography,
    makeStyles,
} from "@material-ui/core";

import {fetchProductsAction} from "../../redux/actions/product/fetchProductsAction";
import {fetchCategoriesAction} from "../../redux/actions/category/fetchCategoriesAction";
import {fetchNotificationsAction} from "../../redux/actions/notification/fetchNotificationsAction";
import {fetchFeaturedProductsAction} from "../../redux/actions/product/fetchFeaturedProductsAction";

import CategoryCardList from "../components/category/CategoryCardList";
import NotificationsCarouselFull from "../components/notification/NotificationCarouselFull";
import DetailList from "../components/detail/DetailList";
import {fetchFeaturedDetailsAction} from "../../redux/actions/details/fetchFeaturedDetailsAction";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

/*
 * ESTILOS
 */

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f4f4f4',
        minHeight: '100%',
        width:'100%',
        maxWidth:'100%',
      //  paddingTop: theme.spacing(3),
       // paddingBottom: theme.spacing(3),
        paddingLeft:0,
        paddingTop:0,
        paddingRight:0
    },toolbar: theme.mixins.toolbar,
}));


/*
 * PÁGINA
 */

const MainPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    /*
     * CARGA DE INFORMACIÓN
     */

    // const featuredProducts = useSelector(state => state["productReducer"].featuredProducts);
    const detailsFeatured = useSelector(state => state["detailReducer"].detailsFeatured);
    const notifications = useSelector(state => state["notificationReducer"].notifications);
    const products = useSelector(state => state["productReducer"].products);
    const categories = useSelector(state => state["categoryReducer"].categories);
    const loadingCategories = useSelector(state => state["categoryReducer"].loading);
    const loadingNotifications = useSelector(state => state["notificationReducer"].loading);
    const loadingProducts = useSelector(state => state["productReducer"].loading);
    const loadingDetails = useSelector(state => state["detailReducer"].loading);

    const fetchCategories = () => {
        dispatch(fetchCategoriesAction());
    };

    const fetchFeaturedProducts = () => {
        dispatch(fetchFeaturedProductsAction());
    };

    const fetchNotifications = () => {
        dispatch(fetchNotificationsAction());
    };

    const fetchProducts = () => {
        dispatch(fetchProductsAction());
    };

    const fetchFeaturedDetails = () => {
        dispatch(fetchFeaturedDetailsAction());
    }
    const [show, setShow] = useState(false);

    useEffect(() => {
        console.log('toy aquí')
        fetchCategories();
        fetchNotifications();
        fetchFeaturedProducts();
        fetchFeaturedDetails();

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleScroll = () => {
    setShow(window.pageYOffset > 140);
    };

    useEffect(() => {
        if (categories.length === 1) {
            fetchProducts();
        }
        // eslint-disable-next-line
    }, [categories]);

    useEffect(() => {
        setShow(window.pageYOffset > 140);
        // eslint-disable-next-line
    }, [detailsFeatured]);
    /*
     * VISTA
     */

    return (
        <Container
            className={classes.root}

        >

            <div>
                {/*
                    loadingProducts || loadingCategories
                        ? <CircularProgress />
                        : categories.length === 1 ? <ProductList products={products} /> : <CategoryList categories={categories} />*/
                }
            </div>
            <div >
                    {
                    loadingNotifications ?
                    <CircularProgress />

                    : (notifications && notifications.length !== 0) ? <NotificationsCarouselFull notifications={notifications} fullScreen = {fullScreen}/> : ""
                   }
                </div>
                <Container>
                <div>
                {
                    loadingCategories
                        ? <CircularProgress />
                        :
                        <React.Fragment>
                            <Typography variant="h2" style={{textAlign:"center",marginTop:"4rem",marginBottom:"2rem"}}>Categorías</Typography>
                        <CategoryCardList categories={categories} /></React.Fragment>
                }
            </div>
                <div style={{"margin-top":"1.5rem"}}>
                {
                    loadingDetails
                        ? <CircularProgress />
                        : <React.Fragment> <Typography variant="h2" style={{textAlign:"center",marginTop:"4rem",marginBottom:"2rem"}}>Destacados</Typography><DetailList details={detailsFeatured} /></React.Fragment>
                }

            </div>
           </Container>




        </Container>
    )
}

export default MainPage;

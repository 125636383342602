import React from 'react';
import { Box, Button, Dialog, DialogTitle, IconButton, ListItemText, Typography } from '@material-ui/core/';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch } from "react-redux";
import { addDetailToCartAction } from "../../redux/actions/cart/addDetailToCartAction";
import Carousel from 'react-gallery-carousel'
import 'react-gallery-carousel/dist/index.css'
import { makeStyles } from '@material-ui/core/styles';
import { Trash2 as DeleteIcon, MinusCircle as MinusIcon, PlusCircle as PlusIcon, ShoppingBag } from "react-feather";
import { Close as CloseIcon, Edit as EditIcon } from "@material-ui/icons"
import { incDetailInCartAction } from "../../redux/actions/cart/incDetailInCartAction";
import { decDetailInCartAction } from "../../redux/actions/cart/decDetailInCartAction";
import { changeVoucherOptionsVisibilityAction } from '../../redux/actions/details/changeVoucherOptionsVisibilityAction';
import {changeCartVisibilityAction} from "../../redux/actions/cart/changeCartVisibilityAction";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const DetailDialog = (props) => {
    const dispatch = useDispatch();
    const [onCart, setOnCart] = React.useState(true);
    const { detail, open, onHandleClose } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    /*const { detail } = useSelector(state => ({
        detail: state.detailSelected
     }))*/


    const useStyles = makeStyles((theme) => ({

        paper: {
            background: "#F5F5F5",
            elevation: 1
        },
        quantityPicker: {
            width: "100%",
            display: "inline-flex",
            flexDirection: "row",
            marginTop: "1auto",
            boxShadow: "0 2px 5px 0 hsl(0deg 0% 51% / 50%)",
            padding: "5px",
            borderRadius: "6px"
        },
        quantitySelectorLabel:{
            flexGrow: 4,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center"
    },
        closeButton: {
        position: "absolute",
        right: "0px",
        top: "0px"
    },
        detailDescription: {
        overflow: "auto",
        flexGrow: 3,
        marginBottom: "1rem"
    },
        dialogTitle: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 4
    },
        dialogContent: {
        display: 'flex',
        flexWrap:'wrap',
        flexDirection: 'row',
        padding: "0px 16px 16px 16px",
    }

    }));
const classes = useStyles();
if (!detail) return null;

const onClickAddToCart = () => {
    if (detail.price.product.voucherInfo) {
       // dispatch(addDetailToCartAction(detail))
        dispatch(changeVoucherOptionsVisibilityAction(true))
        setOnCart({ onCart: true });
    } else {
        dispatch(addDetailToCartAction(detail))
        onHandleClose();
        dispatch(changeCartVisibilityAction(true));
        setOnCart({ onCart: true });
    }
}
const onPlusClicked = () => {
    dispatch(incDetailInCartAction(detail));
    setOnCart({ onCart: true });
}
const onMinusClicked = () => {
    dispatch(decDetailInCartAction(detail))
    if (detail.quantity == 0) {
        setOnCart({ onCart: true });
    } else {
        setOnCart({ onCart: false });
    }
}
const onDeleteClicked = () => {
    detail.quantity = 1
    dispatch(decDetailInCartAction(detail))
    setOnCart({ onCart: false });
}
const AddToCartButton = ({ detail }) => (

    <Box variant="outlined" className={classes.quantityPicker} classes={{ paper: classes.paper }}>
        <IconButton size="small" onClick={onMinusClicked}
            style={
                detail.quantity == 1 ? {
                    color: "#f44336"
                } : {}
            }
        >{detail.quantity > 1 ? <MinusIcon /> : <DeleteIcon />}</IconButton>

        {
            <ListItemText className={classes.quantitySelectorLabel} primary={`${detail.getPrice().toFixed(2)}€`} secondary={`${detail.quantity} ${detail.quantity == 1 ? "unidad" : "unidades"} en el carrito`} />
            // <Typography className={classes.quantitySelectorLabel}> {detail.quantity} {detail.quantity == 1 ? "unidad":"unidades"} en el carrito</Typography> 
        }

        <IconButton size="small" onClick={onPlusClicked} ><PlusIcon /></IconButton>


    </Box>
);


const GetButtonForVoucher = ({ detail }) => {
    return (
      
    (detail.quantity == 0) ? 
    <Button fullWidth variant={"contained"} onClick={onClickAddToCart} color="secondary" style={{ "marginTop": "auto" }}>
             VER OPCIONES Y AÑADIR AL CARRITO
             <ShoppingBag style={{ marginLeft: "1rem" }} />
 
         </Button>
        
         : 
    
         <Box variant="outlined" className={classes.quantityPicker} classes={{ paper: classes.paper }}>
         <IconButton size="small" onClick={onDeleteClicked}
             style={{
                     color: "#f44336"

             }}
         ><DeleteIcon /></IconButton>
 
         {
             <ListItemText onClick={onClickAddToCart} className={classes.quantitySelectorLabel} primary={`${detail.getPrice().toFixed(2)}€`} secondary={`El cupón ya está en el carrito`} />
             // <Typography className={classes.quantitySelectorLabel}> {detail.quantity} {detail.quantity == 1 ? "unidad":"unidades"} en el carrito</Typography> 
         }
 
         <IconButton size="small" onClick={onClickAddToCart} ><EditIcon /></IconButton>
 
 
     </Box>
    

        )};
const GetButtonForDetail = ({ detail }) => {
    return (
    (detail.quantity == 0) ? (
       <Button fullWidth variant={"contained"} onClick={onClickAddToCart} color="secondary" style={{ "marginTop": "auto" }}>
            AÑADIR AL CARRITO
            <ShoppingBag style={{ marginLeft: "1rem" }} />

        </Button>)
        
        :
        <AddToCartButton detail={detail} />
    )
    };

//esto lo pide el componente de carrusel
const images = detail.price.product.photoUrls.map((imageURL) => ({ src: imageURL }));

if (!detail) return null
const voucherInfo = detail.price.product.voucherInfo

return (
    <React.Fragment>
        <Dialog
            onClose={onHandleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth={"lg"}
            fullScreen={fullScreen}
            fullWidth>

            <DialogTitle className={"dialogTitle"} classes={{ root: classes.dialogTitle }}>
                <Box display="flex" alignItems="center" style={{ padding: 0 }}>
                    <Box flexGrow={1} style={{ padding: 0 }} >{detail.price.product.families[0].name}</Box>
                    <Box>
                        <IconButton onClick={onHandleClose}><CloseIcon /></IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent} >
                <div style={{ width: fullScreen ? "100%":"50%", minWidth:"350px", paddingTop: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center", padding: fullScreen ? 12:24 }}>
                    <Carousel
                        images={images}
                        canAutoPlay={false}
                        hasIndexBoard={false}
                        style={{ backgroundColor: "#FFFFFF" }}
                        hasThumbnails={!fullScreen}
                        hasDotButtons={'bottom'}
                        hasRightButton={false}
                        hasLeftButton={false}
                    /></div>


                <div style={{ width:fullScreen ? "100%":"50%", minWidth:"350px",padding: 16, display: "flex", flexDirection: "column" }}>
                    <Typography variant={"h4"}  >
                        {
                            detail.price.product.name
                        }
                    </Typography>
                    <Typography variant={"h4"} style={{ "fontWeight": 'bold', "fontSize": "30px", "marginBottom": "8px" }}>
                        {
                            (voucherInfo && voucherInfo.type == "money") ?
                            "Desde "+voucherInfo.minUnits:
                            detail.getUnitaryPrice().toFixed(2)
                        } €
                    </Typography>
                    <Typography component={'div'} variant={"body1"} className={classes.detailDescription}>
                        {
                            <div dangerouslySetInnerHTML={{ __html: detail.price.product.description }} />
                        }
                    </Typography>
                    {
                        detail.price.product.voucherInfo ? <GetButtonForVoucher detail={detail}/> : <GetButtonForDetail detail={detail}/>
                    
                    }
                </div>
            </DialogContent>

        </Dialog>
    </React.Fragment>
);
}


export default DetailDialog;
